import { z } from 'zod'
import ZAction from '@/app/module/campaigns/types/schemas/actions/all-actions'

export const ZAnswerCategory = z.object({
  category: z.string(),
  actions: z.array(ZAction).optional(),
})

const ZOpenEnded = z.object({
  actions: z.array(ZAction).optional(),
  categories: z.array(ZAnswerCategory).optional(),
})

export default ZOpenEnded
