export const ALL = undefined
export const FILTER_INBOUND = 'inbound'
export const FILTER_OUTBOUND = 'outbound'
export const FILTER_MISSED_CALLS = 'missedcalls'

export const FILTER_SENT = false
export const FILTER_FAILED = true

export const MESSAGE_INBOUND = 'ingress'
export const MESSAGE_OUTBOUND = 'egress'

export const TYPE_FILTER = [
  { name: 'Inbound', value: FILTER_INBOUND },
  { name: 'Outbound', value: FILTER_OUTBOUND },
  { name: 'Missed Calls', value: FILTER_MISSED_CALLS },
]
export const STATUS_FILTER = [
  { name: 'Sent', value: FILTER_SENT },
  { name: 'Failed', value: FILTER_FAILED },
]
export const CALL_STATUS_FILTER = [
  { name: 'Answered', value: FILTER_SENT },
  { name: 'Not Answered', value: FILTER_FAILED },
]
export const TOPUP_STATUS_FILTER = [
  { name: 'Success', value: FILTER_SENT },
  { name: 'Failed', value: FILTER_FAILED },
]

export const WHATSAPP_STATUS_FILTER = [
  { name: 'Sent', value: FILTER_SENT },
  { name: 'Failed', value: FILTER_FAILED },
]

export const SEND_LIMIT = 5000

// MESSAGEES

// ACTIONS

export const ACTION_GET_MESSAGES_REQUEST = '@actions/MESSAGES/GET_LIST_REQUEST'
export const ACTION_GET_MESSAGES_SUCCESS = '@actions/MESSAGES/GET_LIST_SUCCESS'
export const ACTION_GET_MESSAGES_FAILURE = '@actions/MESSAGES/GET_LIST_FAILURE'

export const ACTION_DOWNLOAD_MESSAGES_REQUEST = '@actions/DOWNLOAD/DOWNLOAD_MESSAGES_REQUEST'
export const ACTION_DOWNLOAD_MESSAGES_SUCCESS = '@actions/DOWNLOAD/DOWNLOAD_MESSAGES_SUCCESS'
export const ACTION_DOWNLOAD_MESSAGES_FAILURE = '@actions/DOWNLOAD/DOWNLOAD_MESSAGES_FAILURE'

export const ACTION_SEND_MESSAGE_REQUEST = '@actions/MESSAGES/SEND_REQUEST'
export const ACTION_SEND_MESSAGE_SUCCESS = '@actions/MESSAGES/SEND_SUCCESS'
export const ACTION_SEND_MESSAGE_FAILURE = '@actions/MESSAGES/SEND_FAILURE'

export const ACTION_GET_SENDER_IDS_REQUEST = '@actions/MESSAGES/GET_SENDER_IDS_REQUEST'
export const ACTION_GET_SENDER_IDS_SUCCESS = '@actions/MESSAGES/GET_SENDER_IDS_SUCCESS'
export const ACTION_GET_SENDER_IDS_FAILURE = '@actions/MESSAGES/GET_SENDER_IDS_FAILURE'

export const ACTION_CLEAR_MESSAGES = '@actions/MESSAGES/CLEAR_MESSAGES_REQUEST'
export const ACTION_GET_MESSAGES_FILTER = '@actions/MESSAGES/GET_MESSAGES_FILTER'
export const ACTION_SET_SEND_MESSAGE = '@actions/MESSAGES/SET_SEND_MESSAGE'
export const ACTION_LOGS_PAGE_SIZE = '@actions/LOGS/LOGS_PAGE_SIZE'

export const ACTION_LOGS_NAVIGATE = '@actions/LOGS/LOGS_NAVIGATE'

// ERRORS
export const ERROR_GET_MESSAGES_SERVER = 'Failed to get messages. Please try again later.'
export const ERROR_SEND_MESSAGE_SERVER = 'Failed to send message. Please try again later.'
export const ERROR_GET_SENDER_IDS_SERVER = 'Failed to get sender ids. Please try again later.'
export const ERROR_DOWNLOAD_SERVER = 'Failed to download selected messages. Please try again later.'

// INFO
export const DEFAULT_SENDER_ID_MESSAGE = 'Please contact Support to register a branded Sender ID for your organization.'
export const SENDER_ID_PLACEHOLDER = 'Please choose a sender ID'

export const STATUS_TOOLTIP = {
  Received: 'This is an inbound message. The message was received successfully.',
  Delivered:
    "The Mobile Network Operator (Telco) reported that this message has been delivered to the recipient's phone. We cannot guarantee that this information is accurate.",
  Sent: 'We successfully sent this message to the Mobile Network Operator (Telco).',
  Pending:
    'We are in the process of delivering this message to the Mobile Network Operator (Telco). This status will be updated soon.',
  Failed: '',
}

// CALLS

// ACTIONS

export const ACTION_GET_CALLS_REQUEST = '@actions/CALLS/GET_LIST_REQUEST'
export const ACTION_GET_CALLS_SUCCESS = '@actions/CALLS/GET_LIST_SUCCESS'
export const ACTION_GET_CALLS_FAILURE = '@actions/CALLS/GET_LIST_FAILURE'

export const ACTION_DOWNLOAD_CALLS_REQUEST = '@actions/DOWNLOAD/DOWNLOAD_CALLS_REQUEST'
export const ACTION_DOWNLOAD_CALLS_SUCCESS = '@actions/DOWNLOAD/DOWNLOAD_CALLS_SUCCESS'
export const ACTION_DOWNLOAD_CALLS_FAILURE = '@actions/DOWNLOAD/DOWNLOAD_CALLS_FAILURE'

export const ACTION_CLEAR_CALLS = '@actions/CALLS/CLEAR_CALLS_REQUEST'
export const ACTION_GET_CALLS_FILTER = '@actions/CALLS/GET_CALLS_FILTER'
export const ACTION_CALLS_PAGE_SIZE = '@actions/CALLS/CALLS_PAGE_SIZE'

// ERRORS
export const ERROR_GET_CALLS_SERVER = 'Failed to get calls. Please try again later.'

// WHATSAPP

// ACTIONS

export const ACTION_GET_WHATSAPP_REQUEST = '@actions/WHATSAPP/GET_LIST_REQUEST'
export const ACTION_GET_WHATSAPP_SUCCESS = '@actions/WHATSAPP/GET_LIST_SUCCESS'
export const ACTION_GET_WHATSAPP_FAILURE = '@actions/WHATSAPP/GET_LIST_FAILURE'

export const ACTION_DOWNLOAD_WHATSAPP_REQUEST = '@actions/DOWNLOAD/DOWNLOAD_WHATSAPP_REQUEST'
export const ACTION_DOWNLOAD_WHATSAPP_SUCCESS = '@actions/DOWNLOAD/DOWNLOAD_WHATSAPP_SUCCESS'
export const ACTION_DOWNLOAD_WHATSAPP_FAILURE = '@actions/DOWNLOAD/DOWNLOAD_WHATSAPP_FAILURE'

export const ACTION_CLEAR_WHATSAPP = '@actions/WHATSAPP/CLEAR_WHATSAPP_REQUEST'
export const ACTION_GET_WHATSAPP_FILTER = '@actions/WHATSAPP/GET_WHATSAPP_FILTER'
export const ACTION_WHATSAPP_PAGE_SIZE = '@actions/WHATSAPP/WHATSAPP_PAGE_SIZE'

// ERRORS
export const ERROR_GET_WHATSAPP_SERVER = 'Failed to get whatsapp data. Please try again later.'

// SEND WHATSAPP MESSAGE
export const ACTION_SEND_WHATSAPP_MESSAGE_REQUEST = '@actions/WHATSAPP/SEND_REQUEST'
export const ACTION_SEND_WHATSAPP_MESSAGE_SUCCESS = '@actions/WHATSAPP/SEND_SUCCESS'
export const ACTION_SEND_WHATSAPP_MESSAGE_FAILURE = '@actions/WHATSAPP/SEND_FAILURE'
export const ACTION_SET_SEND_WHATSAPP_MESSAGE = '@actions/MESSAGES/SET_SEND_WHATSAPP_MESSAGE'
export const ERROR_SEND_WHATSAPP_MESSAGE_SERVER = 'Failed to send message. Please try again later.'

// WHATSAPP FILE
export const ACTION_SAVE_WHATSAPP_FILE_REQUEST = '@actions/WHATSAAPP/SAVE_FILE_REQUEST'
export const ACTION_SAVE_WHATSAPP_FILE_SUCCESS = '@actions/WHATSAAPP/SAVE_FILE_SUCCESS'
export const ACTION_SAVE_WHATSAPP_FILE_FAILURE = '@actions/WHATSAAPP/SAVE_FILE_FAILURE'

export const ERROR_SAVE_WHATSAPP_FILE = 'There was an error saving your file. Please try again later.'

// TOPUPS

// ACTIONS

export const ACTION_GET_TOPUPS_REQUEST = '@actions/TOPUPS/GET_LIST_REQUEST'
export const ACTION_GET_TOPUPS_SUCCESS = '@actions/TOPUPS/GET_LIST_SUCCESS'
export const ACTION_GET_TOPUPS_FAILURE = '@actions/TOPUPS/GET_LIST_FAILURE'

export const ACTION_DOWNLOAD_TOPUPS_REQUEST = '@actions/DOWNLOAD/DOWNLOAD_TOPUPS_REQUEST'
export const ACTION_DOWNLOAD_TOPUPS_SUCCESS = '@actions/DOWNLOAD/DOWNLOAD_TOPUPS_SUCCESS'
export const ACTION_DOWNLOAD_TOPUPS_FAILURE = '@actions/DOWNLOAD/DOWNLOAD_TOPUPS_FAILURE'

export const ACTION_CLEAR_TOPUPS = '@actions/TOPUPS/CLEAR_TOPUPS_REQUEST'
export const ACTION_GET_TOPUPS_FILTER = '@actions/TOPUPS/GET_TOPUPS_FILTER'
export const ACTION_TOPUPS_PAGE_SIZE = '@actions/TOPUPS/TOPUPS_PAGE_SIZE'

// ERRORS
export const ERROR_GET_TOPUPS_SERVER = 'Failed to get topups data. Please try again later.'
