export default {
  list: {
    loading: false,
    error: {},
    data: {
      values: {},
      pages: {},
      page: 1,
      size: 0,
      total: 0,
      search: '',
    },
  },
  item: {
    loading: false,
    error: {},
    data: {},
  },
}
