import { w3cwebsocket as W3CWebSocket } from 'websocket'
import config from '@/config'
import {
  ACTION_WEBSOCKET_CONNECT,
  ACTION_WEBSOCKET_DISCONNECT,
  ACTION_WEBSOCKET_NEW_MESSAGE,
  WEBSOCKET_INCOMING_CAMPAIGN_UPDATED,
  WEBSOCKET_INCOMING_CONTACT,
  WEBSOCKET_INCOMING_CONTACTS_COMPLETED,
  WEBSOCKET_INCOMING_CONTACT_MESSAGE_COMPLETED,
  WEBSOCKET_INCOMING_ERROR,
  WEBSOCKET_INCOMING_EXPORT_CHANGED,
  WEBSOCKET_INCOMING_EXPORT_COMPLETED,
  WEBSOCKET_INCOMING_MESSAGE_BALANCE,
  WEBSOCKET_INCOMING_SMS,
  WEBSOCKET_INCOMING_WHATSAPP,
} from '@/app/definitions'
import {
  ACTION_ADD_NEW_LOG,
  ACTION_GET_CONTACTS_COMPLETED,
  ACTION_GET_CONTACT_LOGS_COMPLETED,
  ACTION_SET_ACTIVE_CONTACT_INFO,
  CONVERSATIONS_CONTACT_PAGE_SIZE,
} from '@/app/module/conversations/definitions'
import { ACTION_GET_BALANCE_SUCCESS } from '@/app/module/payments/definitions'
import { handleCampaignUpdated, handleExportChanged, handleExportDone } from './ws-handlers'

let disconnected = false

const socketMiddleware = () => {
  let socket = null
  let lastToken
  let invalidToken

  const onOpen = (_, action) => () => {
    socket.send(JSON.stringify({ action: 'auth', jwt: action.token, orgId: action.orgId }))
    lastToken = action.token
  }

  const onClose = (store, action) => () => {
    if (action.token && !disconnected) {
      setTimeout(() => {
        // try reconnecting
        store.dispatch({ type: ACTION_WEBSOCKET_CONNECT, ...action })
      }, 5000)
    }
  }

  const onMessage = (store) => (event) => {
    const payload = JSON.parse(event.data)

    switch (payload.type) {
      case WEBSOCKET_INCOMING_MESSAGE_BALANCE:
        store.dispatch({ type: ACTION_GET_BALANCE_SUCCESS, value: payload.balance })
        break
      case WEBSOCKET_INCOMING_SMS:
        store.dispatch({
          type: ACTION_ADD_NEW_LOG,
          value: { data: { ...payload.data, new: payload.sub, type: 'sms' } },
        })
        break
      case WEBSOCKET_INCOMING_WHATSAPP:
        store.dispatch({
          type: ACTION_ADD_NEW_LOG,
          value: { data: { ...payload.data, new: payload.sub, type: 'whatsapp' } },
        })
        break
      case WEBSOCKET_INCOMING_CONTACT_MESSAGE_COMPLETED:
        store.dispatch({
          type: ACTION_GET_CONTACT_LOGS_COMPLETED,
          value: { contactId: payload.contactId, nextPage: payload.nextPage },
        })
        break
      case WEBSOCKET_INCOMING_CONTACTS_COMPLETED:
        // using payloadcount instead of !!nextPage because nextPage is not empty if you've reached the last page. Probably a bug from the API
        store.dispatch({
          type: ACTION_GET_CONTACTS_COMPLETED,
          value: { nextPage: payload.count === CONVERSATIONS_CONTACT_PAGE_SIZE ? payload.nextPage : '' },
        })
        break
      case WEBSOCKET_INCOMING_CONTACT:
        store.dispatch({ type: ACTION_SET_ACTIVE_CONTACT_INFO, value: payload })
        break
      case WEBSOCKET_INCOMING_ERROR:
        if (!payload.error) {
          // shouldn't happen, but...
        }
        switch (payload.error.errorCode) {
          case 'ws.invalidauth':
            // token might be expired or not valid
            // server will disconnect this websocket now
            // no point in trying to connect again with the invalid token
            invalidToken = lastToken
            break
          default:
          // nothing to do
        }
        break
      case WEBSOCKET_INCOMING_EXPORT_CHANGED: {
        handleExportChanged(store, payload)
        break
      }
      case WEBSOCKET_INCOMING_EXPORT_COMPLETED: {
        handleExportDone(store, payload)
        break
      }
      case WEBSOCKET_INCOMING_CAMPAIGN_UPDATED: {
        handleCampaignUpdated(store, payload)
        break
      }
      default:
        break
    }
  }

  const sendMessage = (action) => {
    if (socket && socket.readyState === socket.OPEN) {
      socket.send(JSON.stringify(action))
    } else {
      setTimeout(() => {
        sendMessage(action)
      }, 1000)
    }
  }

  // eslint-disable-next-line consistent-return
  return (store) => (next) => (action) => {
    switch (action.type) {
      case ACTION_WEBSOCKET_CONNECT:
        if (socket !== null) {
          break
        }
        if (action.token === invalidToken) {
          // we tried with this token and it didn't work
          // login or switching orgs will trigger a new attempt
          break
        }
        disconnected = false
        socket = new W3CWebSocket(`${config.ws.url}/ws/org`)

        socket.onmessage = onMessage(store)
        socket.onclose = onClose(store, action)
        socket.onopen = onOpen(store, action)

        break
      case ACTION_WEBSOCKET_DISCONNECT:
        if (socket !== null) {
          socket.close()
        }
        disconnected = true
        socket = null
        break
      case ACTION_WEBSOCKET_NEW_MESSAGE:
        sendMessage(action.payload)
        break
      default:
        return next(action)
    }
  }
}

export default socketMiddleware()
