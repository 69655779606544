import { concat, findIndex, omit, pathOr, propEq, uniq, update, without } from 'ramda'
import { createReducer } from '@/app/service/util'
import {
  ACTION_CONTACTS_NAVIGATE,
  ACTION_CONTACTS_PAGE_SIZE,
  ACTION_DELETE_CONTACTS_FAILURE,
  ACTION_DELETE_CONTACTS_ITEM_FAILURE,
  ACTION_DELETE_CONTACTS_ITEM_REQUEST,
  ACTION_DELETE_CONTACTS_ITEM_SUCCESS,
  ACTION_DELETE_CONTACTS_REQUEST,
  ACTION_DELETE_CONTACTS_SUCCESS,
  ACTION_GET_CONTACTS_FAILURE,
  ACTION_GET_CONTACTS_ITEM_FAILURE,
  ACTION_GET_CONTACTS_ITEM_REQUEST,
  ACTION_GET_CONTACTS_ITEM_SUCCESS,
  ACTION_GET_CONTACTS_REQUEST,
  ACTION_GET_CONTACTS_SUCCESS,
  ACTION_RESET_CONTACTS,
  ACTION_SAVE_CONTACTS_ITEM_FAILURE,
  ACTION_SAVE_CONTACTS_ITEM_REQUEST,
  ACTION_SAVE_CONTACTS_ITEM_SUCCESS,
  ACTION_SAVE_TABLE_HEADERS,
  ACTION_UPDATE_CONTACTS_ITEM,
  ACTION_UPLOAD_CONFIRM_CONTACTS_FAILURE,
  ACTION_UPLOAD_CONFIRM_CONTACTS_REQUEST,
  ACTION_UPLOAD_CONFIRM_CONTACTS_SUCCESS,
  ACTION_UPLOAD_CONTACTS_FAILURE,
  ACTION_UPLOAD_CONTACTS_REQUEST,
  ACTION_UPLOAD_CONTACTS_SUCCESS,
} from '@/app/module/contacts/definitions'
import defaultState from './state'

export default createReducer(defaultState, {
  // GET LIST
  [ACTION_GET_CONTACTS_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: true,
    },
  }),

  [ACTION_GET_CONTACTS_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      initialized: true,
      error: defaultState.list.error,
      data: {
        ...state.list.data,
        ...value,
      },
    },
  }),
  [ACTION_GET_CONTACTS_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      error: value,
    },
  }),

  // GET ITEM
  [ACTION_GET_CONTACTS_ITEM_REQUEST]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: uniq(concat([value], state.list.loadingItems)),
    },
    item: {
      ...state.item,
      loading: true,
      error: defaultState.item.error,
    },
  }),

  [ACTION_GET_CONTACTS_ITEM_SUCCESS]: (state, { value }) => {
    const item = findIndex(propEq('id', value.id))(state.list.data.values)
    return {
      ...state,
      list: {
        ...state.list,
        loadingItems: without([value.id], state.list.loadingItems),
        ...(state.list.data.values.length > 0 &&
          item >= 0 && {
            data: {
              ...state.list.data,
              values: update(item, value, state.list.data.values),
            },
          }),
      },
      item: {
        ...state.item,
        loading: false,
        error: defaultState.item.error,
        data: value,
      },
    }
  },

  [ACTION_GET_CONTACTS_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
    item: {
      ...state.data,
      loading: false,
      error: value,
      data: defaultState.item.data,
    },
  }),

  // SAVE OR EDIT
  [ACTION_SAVE_CONTACTS_ITEM_REQUEST]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: uniq(concat([value.id], state.list.loadingItems)),
    },
    item: {
      ...state.item,
      error: defaultState.item.error,
      loading: true,
    },
  }),

  [ACTION_SAVE_CONTACTS_ITEM_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
    item: {
      ...state.item,
      loading: false,
      data: value,
    },
  }),

  [ACTION_SAVE_CONTACTS_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
    item: {
      ...state.item,
      loading: false,
    },
  }),

  // DELETE ITEM
  [ACTION_DELETE_CONTACTS_ITEM_REQUEST]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: uniq(concat([value.id], state.list.loadingItems)),
    },
    item: {
      ...state.item,
      loading: true,
    },
  }),

  [ACTION_DELETE_CONTACTS_ITEM_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
      data: {
        ...state.list.data,
        values: omit([value.id], state.list.data.values),
      },
    },
    item: {
      loading: false,
      error: defaultState.item.error,
      data: defaultState.item.data,
    },
  }),

  [ACTION_DELETE_CONTACTS_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without([value.id], state.list.loadingItems),
    },
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // DELETE
  [ACTION_DELETE_CONTACTS_REQUEST]: (state, { value = { ids: [] } }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: uniq(concat(pathOr([], ['ids', 'includedContactIds'], value), state.list.loadingItems)),
    },
  }),

  [ACTION_DELETE_CONTACTS_SUCCESS]: (state, { value = { ids: [] } }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without(pathOr([], ['ids', 'includedContactIds'], value), state.list.loadingItems),
    },
  }),

  [ACTION_DELETE_CONTACTS_FAILURE]: (state, { value = { ids: [] } }) => ({
    ...state,
    list: {
      ...state.list,
      loadingItems: without(pathOr([], ['ids', 'includedContactIds'], value), state.list.loadingItems),
    },
  }),

  // UPLOAD
  [ACTION_UPLOAD_CONTACTS_REQUEST]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      loading: true,
    },
  }),

  [ACTION_UPLOAD_CONTACTS_SUCCESS]: (state, { value }) => ({
    ...state,
    upload: {
      ...state.upload,
      loading: false,
      data: value,
    },
  }),

  [ACTION_UPLOAD_CONTACTS_FAILURE]: (state, { value }) => ({
    ...state,
    upload: {
      ...state.upload,
      loading: false,
      error: value,
    },
  }),

  [ACTION_UPLOAD_CONFIRM_CONTACTS_REQUEST]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      loading: true,
    },
  }),

  // UPLOAD CONFIRM
  [ACTION_UPLOAD_CONFIRM_CONTACTS_SUCCESS]: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      loading: false,
      error: defaultState.upload.error,
      data: defaultState.upload.data,
    },
  }),

  [ACTION_UPLOAD_CONFIRM_CONTACTS_FAILURE]: (state, { value }) => ({
    ...state,
    upload: {
      ...state.upload,
      loading: false,
      confirmError: value,
    },
  }),

  // UPDATE
  [ACTION_UPDATE_CONTACTS_ITEM]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: defaultState.item.error,
      data: value || state.item.data,
    },
  }),

  // NAVIGATE
  [ACTION_CONTACTS_NAVIGATE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      data: {
        ...state.list.data,
        page: value.page,
        searches: {
          ...state.list.data.searches,
          active: value.searchTerm || '',
        },
        filters: {
          ...state.list.data.filters,
          active: value.filter || false,
        },
        subscriptionFilter: value.subscriptionFilter,
        subscriptionStatus: value.subscriptionStatus,
      },
    },
  }),

  // PAGE SIZE
  [ACTION_CONTACTS_PAGE_SIZE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      data: {
        ...state.list.data,
        size: value || state.list.data.size,
      },
    },
  }),

  // RESET
  [ACTION_RESET_CONTACTS]: (state) => ({
    ...defaultState,
    list: {
      ...defaultState.list,
      data: {
        ...defaultState.list.data,
        size: state.contacts.list.data.size,
      },
    },
  }),
  [ACTION_SAVE_TABLE_HEADERS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      headers: value,
    },
  }),
})
