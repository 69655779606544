import { pathOr } from 'ramda'
import config from '@/config'
import { getPageSize } from '@/app/service/storage'

const pageSize = getPageSize({
  namespace: 'contacts',
  defaultValue: pathOr(100, ['modules', 'contacts', 'pageSize', 'default'], config),
})

export default {
  list: {
    loading: false,
    loadingItems: [],
    initialized: false,
    error: {},
    headers: [],
    data: {
      values: [],
      pages: {},
      searches: {
        active: '',
        data: {},
      },
      filters: {
        active: false,
        data: {},
      },
      page: 1,
      size: pageSize,
      total: 0,
      orgTotal: 0,
    },
  },
  item: {
    loading: false,
    error: {},
    data: {},
  },
  upload: {
    loading: false,
    error: {},
    confirmError: {},
    data: {},
  },
}
