import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import Typography from '@mui/material/Typography'
import { path } from 'ramda'
import React from 'react'
import { makeStyles } from 'tss-react/mui'
import IconText from '../../../../layout/icon-text'

function SelectNotification({ size, total, isOpen, onClose, onConfirm, activeFilter, pageSearchTerm }) {
  const { classes } = useStyles()
  const { link } = classes
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={isOpen}
      onClose={onClose}
      autoHideDuration={10000}
    >
      <SnackbarContent
        message={
          <IconText>
            <Icon color="primary">check_circle</Icon>
            <Typography color="inherit">
              {size !== 1
                ? `The ${size} items on this page have been selected. `
                : `The ${size} item on this page has been selected. `}
              {size !== total && typeof activeFilter === 'undefined' && pageSearchTerm === '' && (
                <React.Fragment>
                  {`To select all ${total} contacts in your account, `}
                  <span className={link} onClick={onConfirm}>
                    click here
                  </span>
                  .
                </React.Fragment>
              )}
            </Typography>
          </IconText>
        }
        action={[
          <IconButton key="notification-close" color="inherit" onClick={onClose}>
            <Icon color="inherit">close</Icon>
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

const useStyles = makeStyles()((theme) => ({
  link: {
    color: path(['palette', 'primary', 'main'], theme),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
}))

export default SelectNotification
