import logger from '@redux-beacon/logger'
import { find, pathOr, propEq } from 'ramda'
import { EventsMap, Target, createMiddleware } from 'redux-beacon'
import { Extensions } from 'redux-beacon/dist/types'
import config from '@/config'
import { Organization } from '@/app/types'

type Events = {
  CHANGE_ORG?: string
  EXPORT_CAMPAIGN_REPORT: string
  GET_USER_SUCCESS: string
  LOGIN?: string
  LOGOUT: string
  SIGNUP_SUCCESS: string
}

const eventNames = {
  CHANGE_ORG: 'Change organization',
  EXPORT_CAMPAIGN_REPORT: 'Export campaign report',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  PAGE_LOAD: 'Page Load',
  SIGN_UP: 'Signup successful',
}

const intercom = (events: any[] = []) => {
  const w = window as any
  if (!w.Intercom) {
    return
  }
  events.forEach((event) => {
    if (typeof event === 'object') {
      let eventName = event.event
      let metadata = {}
      let trackEvent = true
      switch (event.event) {
        case eventNames.PAGE_LOAD: {
          metadata = {
            user_id: pathOr('', ['user', 'id'], event),
            user_hash: pathOr('', ['user', 'hash'], event),
            organization: `${pathOr('', ['activeOrg', 'id'], event)} ${pathOr('', ['activeOrg', 'name'], event)}`,
            tag: 'app.eS',
          }
          const source = pathOr('', ['source'], event) as string
          switch (source) {
            case 'switch-organization':
              eventName = eventNames.CHANGE_ORG
              break
            case 'login':
              eventName = eventNames.LOGIN
              break

            default:
              eventName = eventNames.PAGE_LOAD
              break
          }
          break
        }

        case eventNames.LOGOUT:
          trackEvent = false
          metadata = {
            app_id: config.intercom.appId,
          }
          w.Intercom('shutdown')
          break

        case eventNames.SIGN_UP:
          metadata = {
            user_id: pathOr('', ['user', 'id'], event),
            user_hash: pathOr('', ['user', 'hash'], event),
            email: pathOr('', ['signupData', 'email'], event),
            name: `${pathOr('', ['signupData', 'firstName'], event)} ${pathOr('', ['signupData', 'lastName'], event)}`,
            phone: pathOr('', ['signupData', 'phoneNumber'], event),
            referral_url: pathOr('', ['signupData', 'referrer'], event),
            marketing_code: pathOr('', ['signupData', 'marketingCode'], event),
            organization: pathOr('', ['signupData', 'organization'], event),
            organization_type: pathOr('', ['signupData', 'organizationType'], event),
            country: pathOr('', ['signupData', 'country'], event),
            utm_source: pathOr('', ['signupData', 'hearAboutUs'], event),
            referrer: window.location.href,
            tag: 'app.eS',
          }
          break
        default:
          break
      }
      w.Intercom('boot', metadata)
      if (trackEvent) {
        w.Intercom('trackEvent', eventName, metadata)
      }
    }
  })
}

const getOrgName = ({ orgId, orgs }: { orgId: number; orgs: Organization[] | null }) =>
  pathOr('', ['name'], find(propEq('orgId', orgId), orgs || []))

export default (
  isLoggingEnabled: boolean,
  { EXPORT_CAMPAIGN_REPORT, GET_USER_SUCCESS, LOGOUT, SIGNUP_SUCCESS }: Events,
) => {
  const eventsMap: EventsMap = {
    [EXPORT_CAMPAIGN_REPORT]: (action: any) => ({
      event: eventNames.EXPORT_CAMPAIGN_REPORT,
      engId: action.value.engId,
      orgId: action.value.orgId,
    }),
    [GET_USER_SUCCESS]: (action: any) => ({
      event: eventNames.PAGE_LOAD,
      loggedIn: true,
      user: {
        id: action.value.id,
        hash: action.value.hmac,
      },
      activeOrg: {
        id: action.value.activeOrgId,
        name: getOrgName({
          orgId: action.value.activeOrgId,
          orgs: action.value.orgs,
        }),
      },
      source: action.value.source,
    }),
    [LOGOUT]: () => ({
      event: eventNames.LOGOUT,
    }),
    [SIGNUP_SUCCESS]: (action: any) => ({
      event: eventNames.SIGN_UP,
      user: {
        id: action.value.id,
        hash: action.value.hash,
      },
      activeOrg: {
        id: action.value.activeOrganization.id,
        name: action.value.activeOrganization.name,
      },
      signupData: action.value.signupData,
    }),
  }

  const args = [eventsMap, intercom] as [EventsMap, Target, Extensions?]

  if (isLoggingEnabled) {
    args.push({ logger } as any)
  }

  return createMiddleware(...args)
}
