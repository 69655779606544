import { concat, omit, pathOr, uniq, without } from 'ramda'
import { createReducer } from '@/app/service/util'
import {
  ACTION_ADD_PHONENUMBER_CALLHOOK_FAILURE,
  ACTION_ADD_PHONENUMBER_CALLHOOK_REQUEST,
  ACTION_ADD_PHONENUMBER_CALLHOOK_SUCCESS,
  ACTION_ADD_PHONENUMBER_HOOK_FAILURE,
  ACTION_ADD_PHONENUMBER_HOOK_REQUEST,
  ACTION_ADD_PHONENUMBER_HOOK_SUCCESS,
  ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_FAILURE,
  ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_REQUEST,
  ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_SUCCESS,
  ACTION_CLEAR_PHONENUMBERS,
  ACTION_DELETE_PHONENUMBER_CALLHOOK_FAILURE,
  ACTION_DELETE_PHONENUMBER_CALLHOOK_REQUEST,
  ACTION_DELETE_PHONENUMBER_CALLHOOK_SUCCESS,
  ACTION_DELETE_PHONENUMBER_HOOK_FAILURE,
  ACTION_DELETE_PHONENUMBER_HOOK_REQUEST,
  ACTION_DELETE_PHONENUMBER_HOOK_SUCCESS,
  ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_FAILURE,
  ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_REQUEST,
  ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS,
  ACTION_FILE_RESET,
  ACTION_GET_FILE_FAILURE,
  ACTION_GET_FILE_REQUEST,
  ACTION_GET_FILE_SUCCESS,
  ACTION_GET_PHONENUMBERS_FAILURE,
  ACTION_GET_PHONENUMBERS_REQUEST,
  ACTION_GET_PHONENUMBERS_SUCCESS,
  ACTION_GET_PHONENUMBER_FAILURE,
  ACTION_GET_PHONENUMBER_REQUEST,
  ACTION_GET_PHONENUMBER_SUCCESS,
  ACTION_POST_PHONENUMBER_FAILURE,
  ACTION_POST_PHONENUMBER_REQUEST,
  ACTION_POST_PHONENUMBER_SUCCESS,
  ACTION_SAVE_FILE_FAILURE,
  ACTION_SAVE_FILE_REQUEST,
  ACTION_SAVE_FILE_SUCCESS,
  ACTION_UPDATE_PHONENUMBER_CALLHOOK_FAILURE,
  ACTION_UPDATE_PHONENUMBER_CALLHOOK_REQUEST,
  ACTION_UPDATE_PHONENUMBER_CALLHOOK_SUCCESS,
  ACTION_UPDATE_PHONENUMBER_HOOK_FAILURE,
  ACTION_UPDATE_PHONENUMBER_HOOK_REQUEST,
  ACTION_UPDATE_PHONENUMBER_HOOK_SUCCESS,
  ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_FAILURE,
  ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_REQUEST,
  ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS,
} from '@/app/module/phonenumbers/definitions'
import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_GET_PHONENUMBERS_REQUEST]: (state) => ({
    ...state,
    loading: true,
    error: defaultState.error,
  }),
  [ACTION_GET_PHONENUMBERS_SUCCESS]: (state, { value }) => ({
    ...state,
    loading: false,
    error: defaultState.error,
    data: {
      ...state.data,
      phoneNumbers: [...state.data.phoneNumbers, ...(value.phoneNumbers || [])],
      totalCount: value.totalCount,
    },
  }),
  [ACTION_GET_PHONENUMBERS_FAILURE]: (state, { value }) => ({
    ...state,
    loading: false,
    error: value,
    data: {
      ...state.data,
      phoneNumbers: defaultState.data.phoneNumbers,
      totalCount: defaultState.data.totalCount,
    },
  }),
  [ACTION_CLEAR_PHONENUMBERS]: (state) => ({
    ...state,
    error: defaultState.error,
    data: {
      ...state.data,
      phoneNumbers: defaultState.data.phoneNumbers,
    },
  }),
  // GET HOOKS
  [ACTION_GET_PHONENUMBER_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_GET_PHONENUMBER_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data: {
        ...state.item.data,
        ...value,
      },
    },
    files: {
      ...state.files,
      data: {
        ...state.files.data,
        ...value.files,
      },
    },
  }),

  [ACTION_GET_PHONENUMBER_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // ADD
  [ACTION_ADD_PHONENUMBER_HOOK_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_ADD_PHONENUMBER_HOOK_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data:
        {
          ...state.item.data,
          hooks: [...pathOr([], ['item', 'data', 'hooks'], state), value],
        } || defaultState.item.data,
    },
  }),

  [ACTION_ADD_PHONENUMBER_HOOK_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // UPDATE
  [ACTION_UPDATE_PHONENUMBER_HOOK_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_UPDATE_PHONENUMBER_HOOK_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data: {
        ...state.item.data,
        hooks:
          [
            ...state.item.data.hooks.map((hook) => {
              if (hook.id === value.id) {
                return value
              }
              return hook
            }),
          ] || defaultState.item.data,
      },
    },
  }),

  [ACTION_UPDATE_PHONENUMBER_HOOK_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // DELETE
  [ACTION_DELETE_PHONENUMBER_HOOK_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_DELETE_PHONENUMBER_HOOK_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data:
        {
          ...state.item.data,
          hooks: state.item.data.hooks.filter((hook) => hook.id !== value),
        } || defaultState.item.data,
    },
  }),

  [ACTION_DELETE_PHONENUMBER_HOOK_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // WHATSAPP
  // ADD
  [ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data:
        {
          ...state.item.data,
          whatsappHooks: [...pathOr([], ['item', 'data', 'whatsappHooks'], state), value],
        } || defaultState.item.data,
    },
  }),

  [ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // UPDATE
  [ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data: {
        ...state.item.data,
        whatsappHooks:
          [
            ...state.item.data.whatsappHooks.map((hook) => {
              if (hook.id === value.id) {
                return value
              }
              return hook
            }),
          ] || defaultState.item.data,
      },
    },
  }),

  [ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // DELETE
  [ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data:
        {
          ...state.item.data,
          whatsappHooks: state.item.data.whatsappHooks.filter((hook) => hook.id !== value),
        } || defaultState.item.data,
    },
  }),

  [ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
  // CALL HOOKS
  // ADD
  [ACTION_ADD_PHONENUMBER_CALLHOOK_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_ADD_PHONENUMBER_CALLHOOK_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data:
        {
          ...state.item.data,
          callHooks: [...pathOr([], ['item', 'data', 'callHooks'], state), value],
        } || defaultState.item.data,
    },
  }),

  [ACTION_ADD_PHONENUMBER_CALLHOOK_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // UPDATE
  [ACTION_UPDATE_PHONENUMBER_CALLHOOK_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_UPDATE_PHONENUMBER_CALLHOOK_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data: {
        ...state.item.data,
        callHooks:
          [
            ...state.item.data.callHooks.map((hook) => {
              if (hook.id === value.id) {
                return value
              }
              return hook
            }),
          ] || defaultState.item.data,
      },
    },
  }),

  [ACTION_UPDATE_PHONENUMBER_CALLHOOK_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // DELETE
  [ACTION_DELETE_PHONENUMBER_CALLHOOK_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_DELETE_PHONENUMBER_CALLHOOK_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data:
        {
          ...state.item.data,
          callHooks: state.item.data.callHooks.filter((hook) => hook.id !== value),
        } || defaultState.item.data,
    },
  }),

  [ACTION_DELETE_PHONENUMBER_CALLHOOK_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  [ACTION_POST_PHONENUMBER_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: {},
    },
  }),

  [ACTION_POST_PHONENUMBER_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      loading: false,
      error: {},
      data: {
        ...state.item.data,
        ...value,
      },
    },
  }),

  [ACTION_POST_PHONENUMBER_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // GET FILE
  [ACTION_GET_FILE_REQUEST]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: true,
      loadingItems: uniq(concat([value.id], state.files.loadingItems)),
    },
  }),
  [ACTION_GET_FILE_SUCCESS]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
      data: {
        ...state.files.data,
        [value.filename]: {
          ...pathOr({}, [value.filename], state.files.data),
          ...value,
        },
      },
    },
  }),
  [ACTION_GET_FILE_FAILURE]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
    },
  }),

  // SAVE FILE
  [ACTION_SAVE_FILE_REQUEST]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: true,
      loadingItems: uniq(concat([value.id], state.files.loadingItems)),
    },
  }),
  [ACTION_SAVE_FILE_SUCCESS]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
      data: {
        ...state.files.data,
        [value.filename]: value,
      },
    },
  }),
  [ACTION_SAVE_FILE_FAILURE]: (state, { value }) => ({
    ...state,
    files: {
      ...state.files,
      loading: false,
      loadingItems: without([value.id], state.files.loadingItems),
    },
  }),

  // RESET FILE(S)
  [ACTION_FILE_RESET]: (state, { value }) => ({
    ...state,
    files: {
      loading: false,
      loadingItems: [],
      data: value.id ? omit([value.id], state.files.data) : defaultState.files.data,
    },
  }),
})
