// ACTIONS
export const ACTION_GET_EXPORTS_REQUEST = '@actions/EXPORTS/GET_EXPORTS_REQUEST'
export const ACTION_GET_EXPORTS_SUCCESS = '@actions/EXPORTS/GET_EXPORTS_SUCCESS'
export const ACTION_GET_EXPORTS_FAILURE = '@actions/EXPORTS/GET_EXPORTS_FAILURE'

export const ACTION_DELETE_EXPORT_REQUEST = '@actions/EXPORTS/DELETE_EXPORT_REQUEST'
export const ACTION_DELETE_EXPORT_SUCCESS = '@actions/EXPORTS/DELETE_EXPORT_SUCCESS'
export const ACTION_DELETE_EXPORT_FAILURE = '@actions/EXPORTS/DELETE_EXPORT_FAILURE'

// ERRORS
export const ERROR_GET_EXPORTS_SERVER = 'Failed to get exports. Please try again later.'
export const ERROR_DELETE_EXPORT_SERVER = 'Failed to delete export. Please try again later.'
