import { ACTION_AUTH_LOGOUT } from '@/app/module/auth/definitions'
import auth from '@/app/module/auth/store/reducer'
import campaigns from '@/app/module/campaigns/store/reducer'
import cati from '@/app/module/cati/store/reducer'
import contacts from '@/app/module/contacts/store/reducer'
import conversations from '@/app/module/conversations/store/reducer'
import customFields from '@/app/module/custom-fields/store/reducer'
import dashboard from '@/app/module/dashboard/store/reducer'
import demos from '@/app/module/demos/store/reducer'
import exports from '@/app/module/exports/store/reducer'
import files from '@/app/module/files/store/reducer'
import groups from '@/app/module/groups/store/reducer'
import logs from '@/app/module/logs/store/reducer'
import notifications from '@/app/module/notifications/store/reducer'
import payments from '@/app/module/payments/store/reducer'
import phoneNumbers from '@/app/module/phonenumbers/store/reducer'
import plans from '@/app/module/plans/store/reducer'
import segments from '@/app/module/segments/store/reducer'
import { ACTION_SWITCH_ORGANIZATION_SUCCESS } from '@/app/module/user/definitions'
import user from '@/app/module/user/store/reducer'
import utils from '@/app/module/utils/store/reducer'
import cookies from 'js-cookie'
import { path } from 'ramda'
import { combineReducers } from 'redux'
import { esApi } from './api'
import { defaultState } from './state'

const appReducers = combineReducers({
  auth,
  user,
  contacts,
  customFields,
  demos,
  logs,
  conversations,
  phoneNumbers,
  notifications,
  utils,
  campaigns,
  groups,
  segments,
  payments,
  files,
  dashboard,
  plans,
  cati,
  exports,
  [esApi.reducerPath]: esApi.reducer,
})

export default (state: any = {}, action: any = {}) => {
  if (action.type === ACTION_SWITCH_ORGANIZATION_SUCCESS) {
    cookies.remove('trialMode')
    return appReducers(
      {
        ...state,
        auth: {
          ...state.auth,
          trialMode: false,
        },
        user: state.user,
        utils: state.utils,
        contacts: {
          ...defaultState.contacts,
          list: {
            ...defaultState.contacts.list,
            data: {
              ...defaultState.contacts.list.data,
              size: path(['contacts', 'list', 'data', 'size'], state),
            },
          },
        },
        demos: state.demos,
        dashboard: {
          ...defaultState.dashboard,
        },
        campaigns: {
          ...defaultState.campaigns,
          list: {
            ...defaultState.campaigns.list,
            data: {
              ...defaultState.campaigns.list.data,
              size: path(['campaigns', 'list', 'data', 'size'], state),
            },
          },
        },
        conversations: defaultState.conversations,
        logs: {
          ...defaultState.logs,
          data: {
            ...defaultState.logs.data,
            filters: path(['logs', 'data', 'filters'], state),
          },
        },
        payments: defaultState.payments,
        phoneNumbers: {
          ...defaultState.phoneNumbers,
          data: {
            ...defaultState.phoneNumbers.data,
            filters: path(['phoneNumbers', 'data', 'filters'], state),
          },
        },
        plans: {
          ...state.plans,
          item: defaultState.plans.item,
        },
        cati: {
          ...state.cati,
          item: defaultState.cati.item,
        },
        exports: defaultState.exports,
      },
      action,
    )
  }
  if (action.type === ACTION_AUTH_LOGOUT) {
    return appReducers(
      {
        // this silly override prevents a type error for now with the exports module
        ...state,
        ...defaultState,
        utils: state.utils,
      },
      action,
    )
  }
  return appReducers(state, action)
}
