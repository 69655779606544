import { z } from 'zod'
import ZAction from '@/app/module/campaigns/types/schemas/actions/all-actions'
import { ZTimeUnit } from '@/app/module/campaigns/types/schemas/common'

const ZOnTimeout = z.object({
  actions: z.array(ZAction).optional(),
  timeout: z.object({
    unit: ZTimeUnit,
    value: z.number(),
  }),
  timeoutSeconds: z.number().optional(),
})

export default ZOnTimeout
