import config from '@/config'
import http from '@/config/http'
import { createAsyncActions, preventParallel, toQuery } from '@/app/service/util'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import {
  ACTION_DELETE_FILES_ITEM_FAILURE,
  ACTION_DELETE_FILES_ITEM_REQUEST,
  ACTION_DELETE_FILES_ITEM_SUCCESS,
  ACTION_GET_FILES_FAILURE,
  ACTION_GET_FILES_ITEM_FAILURE,
  ACTION_GET_FILES_ITEM_REQUEST,
  ACTION_GET_FILES_ITEM_SUCCESS,
  ACTION_GET_FILES_REQUEST,
  ACTION_GET_FILES_SUCCESS,
  ACTION_UPLOAD_FILES_ITEM_FAILURE,
  ACTION_UPLOAD_FILES_ITEM_REQUEST,
  ACTION_UPLOAD_FILES_ITEM_SUCCESS,
  ERROR_FILES_DELETE_SERVER,
  ERROR_FILES_UPLOAD_SERVER,
} from '@/app/module/files/definitions'

const {
  request: getListRequest,
  success: getListSuccess,
  failure: getListFailure,
} = createAsyncActions({
  request: ACTION_GET_FILES_REQUEST,
  success: ACTION_GET_FILES_SUCCESS,
  failure: ACTION_GET_FILES_FAILURE,
})

const {
  request: getRequest,
  success: getSuccess,
  failure: getFailure,
} = createAsyncActions({
  request: ACTION_GET_FILES_ITEM_REQUEST,
  success: ACTION_GET_FILES_ITEM_SUCCESS,
  failure: ACTION_GET_FILES_ITEM_FAILURE,
})

const {
  request: uploadRequest,
  success: uploadSuccess,
  failure: uploadFailure,
} = createAsyncActions({
  request: ACTION_UPLOAD_FILES_ITEM_REQUEST,
  success: ACTION_UPLOAD_FILES_ITEM_SUCCESS,
  failure: ACTION_UPLOAD_FILES_ITEM_FAILURE,
})

const {
  request: deleteRequest,
  success: deleteSuccess,
  failure: deleteFailure,
} = createAsyncActions({
  request: ACTION_DELETE_FILES_ITEM_REQUEST,
  success: ACTION_DELETE_FILES_ITEM_SUCCESS,
  failure: ACTION_DELETE_FILES_ITEM_FAILURE,
})

export const getFiles = preventParallel(
  ({ orgId, query }) => `${orgId}-${toQuery(query)}`,
  ({ token, orgId, query = {} }) =>
    (dispatch) => {
      dispatch(getListRequest(query))

      return request.files
        .getList({ token, orgId, query })
        .then(({ files = [] } = {}) => {
          dispatch(getListSuccess({ data: files, category: query.category }))
          return files
        })
        .catch((err) => {
          dispatch(
            getListFailure({
              status: err.status,
              error: err.message,
            }),
          )
        })
    },
)

export const getFilesItem = preventParallel(
  ({ orgId, name }) => `${orgId}-${name}`,
  ({ token, orgId, name }) =>
    (dispatch) => {
      dispatch(getRequest({ name }))

      const dlPath = http.files.get.getSignablePath({ orgId, name })
      const data = {
        paths: [dlPath],
      }

      return request.auth
        .sign({ token, item: data })
        .then((res) => {
          const url = http.files.get.getPath({
            name,
            signedPath: dlPath,
            urlToken: res[dlPath],
          })
          dispatch(getSuccess({ name, url, urlToken: res[dlPath] }))
          return {
            name,
            url,
          }
        })
        .catch((err) => {
          dispatch(
            getFailure({
              status: err.status,
              error: err.message,
            }),
          )
        })
    },
)

export const saveFile =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(uploadRequest())

    const data = new FormData()
    data.append('category', 'uploads')
    data.append('upload', item.file, item.filename)
    if (item.filter) {
      data.append('filter', item.filter)
    }

    return request.files
      .save({ token, orgId, file: data, ...(item.type === 'video' && { type: '?video=true' }) })
      .then(({ filename, title }) => {
        const url = `${config.svc.url}/v1/organizations/${orgId}/files/${filename}`
        dispatch(
          uploadSuccess({
            filename,
            title,
            url,
          }),
        )
        return {
          filename,
          title,
          url,
        }
      })
      .catch((err) => {
        dispatch(
          uploadFailure({
            status: err.status,
            error: err.message,
          }),
        )
        dispatch(
          createNotification({
            'upload-file': {
              type: 'error',
              message: ERROR_FILES_UPLOAD_SERVER,
            },
          }),
        )
        return err
      })
  }

// eslint-disable-next-line
export const deleteFile =
  ({ token, orgId, name }) =>
  (dispatch) => {
    dispatch(deleteRequest({ name }))
    return request.files
      .delete({ token, orgId, name })
      .then(() => {
        dispatch(deleteSuccess({ name }))
        return {
          name,
        }
      })
      .catch((err) => {
        dispatch(
          deleteFailure({
            status: err.status,
            error: err.message,
          }),
        )
        dispatch(
          createNotification({
            'delete-file': {
              type: 'error',
              message: ERROR_FILES_DELETE_SERVER,
            },
          }),
        )
      })
  }
