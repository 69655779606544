/**
 * Backend campaign template names, the template type that the backend accepts and returns
 */
export enum BackendCampaignTemplate {
  CATI = 'cati',
  Drip = 'multidrip',
  Reminder = 'reminder',
  SMSBlast = 'smsblast',
  SMSSurvey = 'smssurvey',
  VoiceBlast = 'voiceblast', // Should not happen, but is here for completeness since the backend can still return it
  VoiceSurvey = 'ivr',
  WhatsAppSurvey = 'whatsappsurvey',
}

/**
 * Predefined templates that the UI uses
 * May not match the backend templates since the frontend can have more than 1 template for a single backend template
 * E.g. topup UI template is a drip campaign with a topup part as the default one.
 */
export enum CampaignTemplate {
  CATI = BackendCampaignTemplate.CATI,
  Drip = BackendCampaignTemplate.Drip,
  Reminder = BackendCampaignTemplate.Reminder,
  SMSBlast = BackendCampaignTemplate.SMSBlast,
  SMSSurvey = BackendCampaignTemplate.SMSSurvey,
  AirtimeTopup = 'topup',
  VoiceBlast = BackendCampaignTemplate.VoiceBlast,
  VoiceSurvey = BackendCampaignTemplate.VoiceSurvey,
  WhatsAppSurvey = BackendCampaignTemplate.WhatsAppSurvey,
}

/**
 * Local Campaign Template Names
 * Converts the backend template name to the local template name (type in campaign item)
 */
export enum CampaignTemplateType {
  CATI = BackendCampaignTemplate.CATI,
  Drip = 'drip',
  Reminder = BackendCampaignTemplate.Reminder,
  SMSBlast = BackendCampaignTemplate.SMSBlast,
  SMSSurvey = BackendCampaignTemplate.SMSSurvey,
  VoiceBlast = BackendCampaignTemplate.VoiceBlast,
  VoiceSurvey = BackendCampaignTemplate.VoiceSurvey,
  WhatsAppSurvey = BackendCampaignTemplate.WhatsAppSurvey,
}

export enum CampaignTemplateUrl {
  CATI = 'cati',
  Drip = 'drip',
  Reminder = 'reminder',
  SMSBlast = 'smsblast',
  SMSSurvey = 'sms-survey',
  VoiceBlast = 'voiceblast',
  VoiceSurvey = 'voice-survey',
  WhatsAppSurvey = 'whatsapp-survey',
}
