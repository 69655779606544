import { compose, not, pathEq, reverse } from 'ramda'
import { createReducer } from '@/app/service/util'
import {
  ACTION_AUTO_RECHARGE_CARD_FAILURE,
  ACTION_AUTO_RECHARGE_CARD_REQUEST,
  ACTION_AUTO_RECHARGE_CARD_SUCCESS,
  ACTION_CHARGE_CARD_FAILURE,
  ACTION_CHARGE_CARD_REQUEST,
  ACTION_CHARGE_CARD_SUCCESS,
  ACTION_DELETE_CARD_FAILURE,
  ACTION_DELETE_CARD_REQUEST,
  ACTION_DELETE_CARD_SUCCESS,
  ACTION_DOWNLOAD_INVOICE_FAILURE,
  ACTION_DOWNLOAD_INVOICE_REQUEST,
  ACTION_DOWNLOAD_INVOICE_SUCCESS,
  ACTION_GENERATE_PROFORMA_INVOICE_FAILURE,
  ACTION_GENERATE_PROFORMA_INVOICE_REQUEST,
  ACTION_GENERATE_PROFORMA_INVOICE_SUCCESS,
  ACTION_GET_BALANCE_FAILURE,
  ACTION_GET_BALANCE_REQUEST,
  ACTION_GET_BALANCE_SUCCESS,
  ACTION_GET_CARDS_FAILURE,
  ACTION_GET_CARDS_REQUEST,
  ACTION_GET_CARDS_SUCCESS,
  ACTION_GET_INVOICES_FAILURE,
  ACTION_GET_INVOICES_REQUEST,
  ACTION_GET_INVOICES_SUCCESS,
  ACTION_GET_RECEIPTS_FAILURE,
  ACTION_GET_RECEIPTS_REQUEST,
  ACTION_GET_RECEIPTS_SUCCESS,
  ACTION_SAVE_CARD_FAILURE,
  ACTION_SAVE_CARD_REQUEST,
  ACTION_SAVE_CARD_SUCCESS,
  ACTION_TRANSFER_FUNDS_FAILURE,
  ACTION_TRANSFER_FUNDS_REQUEST,
  ACTION_TRANSFER_FUNDS_SUCCESS,
} from '@/app/module/payments/definitions'
import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_GET_CARDS_REQUEST]: (state) => ({
    ...state,
    cards: {
      ...state.cards,
      loading: true,
    },
  }),
  [ACTION_GET_CARDS_SUCCESS]: (state, { value }) => ({
    ...state,
    cards: {
      ...state.cards,
      error: defaultState.cards.error,
      loading: false,
      initialized: true,
      data: value.map((item) => ({
        ...item,
        id: item.id.toString(),
      })),
    },
  }),
  [ACTION_GET_CARDS_FAILURE]: (state, { value }) => ({
    ...state,
    cards: {
      ...state.cards,
      loading: false,
      initialized: true,
      error: value,
    },
  }),
  [ACTION_SAVE_CARD_REQUEST]: (state) => ({
    ...state,
    cards: {
      ...state.cards,
      loading: true,
    },
  }),
  [ACTION_SAVE_CARD_SUCCESS]: (state, { value }) => ({
    ...state,
    cards: {
      ...state.cards,
      loading: false,
      error: defaultState.cards.error,
      data: [
        ...state.cards.data,
        {
          ...value,
          id: value.id.toString(),
        },
      ],
    },
  }),
  [ACTION_SAVE_CARD_FAILURE]: (state) => ({
    ...state,
    cards: {
      ...state.cards,
      loading: false,
    },
  }),
  [ACTION_CHARGE_CARD_REQUEST]: (state) => ({
    ...state,
    funds: {
      loading: true,
    },
  }),
  [ACTION_CHARGE_CARD_SUCCESS]: (state) => ({
    ...state,
    funds: {
      loading: false,
    },
  }),
  [ACTION_CHARGE_CARD_FAILURE]: (state) => ({
    ...state,
    funds: {
      loading: false,
    },
  }),
  [ACTION_DELETE_CARD_REQUEST]: (state) => ({
    ...state,
    cards: {
      ...state.cards,
      error: defaultState.cards.error,
      loading: true,
    },
  }),
  [ACTION_DELETE_CARD_SUCCESS]: (state, { value }) => ({
    ...state,
    cards: {
      ...state.cards,
      loading: false,
      error: defaultState.cards.error,
      data: state.cards.data.filter(compose(not, pathEq(['id'], value))),
    },
  }),
  [ACTION_DELETE_CARD_FAILURE]: (state, { value }) => ({
    ...state,
    cards: {
      ...state.cards,
      loading: false,
      error: value,
    },
  }),
  [ACTION_GET_RECEIPTS_REQUEST]: (state) => ({
    ...state,
    receipts: {
      ...state.receipts,
      loading: true,
    },
  }),
  [ACTION_GET_RECEIPTS_SUCCESS]: (state, { value }) => ({
    ...state,
    receipts: {
      ...state.receipts,
      error: defaultState.receipts.error,
      loading: false,
      data: value.map((item) => ({
        ...item,
        id: item.id.toString(),
      })),
    },
  }),
  [ACTION_GET_RECEIPTS_FAILURE]: (state, { value }) => ({
    ...state,
    receipts: {
      ...state.receipts,
      loading: false,
      error: value,
    },
  }),
  [ACTION_GET_INVOICES_REQUEST]: (state) => ({
    ...state,
    invoices: {
      ...state.invoices,
      loading: true,
    },
  }),
  [ACTION_GET_INVOICES_SUCCESS]: (state, { value }) => ({
    ...state,
    invoices: {
      ...state.invoices,
      error: defaultState.invoices.error,
      loading: false,
      data: reverse(value),
    },
  }),
  [ACTION_GET_INVOICES_FAILURE]: (state, { value }) => ({
    ...state,
    invoices: {
      ...state.invoices,
      loading: false,
      error: value,
    },
  }),
  [ACTION_GENERATE_PROFORMA_INVOICE_REQUEST]: (state) => ({
    ...state,
    proformaInvoice: {
      ...state.proformaInvoice,
      loading: true,
    },
  }),
  [ACTION_GENERATE_PROFORMA_INVOICE_SUCCESS]: (state) => ({
    ...state,
    proformaInvoice: {
      ...state.proformaInvoice,
      loading: false,
    },
  }),
  [ACTION_GENERATE_PROFORMA_INVOICE_FAILURE]: (state) => ({
    ...state,
    proformaInvoice: {
      ...state.proformaInvoice,
      loading: false,
    },
  }),
  [ACTION_DOWNLOAD_INVOICE_REQUEST]: (state) => ({
    ...state,
    invoices: {
      ...state.invoices,
      loading: true,
    },
  }),
  [ACTION_DOWNLOAD_INVOICE_SUCCESS]: (state) => ({
    ...state,
    invoices: {
      ...state.invoices,
      error: defaultState.invoices.error,
      loading: false,
    },
  }),
  [ACTION_DOWNLOAD_INVOICE_FAILURE]: (state, { value }) => ({
    ...state,
    invoices: {
      ...state.invoices,
      loading: false,
      error: value,
    },
  }),
  [ACTION_AUTO_RECHARGE_CARD_REQUEST]: (state) => ({
    ...state,
    autoRecharge: {
      ...state.autoRecharge,
      loading: true,
    },
  }),
  [ACTION_AUTO_RECHARGE_CARD_SUCCESS]: (state, { value }) => ({
    ...state,
    autoRecharge: {
      ...state.autoRecharge,
      loading: false,
      data: {
        ...state.autoRecharge.data,
        ...value,
      },
    },
  }),
  [ACTION_AUTO_RECHARGE_CARD_FAILURE]: (state, { value }) => ({
    ...state,
    autoRecharge: {
      ...state.autoRecharge,
      loading: false,
      error: value,
    },
  }),
  [ACTION_GET_BALANCE_REQUEST]: (state) => ({
    ...state,
    autoRecharge: {
      ...state.autoRecharge,
      loading: true,
    },
  }),
  [ACTION_GET_BALANCE_SUCCESS]: (state, { value }) => ({
    ...state,
    autoRecharge: {
      ...state.autoRecharge,
      loading: false,
      data: {
        ...state.autoRecharge.data,
        ...value,
      },
    },
  }),
  [ACTION_GET_BALANCE_FAILURE]: (state, { value }) => ({
    ...state,
    autoRecharge: {
      ...state.autoRecharge,
      loading: false,
      error: value,
    },
  }),
  [ACTION_TRANSFER_FUNDS_REQUEST]: (state) => ({
    ...state,
    transferFunds: {
      ...state.transferFunds,
      loading: true,
    },
  }),
  [ACTION_TRANSFER_FUNDS_SUCCESS]: (state) => ({
    ...state,
    transferFunds: {
      ...state.transferFunds,
      loading: false,
    },
  }),
  [ACTION_TRANSFER_FUNDS_FAILURE]: (state) => ({
    ...state,
    transferFunds: {
      ...state.transferFunds,
      loading: false,
    },
  }),
})
