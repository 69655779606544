import { Theme, Typography } from '@mui/material'
import cx from 'classnames'
import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import { Balance as TBalance } from '@/app/types'
import CustomTooltip from '@/app/component/atom/tooltip'

type Props = {
  balance: TBalance
  balanceIsBelowNotificationAmount: boolean
}

const OrgBalance: React.FC<Props> = ({ balance, balanceIsBelowNotificationAmount }) => {
  const { classes } = useStyles()

  return (
    <div className={classes.container}>
      <CustomTooltip title="Add funds">
        <Link
          id="account-balance"
          to="/account/billing"
          className={cx(classes.balancePanel, {
            [classes.balanceWarn]: balanceIsBelowNotificationAmount,
            [classes.balanceNormal]: !balanceIsBelowNotificationAmount,
          })}
        >
          <Typography color="inherit" className={classes.balanceWrap}>
            {balance.balanceHuman ? (
              <>
                <span
                  className={classes.balanceItem}
                  style={{
                    marginRight: '0.25rem',
                  }}
                >
                  $
                </span>
                <span className={classes.balanceItem} id="org-balance">
                  {balance.balanceHuman}
                </span>
              </>
            ) : (
              <span className={classes.balanceItem}>Balance</span>
            )}
          </Typography>
        </Link>
      </CustomTooltip>
    </div>
  )
}

const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    display: 'flex',
  },
  balancePanel: {
    height: '64px',
    marginRight: '0.5rem',
    padding: '0 0.5rem',
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  balanceWrap: {
    display: 'flex',
    height: '100%',
  },
  balanceNormal: {
    backgroundColor: theme.palette.primary.dark,
  },
  balanceWarn: {
    backgroundColor: theme.palette.error.light,
  },
  balanceItem: {
    display: 'flex',
    fontSize: '1rem',
    alignItems: 'center',
    fontWeight: 'bold',
  },
}))

export default OrgBalance
