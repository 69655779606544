import {
  APIAction,
  EndCampaignAction,
  HangupAction,
  JumpAction,
  PlayAudioAction,
  ReplayQuestionAction,
  ResendQuestionAction,
  SendEmailAction,
  SendSMSAction,
  SendSMSPhoneNumberAction,
  SendWhatsappAction,
  SetPausedSubscriptionAction,
  SubscribeAction,
  TopupAction,
  TransferAction,
  UnsubscribeAction,
  UnsubscribeAllAction,
  UpdateContactAction,
} from './schemas/actions/all-actions'

export enum LocalActionType {
  API = 'api',
  FinishFlow = 'finishFlow',
  Hangup = 'hangup',
  Jump = 'jump',
  PauseSubscription = 'pauseSubscription',
  PlayAudio = 'voice',
  Replay = 'replay',
  Resend = 'resend',
  ResumeSubscription = 'resumeSubscription',
  SendEmail = 'sendEmail',
  SendSMS = 'sms',
  SendSMSToPhoneNumbers = 'sendSMSToPhoneNumbers',
  SendWhatsApp = 'sendWhatsapp',
  SubscribeToCampaign = 'subscribeToCampaign',
  Topup = 'topup',
  Transfer = 'transfer',
  Unsubscribe = 'unsubscribe',
  UnsubscribeAll = 'unsubscribeAll',
  UpdateContact = 'updateContact',
}

export type LocalAPIAction = APIAction & {
  type: LocalActionType.API
}

export type LocalEndCampaignAction = EndCampaignAction & {
  type: LocalActionType.FinishFlow
}

export type LocalHangupAction = HangupAction & {
  type: LocalActionType.Hangup
}

export type LocalJumpAction = JumpAction & {
  type: LocalActionType.Jump
}

export type LocalPauseSubscriptionAction = SetPausedSubscriptionAction & {
  type: LocalActionType.PauseSubscription
}

export type LocalPlayAudioAction = PlayAudioAction & {
  type: LocalActionType.PlayAudio
}

export type LocalReplayQuestionAction = ReplayQuestionAction & {
  type: LocalActionType.Replay
}

export type LocalResendQuestionAction = ResendQuestionAction & {
  type: LocalActionType.Resend
}

export type LocalResumeSubscriptionAction = SetPausedSubscriptionAction & {
  type: LocalActionType.ResumeSubscription
}

export type LocalSendEmailAction = SendEmailAction & {
  type: LocalActionType.SendEmail
}

export type LocalSendSMSAction = SendSMSAction & {
  type: LocalActionType.SendSMS
}

export type LocalSendSMSToPhoneNumbersAction = SendSMSPhoneNumberAction & {
  type: LocalActionType.SendSMSToPhoneNumbers
}

export type LocalSendWhatsAppAction = SendWhatsappAction & {
  type: LocalActionType.SendWhatsApp
}

export type LocalSubscribeToCampaignAction = SubscribeAction & {
  type: LocalActionType.SubscribeToCampaign
}

export type LocalTopupAction = TopupAction & {
  type: LocalActionType.Topup
}

export type LocalTransferAction = TransferAction & {
  type: LocalActionType.Transfer
}

export type LocalUnsubscribeAction = UnsubscribeAction & {
  type: LocalActionType.Unsubscribe
}

export type LocalUnsubscribeAllAction = UnsubscribeAllAction & {
  type: LocalActionType.UnsubscribeAll
}

export type LocalUpdateContactAction = UpdateContactAction & {
  type: LocalActionType.UpdateContact
}

export type LocalEmptyAction = {
  type?: ''
}

export type LocalNonEmptyAction =
  | LocalAPIAction
  | LocalEndCampaignAction
  | LocalHangupAction
  | LocalJumpAction
  | LocalPauseSubscriptionAction
  | LocalPlayAudioAction
  | LocalReplayQuestionAction
  | LocalResendQuestionAction
  | LocalResumeSubscriptionAction
  | LocalSendEmailAction
  | LocalSendSMSAction
  | LocalSendSMSToPhoneNumbersAction
  | LocalSendWhatsAppAction
  | LocalSubscribeToCampaignAction
  | LocalTopupAction
  | LocalTransferAction
  | LocalUnsubscribeAction
  | LocalUnsubscribeAllAction
  | LocalUpdateContactAction

/**
 * Local actions have an additional type field to make it easier to distinguish between different action types.
 * LocalEmptyAction is the placeholder for actions UI
 */
export type LocalAction = LocalNonEmptyAction | LocalEmptyAction
