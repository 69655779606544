import cookies from 'js-cookie'
import { createAsyncActions } from '@/app/service/util'
import { activateTrialMode } from '@/app/module/auth/store/actions'
import { createNotification } from '@/app/module/notifications/store/actions'
import request from '@/app/module/request'
import { getUser } from '@/app/module/user/store/actions'
import {
  ACTION_DELETE_PLAN_FAILURE,
  ACTION_DELETE_PLAN_REQUEST,
  ACTION_DELETE_PLAN_SUCCESS,
  ACTION_GET_PLANS_FAILURE,
  ACTION_GET_PLANS_REQUEST,
  ACTION_GET_PLANS_SUCCESS,
  ACTION_GET_PLAN_FAILURE,
  ACTION_GET_PLAN_REQUEST,
  ACTION_GET_PLAN_SUCCESS,
  ACTION_SUBSCRIBE_PLAN_FAILURE,
  ACTION_SUBSCRIBE_PLAN_REQUEST,
  ACTION_SUBSCRIBE_PLAN_SUCCESS,
  ERROR_DELETE_PLAN_SERVER,
  ERROR_GET_PLANS_SERVER,
  ERROR_GET_PLAN_SERVER,
  ERROR_SUBSCRIBE_PLAN_SERVER,
} from '@/app/module/plans/definitions'

const {
  request: getPlanRequest,
  success: getPlanSuccess,
  failure: getPlanFailure,
} = createAsyncActions({
  request: ACTION_GET_PLAN_REQUEST,
  success: ACTION_GET_PLAN_SUCCESS,
  failure: ACTION_GET_PLAN_FAILURE,
})

const {
  request: getPlansRequest,
  success: getPlansSuccess,
  failure: getPlansFailure,
} = createAsyncActions({
  request: ACTION_GET_PLANS_REQUEST,
  success: ACTION_GET_PLANS_SUCCESS,
  failure: ACTION_GET_PLANS_FAILURE,
})

const {
  request: subscribePlanRequest,
  success: subscribePlanSuccess,
  failure: subscribePlanFailure,
} = createAsyncActions({
  request: ACTION_SUBSCRIBE_PLAN_REQUEST,
  success: ACTION_SUBSCRIBE_PLAN_SUCCESS,
  failure: ACTION_SUBSCRIBE_PLAN_FAILURE,
})

const {
  request: deletePlanRequest,
  success: deletePlanSuccess,
  failure: deletePlanFailure,
} = createAsyncActions({
  request: ACTION_DELETE_PLAN_REQUEST,
  success: ACTION_DELETE_PLAN_SUCCESS,
  failure: ACTION_DELETE_PLAN_FAILURE,
})

export {
  deletePlanFailure,
  deletePlanRequest,
  deletePlanSuccess,
  getPlanFailure,
  getPlanRequest,
  getPlanSuccess,
  getPlansFailure,
  getPlansRequest,
  getPlansSuccess,
  subscribePlanFailure,
  subscribePlanRequest,
  subscribePlanSuccess,
}

export const getPlan =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(getPlanRequest())
    return request.plan
      .get({
        token,
        orgId,
        query: {},
      })
      .then((res) => {
        dispatch(getPlanSuccess(res))
      })
      .catch((err) => {
        dispatch(
          getPlanFailure({
            status: err.status,
            message: ERROR_GET_PLAN_SERVER,
          }),
        )
        return err
      })
  }

export const getPlans =
  ({ token }) =>
  (dispatch) => {
    dispatch(getPlansRequest())
    return request.plan
      .getList({
        token,
      })
      .then((res) => {
        dispatch(getPlansSuccess(res))
      })
      .catch((err) => {
        dispatch(
          getPlansFailure({
            status: err.status,
            message: ERROR_GET_PLANS_SERVER,
          }),
        )
        return err
      })
  }

export const subscribePlan =
  ({ token, orgId, item }) =>
  (dispatch) => {
    dispatch(subscribePlanRequest())
    return request.plan
      .post({
        token,
        orgId,
        item,
      })
      .then((res) => {
        dispatch(subscribePlanSuccess(res))
        dispatch(getUser({ token }))
        dispatch(activateTrialMode({ trialMode: false }))
        cookies.remove('trialMode')
        dispatch(
          createNotification({
            'subscription-success': {
              type: 'success',
              message: `Thank you for subscribing to the "${item.label}" plan.`,
            },
          }),
        )
      })
      .catch((err) => {
        dispatch(
          createNotification({
            'subscription-error': {
              type: 'error',
              message: ERROR_SUBSCRIBE_PLAN_SERVER,
            },
          }),
        )
        dispatch(
          subscribePlanFailure({
            status: err.status,
            message: ERROR_SUBSCRIBE_PLAN_SERVER,
          }),
        )
        return err
      })
  }

export const deletePlan =
  ({ token, orgId }) =>
  (dispatch) => {
    dispatch(deletePlanRequest())
    return request.plan
      .delete({
        token,
        orgId,
        query: {},
      })
      .then((res) => {
        dispatch(deletePlanSuccess(res))
      })
      .catch((err) => {
        dispatch(
          createNotification({
            'cancel-subscription-error': {
              type: 'error',
              message: ERROR_DELETE_PLAN_SERVER,
            },
          }),
        )
        dispatch(
          deletePlanFailure({
            status: err.status,
            message: ERROR_DELETE_PLAN_SERVER,
          }),
        )
        return err
      })
  }
