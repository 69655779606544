import { createAsyncState } from '@/app/service/util'
import { Export, ExportQuery } from '@/app/module/exports/types'

export type ExportState = {
  data: {
    exports: {
      list: Export[]
      hasMore?: boolean
      query: ExportQuery
    }
  }
  error: unknown
}

const defaultState = createAsyncState({
  data: {
    exports: {
      list: [],
      query: {},
    },
  },
}) as ExportState

export default defaultState
