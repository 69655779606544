import { createReducer } from '@/app/service/util'
import {
  ACTION_CLOSE_CATI_SURVEY_FAILURE,
  ACTION_CLOSE_CATI_SURVEY_REQUEST,
  ACTION_CLOSE_CATI_SURVEY_SUCCESS,
  ACTION_GET_CATI_FAILURE,
  ACTION_GET_CATI_REQUEST,
  ACTION_GET_CATI_STEP_FAILURE,
  ACTION_GET_CATI_STEP_REQUEST,
  ACTION_GET_CATI_STEP_SUCCESS,
  ACTION_GET_CATI_SUCCESS,
  ACTION_GET_ORG_SUBS_FAILURE,
  ACTION_GET_ORG_SUBS_REQUEST,
  ACTION_GET_ORG_SUBS_SUCCESS,
  ACTION_GET_SUB_DETAILS_FAILURE,
  ACTION_GET_SUB_DETAILS_REQUEST,
  ACTION_GET_SUB_DETAILS_SUCCESS,
  ACTION_ORG_SUBS_NAVIGATE,
  ACTION_ORG_SUBS_PAGE_SIZE,
  ACTION_SET_CATI_STATUS_FAILURE,
  ACTION_SET_CATI_STATUS_REQUEST,
  ACTION_SET_CATI_STATUS_SUCCESS,
} from '@/app/module/cati/definitions'
import { CATIType } from '@/app/module/cati/types'
import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_GET_CATI_REQUEST]: (state: typeof defaultState) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: defaultState.item.error,
    },
  }),
  [ACTION_GET_CATI_SUCCESS]: (state: typeof defaultState, { value }: { value: CATIType }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: defaultState.item.error,
      data: {
        ...state.item.data,
        ...value,
      },
    },
  }),
  [ACTION_GET_CATI_FAILURE]: (state: typeof defaultState, { value }: { value: string }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
  [ACTION_GET_CATI_STEP_REQUEST]: (state: typeof defaultState) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: defaultState.item.error,
    },
  }),
  [ACTION_GET_CATI_STEP_SUCCESS]: (state: typeof defaultState, { value }: { value: { state: any } }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: defaultState.item.error,
      data: {
        ...state.item.data,
        ...value,
      },
    },
  }),
  [ACTION_GET_CATI_STEP_FAILURE]: (state: typeof defaultState, { value }: { value: string }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
  [ACTION_GET_ORG_SUBS_REQUEST]: (state: typeof defaultState) => ({
    ...state,
    subs: {
      ...state.subs,
      loading: true,
      error: defaultState.subs.error,
    },
  }),
  [ACTION_GET_ORG_SUBS_SUCCESS]: (state: typeof defaultState, { value }: { value: { subs: any; total: number } }) => ({
    ...state,
    subs: {
      ...state.subs,
      loading: false,
      initialized: true,
      error: defaultState.subs.error,
      data: {
        ...state.subs.data,
        values: value.subs,
        total: value.total,
      },
    },
  }),
  [ACTION_GET_ORG_SUBS_FAILURE]: (state: typeof defaultState, { value }: { value: string }) => ({
    ...state,
    subs: {
      ...state.subs,
      loading: false,
      initialized: true,
      error: value,
    },
  }),
  [ACTION_ORG_SUBS_PAGE_SIZE]: (state: typeof defaultState, { value }: { value: number }) => ({
    ...state,
    subs: {
      ...state.subs,
      data: {
        ...state.subs.data,
        size: value,
      },
    },
  }),
  // NAVIGATE
  [ACTION_ORG_SUBS_NAVIGATE]: (
    state: typeof defaultState,
    { value }: { value: { page: number; searchTerm: string } },
  ) => ({
    ...state,
    subs: {
      ...state.subs,
      data: {
        ...state.subs.data,
        page: value.page,
        searches: {
          ...state.subs.data.searches,
          active: value.searchTerm || false,
        },
      },
    },
  }),
  [ACTION_GET_SUB_DETAILS_REQUEST]: (state: typeof defaultState) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: defaultState.item.error,
    },
  }),
  [ACTION_GET_SUB_DETAILS_SUCCESS]: (state: typeof defaultState, { value }: { value: CATIType }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: defaultState.item.error,
      data: {
        ...state.item.data,
        userStatus: value.userStatus,
      },
    },
  }),
  [ACTION_GET_SUB_DETAILS_FAILURE]: (state: typeof defaultState, { value }: { value: string }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
  [ACTION_SET_CATI_STATUS_REQUEST]: (state: typeof defaultState) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: defaultState.item.error,
    },
  }),
  [ACTION_SET_CATI_STATUS_SUCCESS]: (state: typeof defaultState, { value }: { value: CATIType }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: defaultState.item.error,
      data: {
        ...state.item.data,
        userStatus: value.userStatus,
      },
    },
  }),
  [ACTION_SET_CATI_STATUS_FAILURE]: (state: typeof defaultState, { value }: { value: string }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
  [ACTION_CLOSE_CATI_SURVEY_REQUEST]: (state: typeof defaultState) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
      error: defaultState.item.error,
    },
  }),
  [ACTION_CLOSE_CATI_SURVEY_SUCCESS]: (state: typeof defaultState) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
    },
  }),
  [ACTION_CLOSE_CATI_SURVEY_FAILURE]: (state: typeof defaultState, { value }: { value: string }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
})
