import { createAsyncState } from '@/app/service/util'

export default createAsyncState({
  data: {
    conversations: [],
    loading: false,
    loadingList: [],
    nextPage: '',
    error: '',
    contactInfo: {},
    initialized: false,
  },
})
