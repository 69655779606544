export const CONTACTS_DEFAULT_FIELD_CHAR_LIMIT = 500
export const CONTACTS_CUSTOM_FIELD_CHAR_LIMIT = 1500
export const GROUPS_DEFAULT_FIELD_CHART_LIMIT = 100

// ACTIONS

export const ACTION_GET_CONTACTS_REQUEST = '@actions/CONTACTS/GET_LIST_REQUEST'
export const ACTION_GET_CONTACTS_SUCCESS = '@actions/CONTACTS/GET_LIST_SUCCESS'
export const ACTION_GET_CONTACTS_FAILURE = '@actions/CONTACTS/GET_LIST_FAILURE'

export const ACTION_GET_CONTACTS_ITEM_REQUEST = '@actions/CONTACTS/GET_ITEM_REQUEST'
export const ACTION_GET_CONTACTS_ITEM_SUCCESS = '@actions/CONTACTS/GET_ITEM_SUCCESS'
export const ACTION_GET_CONTACTS_ITEM_FAILURE = '@actions/CONTACTS/GET_ITEM_FAILURE'

export const ACTION_SAVE_CONTACTS_ITEM_REQUEST = '@actions/CONTACTS/SAVE_ITEM_REQUEST'
export const ACTION_SAVE_CONTACTS_ITEM_SUCCESS = '@actions/CONTACTS/SAVE_ITEM_SUCCESS'
export const ACTION_SAVE_CONTACTS_ITEM_FAILURE = '@actions/CONTACTS/SAVE_ITEM_FAILURE'

export const ACTION_DELETE_CONTACTS_ITEM_REQUEST = '@actions/CONTACTS/DELETE_ITEM_REQUEST'
export const ACTION_DELETE_CONTACTS_ITEM_SUCCESS = '@actions/CONTACTS/DELETE_ITEM_SUCCESS'
export const ACTION_DELETE_CONTACTS_ITEM_FAILURE = '@actions/CONTACTS/DELETE_ITEM_FAILURE'

export const ACTION_DELETE_CONTACTS_REQUEST = '@actions/CONTACTS/DELETE_REQUEST'
export const ACTION_DELETE_CONTACTS_SUCCESS = '@actions/CONTACTS/DELETE_SUCCESS'
export const ACTION_DELETE_CONTACTS_FAILURE = '@actions/CONTACTS/DELETE_FAILURE'

export const ACTION_UPLOAD_CONTACTS_REQUEST = '@actions/CONTACTS/UPLOAD_REQUEST'
export const ACTION_UPLOAD_CONTACTS_SUCCESS = '@actions/CONTACTS/UPLOAD_SUCCESS'
export const ACTION_UPLOAD_CONTACTS_FAILURE = '@actions/CONTACTS/UPLOAD_FAILURE'

export const ACTION_UPLOAD_CONFIRM_CONTACTS_REQUEST = '@actions/CONTACTS/UPLOAD_CONFIRM_REQUEST'
export const ACTION_UPLOAD_CONFIRM_CONTACTS_SUCCESS = '@actions/CONTACTS/UPLOAD_CONFIRM_SUCCESS'
export const ACTION_UPLOAD_CONFIRM_CONTACTS_FAILURE = '@actions/CONTACTS/UPLOAD_CONFIRM_FAILURE'

export const ACTION_DOWNLOAD_CONTACTS_REQUEST = '@actions/CONTACTS/DONWLOAD_REQUEST'
export const ACTION_DOWNLOAD_CONTACTS_SUCCESS = '@actions/CONTACTS/DONWLOAD_SUCCESS'
export const ACTION_DOWNLOAD_CONTACTS_FAILURE = '@actions/CONTACTS/DONWLOAD_FAILURE'

export const ACTION_DOWNLOAD_INVALID_CONTACTS_REQUEST = '@actions/CONTACTS/UPLOAD_GET_INVALID_FILE_REQUEST'
export const ACTION_DOWNLOAD_INVALID_CONTACTS_SUCCESS = '@actions/CONTACTS/UPLOAD_GET_INVALID_FILE_SUCCESS'
export const ACTION_DOWNLOAD_INVALID_CONTACTS_FAILURE = '@actions/CONTACTS/UPLOAD_GET_INVALID_FILE_FAILURE'

export const ACTION_CONTACTS_NAVIGATE = '@actions/CONTACTS/NAVIGATE'
export const ACTION_CONTACTS_PAGE_SIZE = '@actions/CONTACTS/PAGE_SIZE'
export const ACTION_UPDATE_CONTACTS_ITEM = '@actions/CONTACTS/UPDATE_ITEM'
export const ACTION_RESET_CONTACTS = '@action/CONTACTS/RESET'
export const ACTION_SAVE_TABLE_HEADERS = '@action/CONTACTS/SAVE_TABLE_HEADERS'

// ERRORS

export const ERROR_GET_CONTACTS_SERVER = 'Failed to get contacts details. Please try again later.'
export const ERROR_GET_CONTACTS_ITEM_SERVER = 'This contact has been deleted. Their information is no longer available.'
export const ERROR_SAVE_CONTACTS_ITEM_SERVER = 'Error saving contact:'
export const ERROR_DELETE_CONTACTS_ITEM_SERVER = 'Failed to delete contacts item. Please try again later.'
export const ERROR_DELETE_CONTACTS_SERVER = 'Failed to delete selected contacts. Please try again later.'
export const ERROR_UPLOAD_CONTACTS_SERVER =
  'Failed to upload file. Please make sure your columns contain valid data, and try again.'
export const ERROR_UPLOAD_CONTACTS_CONFIRM_SERVER = 'Failed to validate configuration. Please try again later.'
export const ERROR_UPLOAD_CONTACTS_STATUS_SERVER =
  'Failed to retrieve processing status. Please try refreshing your page.'
export const SUCCESS_UPLOAD_CONTACTS_STATUS_SERVER = 'Contacts added successfully.'
export const ERROR_DOWNLOAD_INVALID_SERVER =
  'Something went wrong, unable to downoad file showing the invalid contacts. To get more information, please contact our customer support.'
export const ERROR_DOWNLOAD_SERVER = 'Failed to download selected contacts. Please try again later.'
export const ERROR_UPLOAD_CONTACTS_EMPTY =
  'Failed to upload file. Please make sure the first sheet in your contacts file is not empty, and try again.'
