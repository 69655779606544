import React from 'react'
import { styled } from '@mui/material/styles'

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',

  '& > *:first-of-type': {
    marginRight: '0.5rem',
  },
})

type IconTextProps = {
  children: React.ReactNode
}

function IconText({ children }: IconTextProps) {
  return <Container>{children}</Container>
}

export default IconText
