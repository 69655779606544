import { configureStore } from '@reduxjs/toolkit'
import config from '@/config'
import middlewares from './middlewares'
import reducer from './reducer'

export type RootState = ReturnType<typeof reducer>

export default (state: RootState) => {
  const store = configureStore({
    devTools: config.env.app !== 'production',
    preloadedState: state,
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares),
  })

  if (import.meta.hot) {
    import.meta.hot.accept('./reducer', () => store.replaceReducer(reducer))
  }

  return store
}
