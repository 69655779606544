import { v1 as uuid } from 'uuid'
import { defaultOnAnswerTextNoActions } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/content'
import {
  Offset,
  PartFamily,
  ReminderMessage,
  ReminderSMSMessage,
  ReminderActionMessage,
  TimeUnit,
  ReminderSMSQuestion,
  CampaignTemplateType,
} from '@/app/module/campaigns/types'
import { ensureUniqueIdForMessageActions } from '@/app/module/campaigns/utils/actions/ids'
import { getDefaultFailureAction } from '@/app/module/campaigns/component/item/steps/content/types/multimessage/utils/empty-message'

const createDefaultOffset = (timezone: string): Offset => ({
  unit: TimeUnit.Hour,
  timezone,
  value: 0,
})

const createSMSMessage = (timezone: string): ReminderSMSMessage => {
  return {
    changed: false,
    hasQuestion: false,
    id: uuid(),
    offset: createDefaultOffset(timezone),
    type: PartFamily.SMS,
    message: {
      text: '',
    },
  }
}

const createSMSQuestion = (timezone: string): ReminderSMSQuestion => {
  return {
    changed: false,
    id: uuid(),
    offset: createDefaultOffset(timezone),
    hasQuestion: true,
    type: PartFamily.SMS,
    message: {
      text: '',
    },
    onAnswer: defaultOnAnswerTextNoActions,
    onInvalidReply: {
      actions: [],
    },
    onRetriesExhausted: getDefaultFailureAction(CampaignTemplateType.Reminder),
    onTimeout: {
      timeout: undefined,
      actions: [],
    },
    retries: 1,
    useAi: false,
  }
}

const createActionsOnly = (timezone: string): ReminderActionMessage => {
  return {
    changed: false,
    id: uuid(),
    offset: createDefaultOffset(timezone),
    hasQuestion: true,
    type: PartFamily.Action,
    actionsOnly: {
      actions: [],
    },
  }
}

export function createNewReminderMessage(args: { contentType: 'sms-m'; timezone: string }): ReminderSMSMessage
export function createNewReminderMessage(args: { contentType: 'sms-q'; timezone: string }): ReminderSMSQuestion
export function createNewReminderMessage(args: { contentType: 'actions-only'; timezone: string }): ReminderActionMessage
export function createNewReminderMessage(args: { contentType: 'sms-m' | 'sms-q' | 'actions-only'; timezone: string }) {
  switch (args.contentType) {
    case 'sms-m':
      return createSMSMessage(args.timezone)
    case 'sms-q':
      return createSMSQuestion(args.timezone)
    case 'actions-only':
      return createActionsOnly(args.timezone)
    default:
      throw new Error('Invalid content type')
  }
}

export const duplicateReminderMessage = (message: ReminderMessage) => {
  const id = uuid()

  const actionsData = ensureUniqueIdForMessageActions(message)
  return {
    ...message,
    ...actionsData,
    changed: false,
    id,
  }
}
