import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'

const Container = styled('div')({
  position: 'absolute',
  top: '0',
  left: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255,255,255,0.65)',
  zIndex: '10',
})

function Loader() {
  return (
    <Container>
      <CircularProgress color="primary" />
    </Container>
  )
}

export default Loader
