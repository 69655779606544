import { z } from 'zod'
import { ZCondition } from '@/app/module/campaigns/types/schemas/common'

const ZBasePart = z.object({
  id: z.string(),
  label: z.string().optional(),
  when: ZCondition.optional().nullable(),
})

export default ZBasePart
