import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import config from '@/config'
import createIntercom from '@/app/service/intercom/middleware'
import { esApi } from '@/__generated/es-api'
import { ACTION_AUTH_LOGOUT, ACTION_AUTH_SIGNUP_SUCCESS } from '@/app/module/auth/definitions'
import { ACTION_EXPORT_CAMPAIGN_REPORT } from '@/app/module/campaigns/definitions'
import { ACTION_GET_USER_SUCCESS } from '@/app/module/user/definitions'
import wsMiddleware from './ws-middleware'

const mwLogging = ['development', 'staging'].indexOf(config.env.app) > -1

const intercom = createIntercom(mwLogging, {
  EXPORT_CAMPAIGN_REPORT: ACTION_EXPORT_CAMPAIGN_REPORT,
  GET_USER_SUCCESS: ACTION_GET_USER_SUCCESS,
  LOGOUT: ACTION_AUTH_LOGOUT,
  SIGNUP_SUCCESS: ACTION_AUTH_SIGNUP_SUCCESS,
})

const middlewares = [thunk, intercom, wsMiddleware, esApi.middleware]

if (mwLogging) {
  middlewares.push(
    createLogger({
      collapsed: true,
    }),
  )
}

export default middlewares
