import { z } from 'zod'
import ZAction from '@/app/module/campaigns/types/schemas/actions/all-actions'
import ZBasePart from './base'

const ZActionsOnlyPart = ZBasePart.extend({
  actionsOnly: z.object({
    actions: z.array(ZAction),
  }),
})

export default ZActionsOnlyPart
