import { map, pathOr, pick } from 'ramda'
import { createSelector } from 'reselect'
import { selectUserTimezone } from '@/app/module/user/store/selectors'

const partSelectPageValue = pathOr('1', ['segments', 'list', 'data', 'page'])

const partSelectPageIds = pathOr({}, ['segments', 'list', 'data', 'pages'])

const partSelectValues = pathOr({}, ['segments', 'list', 'data', 'values'])

export const selectOrdered = createSelector(partSelectPageValue, partSelectPageIds, (page, pages) =>
  pathOr([], [page], pages),
)

export const selectHeaders = () => [
  {
    title: 'Name',
    fields: ['name'],
  },
  {
    title: 'Created',
    fields: ['createdAt'],
  },
  {
    title: 'Number of contacts',
    fields: ['numberOfContacts'],
  },
]

export const partSelectPage = createSelector(selectOrdered, partSelectValues, pick)

export const selectSegmentValues = createSelector(partSelectValues, selectOrdered, (segments, ordered) => [
  ...map(
    (index) => ({
      label: segments[index].name,
      value: index.toString(),
      numberOfContacts: segments[index].numberOfContacts,
      recounting: segments[index].recounting,
    }),
    ordered,
  ),
])

export const selectSegmentsPageValues = createSelector(partSelectValues, selectOrdered, (segments, ordered) => [
  ...map((index) => segments[index], ordered),
])

export const selectItemConfig = createSelector(selectUserTimezone, (tz) => ({
  id: {
    type: 'hidden',
    display: 'hidden',
  },
  name: {
    type: 'text',
    display: 'text',
  },
  createdAt: {
    type: 'datetime',
    display: 'datetime',
    tz,
  },
  numberOfContacts: {
    type: 'text',
    display: 'text',
    icon: 'group',
  },
}))

const getValues =
  ({ headers }) =>
  (item) => ({
    ...headers.reduce(
      (acc, { fields }) => ({
        ...acc,
        ...pick(fields, item),
      }),
      {},
    ),
  })

export const selectPage = createSelector(selectHeaders, partSelectPage, (headers, items = {}) =>
  map(getValues({ headers }), items),
)
