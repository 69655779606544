import { z } from 'zod'
import { BaseLanguageType, SenderIdsForDripType } from '@/app/module/campaigns/helpers'
import ReminderCampaignClass from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import { HTTPMethod } from '@/app/module/campaigns/types-api'
import { ContactType } from '@/app/module/conversations/types'
import { Condition, CountryTimezone, SelectOption } from '@/app/types'
import { LocalAction } from './actions'
import { Message as TMessage } from '.'

import { PartFamily, PartTypes } from './part'
import { Action } from './schemas/actions/all-actions'
import { ZReminderDefaultArgs, ZReminderPart } from './schemas/campaigns/reminder'
import {
  ZCallSchedule,
  ZCallWindow,
  ZCallWindowDay,
  ZCallWindowItem,
  ZCallWindowSlots,
  ZDelta,
  ZLaunch,
  ZOffset,
} from './schemas/common'
import { ZAnswerCategory } from './schemas/response/open-ended'
import { BackendCampaignTemplate, CampaignTemplateType } from './template'

export type { Action }

export type TabTypes = 'spoken' | 'keypress' | 'speech'

export type SpokenType = {
  maxSeconds?: number
  silence?: number
  stopKey?: string
  transcribe: boolean
}

export type ChoiceListType = {
  replies?: string[]
  ranges?: RangeType[]
  reply?: string
  speechReplies?: SpeechRepliesType
}

export type ChoiceValueMultiple = {
  replies: string[]
  ranges: RangeType[]
  speech?: boolean
}

export type ChoiceValueSingle = {
  reply: string
}

export type RangeType = {
  min: number
  max: number
}

export type ReplyType = string

export type SpeechReplyType = string[]

export type SpeechRepliesType = Record<string, SpeechReplyType>

export type FileType = {
  filename: string
  title: string
  category: string
  thumb?: string
  url: string
}

export type FileUploadType = {
  file: File
  filename: string
  filter?: string
  type: string
}

export type FilesType = Record<string, FileType>

export type TimezoneWithCountryCodeType = {
  [key: string]: {
    country: string
    tzs: SingleTimezoneType[]
  }
}

export type SingleTimezoneType = {
  city: string
  offset: string
  timezone: string
}

export type ActionSnippetType = {
  id: number
  category: 'action'
  file: FilesType[]
  snippet: any
  userId: number
  value: boolean
}

export type MessageSnippetType = {
  id: number
  category: 'message'
  file: FilesType[]
  snippet: TMessage | ReminderMessage
  userId: number
  value: boolean
}

export type SnippetType = ActionSnippetType | MessageSnippetType

export type SenderIdOption = SelectOption<string> & {
  type: 'phonenumber' | 'sender'
}

export type PersonalizationType = Record<string, SinglePersonalizationType[]>

export type SinglePersonalizationType = {
  label: string
  value: string
}

export type DataMap = {
  [key: string]: any
}

export const dayOfWeekMap: DataMap = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
}

export enum TimeUnit {
  Second = 'second',
  Minute = 'minute',
  Hour = 'hour',
  Day = 'day',
  Week = 'week',
  Month = 'month',
  At = 'at',
  Immediate = 'immediate',
}

export type DelayState = {
  at?: string
  country: string
  dayOfWeek: string
  daysOfWeek: Record<string, boolean>
  relative?: string
  time: string
  timezone: string
  timezones: CountryTimezone[]
  unit: TimeUnit
  value: number
  valueError: string
}

export type EstimateResponseType = {
  message: string
  protip: string
}

export type TimezoneType = {
  label: string
}

export type Delta = z.infer<typeof ZDelta>

export type Offset = z.infer<typeof ZOffset>

export type CampaignType = {
  type: string
}

export type CampaignQuery = {
  aborted?: string
  archived?: string
  launched?: string
  open?: string
  page: number
  published?: string
  search?: string
  size: number
  templates?: string
}

export type SendEmailData = {
  toMultiple?: string[]
  to?: string
  from: string
  message: string
  subject: string
}

export type OnAnswerType = {
  replies?: ReplyType[]
  speechReplies?: SpeechRepliesType
  ranges?: RangeType[]
  label?: string
  actions?: Action[]
  reply?: string
}

export type LocalOnAnswerType = Omit<OnAnswerType, 'actions'> & {
  actions: LocalAction[]
}

export type OnInvalidReply = {
  enabled?: boolean
  actions?: Action[]
}

export type LocalOnInvalidReply = Omit<OnInvalidReply, 'actions'> & {
  actions: LocalAction[]
}

export type OnRetriesExhausted = {
  enabled?: boolean
  actions?: Action[]
}

export type LocalOnRetriesExhausted = Omit<OnRetriesExhausted, 'actions'> & {
  actions: LocalAction[]
}

export type ActionsOnly = {
  actions: Action[]
}

export type LocalActionsOnly = {
  actions: LocalAction[]
}

export type OnTimeout = {
  enabled?: boolean
  actions?: Action[]
  timeout?: {
    unit: string
    value: number
  }
  timeoutSeconds?: number
}

export type LocalOnTimeout = Omit<OnTimeout, 'actions'> & {
  actions: LocalAction[]
}

export type AnswerCategory = z.infer<typeof ZAnswerCategory>

export type LocalAnswerCategory = {
  category: string
  actions: LocalAction[]
}

export type OpenEnded = {
  actions?: Action[]
  categories?: AnswerCategory[]
}

export type LocalOpenEnded = {
  actions?: LocalAction[]
  categories?: LocalAnswerCategory[]
}

export type Spoken = SpokenType & {
  actions: Action[]
}

export type LocalSpoken = Omit<Spoken, 'actions'> & {
  actions: LocalAction[]
}

export type Translation = {
  document?: string
  image?: string
  playfile?: string
  text?: string
  say?: string
  voice?: string
}

export type SpeechSettings = {
  languages: { '': string } & Record<string, string>
  speechAfterAudio: boolean
  timeoutSeconds?: number
}

export type ContentSMS = {
  text: string
  translations?: Record<string, Translation>
}

export type ContentAudio = {
  playfile?: string
  translations?: Record<string, Translation>
  say?: string
  voice?: string
}

export type ContentWhatsApp = {
  audio?: string | null
  document?: string | null
  image?: string | null
  language?: string | null
  text?: string | null
  translations?: Record<string, Translation>
  video?: string | null
  registerTemplate?: boolean
}

export type ContentTopup = {
  desiredAmount?: string
  maxAmount?: string
  onError?: Action[]
  onSuccess?: Action[]
}

export type ContentAPI = {
  method: HTTPMethod
  url: string
  contentType?: string
  headers?: Record<string, string[]>
  body?: string
  onSuccess?: Action[]
  onError?: Action[]
  retries?: number
}

export type BaseMessage = {
  id: string
  autoFocus?: boolean
  changed: boolean
  callResultActions?: ConditionActions[]
  delta?: Delta
  hasQuestion: boolean
  label?: string
  offset?: Offset
  when?: Condition | null
}

export type SMSMessage = BaseMessage & {
  type: PartFamily.SMS
  hasQuestion: false
  message: ContentSMS
}

export type SMSQuestion = BaseMessage & {
  type: PartFamily.SMS
  hasQuestion: true
  message: ContentSMS
  useAi: boolean
  onAnswer: OnAnswerType[]
  onInvalidReply: OnInvalidReply
  onRetriesExhausted: OnRetriesExhausted
  onTimeout: OnTimeout
  openEnded?: OpenEnded
  retries: number
}

export type VoiceMessage = BaseMessage & {
  type: PartFamily.Voice
  hasQuestion: false
  audio: ContentAudio
}

export type VoiceQuestion = BaseMessage & {
  type: PartFamily.Voice
  hasQuestion: true
  audio: ContentAudio
  onAnswer: OnAnswerType[]
  onInvalidReply: OnInvalidReply
  onRetriesExhausted: OnRetriesExhausted
  onTimeout: OnTimeout
  speechSettings?: SpeechSettings
  spoken?: Spoken
  retries: number
}

export type WhatsAppMessage = BaseMessage & {
  type: PartFamily.WhatsApp
  hasQuestion: false
  message: ContentWhatsApp
}

export type WhatsAppQuestion = BaseMessage & {
  type: PartFamily.WhatsApp
  hasQuestion: true
  message: ContentWhatsApp
  onAnswer: OnAnswerType[]
  onButtons?: TWhatsAppButtons
  onInvalidReply: OnInvalidReply
  onList?: TWhatsAppList
  onRetriesExhausted: OnRetriesExhausted
  onTimeout: OnTimeout
  openEnded?: OpenEnded
  retries: number
  useAi: boolean
  transcribe: boolean
}

export type ActionMessage = BaseMessage & {
  type: PartFamily.Action
  hasQuestion: true
  actionsOnly: ActionsOnly
}

export type TopupMessage = BaseMessage & {
  type: PartFamily.Topup
  hasQuestion: true
  topup: ContentTopup
}

export type APIMessage = BaseMessage & {
  type: PartFamily.API
  hasQuestion: true
  apiCall: ContentAPI
}

export type CATIMessage = BaseMessage & {
  type: PartFamily.CATI
  hasQuestion: false
  message: ContentSMS
}

export type CATIQuestion = BaseMessage & {
  type: PartFamily.CATI
  hasQuestion: true
  message: ContentSMS
  onAnswer: OnAnswerType[]
  openEnded?: Omit<OpenEnded, 'categories'>
}

export type Message =
  | APIMessage
  | ActionMessage
  | SMSMessage
  | SMSQuestion
  | TopupMessage
  | VoiceMessage
  | VoiceQuestion
  | WhatsAppMessage
  | WhatsAppQuestion
  | CATIMessage
  | CATIQuestion

export type ReminderBaseMessage<T> = Omit<T, 'delta'> & {
  offset: Offset
}

export type ReminderSMSMessage = ReminderBaseMessage<SMSMessage>

export type ReminderSMSQuestion = ReminderBaseMessage<SMSQuestion>

export type ReminderActionMessage = ReminderBaseMessage<ActionMessage>

export type ReminderMessage = ReminderSMSMessage | ReminderSMSQuestion | ReminderActionMessage

export type Language = {
  name: string
  value: string
}

export type ContentProperty = 'playfile' | 'text' | 'image' | 'audio' | 'video' | 'document' | 'say' | 'voice'

export type MessageContent = {
  name: string
  qunwrap: string[]
  paths: string[][]
  unwrap: string | string[]
  wrap: string

  labels?: Record<string, { title: string; key: string }>
  qSenderId?: string
  qwrap?: string
}

export type CampaignMessageContent = Record<string, MessageContent>

export type CampaignDefinition = {
  defaultType: string
  icon: string
  isSurvey?: boolean
  name: string
  senderIdInfo: string
  template: string
  title: string
  url: string

  callResultActions?: boolean
  callResultActionsPath?: string[]
  content?: CampaignMessageContent
  reconnectActions?: boolean
  reconnectActionsPath?: string[]
  welcome?: boolean
  welcomeKey?: string
  welcomeType?: string
  welcomeTypeProperties?: ContentProperty[]
}

type PartContent<T> = Omit<T, 'delta' | 'hasQuestion' | 'id' | 'type' | 'changed'>

export type PartSMSMessage = PartContent<SMSMessage>

export type PartSMSQuestion = PartContent<SMSQuestion>

export type PartVoiceMessage = PartContent<VoiceMessage>

export type PartVoiceQuestion = PartContent<VoiceQuestion>

export type PartWhatsAppMessage = PartContent<WhatsAppMessage>

export type PartWhatsAppQuestion = PartContent<WhatsAppQuestion>

export type PartAction = ActionMessage['actionsOnly']

export type PartTopup = TopupMessage['topup']

export type PartAPI = APIMessage['apiCall']

export type BasePart = { label?: string; when?: Condition | null } & Pick<Message, 'delta' | 'id'>

export type Part = DripPart | IVRPart | SMSSurveyPart | WhatsAppSurveyPart | CATIPart | ReminderPart

export type CallWindowItem = z.infer<typeof ZCallWindowItem>

export type CallWindowDay = z.infer<typeof ZCallWindowDay>

export type CallWindowSlots = z.infer<typeof ZCallWindowSlots>

export type CallWindow = z.infer<typeof ZCallWindow>

export type CallSchedule = z.infer<typeof ZCallSchedule>

export type IndexedCallSchedule = CallSchedule & {
  index: number
}

export interface BaseCampaignItem {
  aborted: boolean
  abortedAt: string
  amd: boolean
  archived: boolean
  autoLaunchAt: string
  callAgainCustom?: CallSchedule[]
  callAgainMax: number
  callWindow: CallWindow
  contacts: {
    allContactsAreSelected: boolean
    excludedContactIds: number[]
    includedContactIds: number[]
    includedGroupIds: number[]
    search: string
  }
  files: FilesType
  launch: {
    immediate: boolean
    at?: string
    timezone?: string
  }
  launchOpen: boolean
  launched: boolean
  launchedAt: string
  name: string
  open: boolean
  published: boolean
  reconnectMax: number
  reconnectOnProgress: boolean
  runs: number
  runsDone: number
  runsError: number
  runsInProgress: number
  runsPaused: number
  runsWithFailures: number
  uiStore: Record<string, any>
  userStatuses: string[]
}

type Actions = {
  actions: Action[] | null
}

export type ConditionActions = Actions & {
  when: Condition | null
}

export type WrappedPartActions = {
  [PartTypes.ActionsOnly]: PartAction
}

export type WrappedPartAPI = {
  [PartTypes.ApiCall]: PartAPI
}

export type WrappedPartCATIMessage = {
  [PartTypes.CATIMessage]: PartSMSMessage
}

export type WrappedPartCATIQuestion = {
  [PartTypes.CATIQuestion]: Pick<PartSMSQuestion, 'label' | 'message' | 'onAnswer' | 'openEnded' | 'when'>
}

export type WrappedPartSMSMessage = {
  [PartTypes.SMSMessage]: PartSMSMessage
}

export type WrappedPartSMSQuestion = {
  [PartTypes.SMSQuestion]: PartSMSQuestion
}

export type WrappedPartTopup = {
  [PartTypes.Topup]: PartTopup
}

export type WrappedPartVoiceMessage = {
  callResultActions?: ConditionActions[]
  [PartTypes.VoiceMessage]: PartVoiceMessage
}

export type WrappedPartVoiceQuestion = {
  callResultActions?: ConditionActions[]
  [PartTypes.VoiceQuestion]: PartVoiceQuestion
}

export type WrappedPartWhatsAppMessage = {
  [PartTypes.WhatsAppMessage]: PartWhatsAppMessage
}

export type WrappedPartWhatsAppQuestion = {
  [PartTypes.WhatsAppQuestion]: PartWhatsAppQuestion
}

export type ActionsPart = BasePart & WrappedPartActions

export type APIPart = BasePart & WrappedPartAPI

export type CATIMessagePart = BasePart & WrappedPartCATIMessage

export type CATIQuestionPart = BasePart & WrappedPartCATIQuestion

export type SMSMessagePart = BasePart & WrappedPartSMSMessage

export type SMSQuestionPart = BasePart & WrappedPartSMSQuestion

export type TopupPart = BasePart & WrappedPartTopup

export type VoiceMessagePart = BasePart & WrappedPartVoiceMessage

export type VoiceQuestionPart = BasePart & WrappedPartVoiceQuestion

export type WhatsAppMessagePart = BasePart & WrappedPartWhatsAppMessage

export type WhatsAppQuestionPart = BasePart & WrappedPartWhatsAppQuestion

export type DripPart =
  | ActionsPart
  | APIPart
  | SMSMessagePart
  | SMSQuestionPart
  | TopupPart
  | VoiceMessagePart
  | VoiceQuestionPart
  | WhatsAppMessagePart
  | WhatsAppQuestionPart

export type IVRPart = VoiceMessagePart | VoiceQuestionPart | ActionsPart

export type SMSSurveyPart = SMSMessagePart | SMSQuestionPart | ActionsPart | APIPart

export type WhatsAppSurveyPart = WhatsAppMessagePart | WhatsAppQuestionPart | ActionsPart | APIPart

export type CATIPart = CATIMessagePart | CATIQuestionPart | ActionsPart

export type ReminderPart = z.infer<typeof ZReminderPart>

export type ReminderDefaultArgs = z.infer<typeof ZReminderDefaultArgs>

export type IVRCampaignItem = BaseCampaignItem & {
  type: CampaignTemplateType.VoiceSurvey
  variables: {
    callResultActions: ConditionActions[]
    callerId: string
    callerIds: string[] | null
    defaultSenderId: boolean
    onTransferFailed: Actions
    parts: IVRPart[]
    reconnectActions: ConditionActions[]
    senderId: string
    welcome?: VoiceMessage['audio'] | null
  }
}

export type DripCampaignItem = BaseCampaignItem & {
  type: CampaignTemplateType.Drip
  variables: {
    callerId: string
    callerIds: string[] | null
    defaultSenderId: boolean
    onTransferFailed: Actions
    parts: DripPart[]
    senderId: string
    senderIdQuestions: string
    senderIdReplies: string
    senderIdWhatsapp: string
  }
}

export type SMSBlastCampaignItem = BaseCampaignItem & {
  type: CampaignTemplateType.SMSBlast
  variables: {
    defaultSenderId: boolean
    message: SMSMessage['message']
    senderId: string
  }
}

export type SMSSurveyCampaignItem = BaseCampaignItem & {
  type: CampaignTemplateType.SMSSurvey
  variables: {
    defaultSenderId: boolean
    parts: SMSSurveyPart[]
    senderId: string
    senderIdQuestions: string
    senderIdReplies: string
    welcome?: SMSMessage['message'] | null
  }
}

export type WhatsAppSurveyCampaignItem = BaseCampaignItem & {
  type: CampaignTemplateType.WhatsAppSurvey
  variables: {
    defaultSenderId: boolean
    parts: WhatsAppSurveyPart[]
    senderId: string
    senderIdWhatsapp: string
  }
}

export type CATICampaignItem = BaseCampaignItem & {
  type: CampaignTemplateType.CATI
  variables: {
    defaultSenderId: boolean
    parts: CATIPart[]
    senderId: string
  }
}

export type LegacyMultimessageItem =
  | IVRCampaignItem
  | DripCampaignItem
  | SMSSurveyCampaignItem
  | WhatsAppSurveyCampaignItem
  | CATICampaignItem

export type LegacyCampaignItem = LegacyMultimessageItem | SMSBlastCampaignItem

export type MultimessageItem = LegacyMultimessageItem | ReminderCampaignClass

export type CampaignItem = MultimessageItem | SMSBlastCampaignItem

export type CampaignItemWithVoice = IVRCampaignItem | DripCampaignItem

export type SubscriptionStatus = 'non-subscribed' | 'subscribed' | 'paused' | 'done'

export type SubscriptionStats = {
  activeId: number
  doneCount: number
  errorCount: number
  inProgressCount: number
  pausedCount: number
  totalCount: number
  withFailuresCount: number
}

export type SubscriptionContactType = ContactType & {
  id: number
  subscription?: SubscriptionStats
}

export type CampaignSubscription = {
  subId: number
  contactId: number
  start: string
  inProgress: boolean
  paused: boolean
  done: boolean
  error: boolean
  errorMessage: string
  contactDeleted: boolean
  contactFirstName: string
  contactLastName: string
  contactFullPhoneNumber: string
  contactInvalid: boolean
  contactLanguage: string
  campaignId: number
  campaignName: string
  campaignTemplate: BackendCampaignTemplate
  contactCustomFields: Record<string, string>
  userStatus: string
}

export type TWhatsAppListItem = {
  id: string
  actions: Action[]
  description: string
  label?: string
  title: string
}

export type TWhatsAppList = {
  button: string
  items: TWhatsAppListItem[]
}

export type TWhatsAppButtonItem = {
  id: string
  actions: Action[]
  label?: string
  title: string
}

export type TWhatsAppButtons = {
  buttons: TWhatsAppButtonItem[]
}

export type LocalWhatsAppListItem = Omit<TWhatsAppListItem, 'actions'> & {
  actions: LocalAction[]
}

export type LocalWhatsAppList = {
  button: string
  items: LocalWhatsAppListItem[]
}

export type LocalWhatsAppButtonItem = Omit<TWhatsAppButtonItem, 'actions'> & {
  actions: LocalAction[]
}

export type LocalWhatsAppButtons = {
  buttons: LocalWhatsAppButtonItem[]
}

type GetFileFunction = (fileId: string) => Promise<FileType>

export type ImageProps = {
  id: string
  loading: boolean
  source?: string
  getFile: GetFileFunction
  saveFileHandler: (file: any) => Promise<FileType>
}

export type VoiceProps = {
  id: string
  defaultUpload?: number
  enableTTS: boolean
  files: FilesType
  filterType: string
  loading: boolean
  playId?: string
  voiceList?: BaseLanguageType[]
  voicePreview?: TVoicePreview
  getFile: GetFileFunction
  onMicAccessError: () => void
  onVoicePreviewRequest?: (say: string, voice: string) => void
  saveFileHandler: (file: any) => Promise<FileType>
  setDefaultUpload?: (defaultUpload: number) => void
  setPlaying: (playing: string) => void
  setPreviewLanguage?: (language: string) => void
}

export type TVoicePreview = {
  data: string
  loading: boolean
  query?: {
    activeLanguage: string
    default?: {
      language: string
      say: string
      voice: string
    }
    translations?: Record<
      string,
      {
        language: string
        say: string
        voice: string
      }
    >
  }
}

export type TSendWhatsappAction = {
  sendWhatsapp: ContentWhatsApp & {
    useCampaignSenderId: string
  }
}

export type MessagesState = {
  apiPersonalizationList: PersonalizationType
  error: Error | {}
  loading: boolean
  personalizationList: PersonalizationType
  senderIds: SenderIdOption[]
  senderIdsForDrip: SenderIdsForDripType
  whatsappSenderIds: SenderIdOption[]
}

export type FilesState = {
  data: Record<string, FileType>
  loadingItems: string[]
}

export enum UpdateContactValue {
  Answer = 'answer',
  Constant = 'constant',
  Message = 'message',
  Response = 'response',
}

export type Launch = z.infer<typeof ZLaunch>

export enum SenderIdTypes {
  CallerId = 'callerId',
  CallerIds = 'callerIds',
  SenderId = 'senderId',
  SenderIdQuestions = 'senderIdQuestions',
  SenderIdReplies = 'senderIdReplies',
  SenderIdWhatsapp = 'senderIdWhatsapp',
}
