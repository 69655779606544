// ACTIONS
export const ACTION_GET_DEMOS_REQUEST = '@actions/DEMOS/GET_LIST_REQUEST'
export const ACTION_GET_DEMOS_SUCCESS = '@actions/DEMOS/GET_LIST_SUCCESS'
export const ACTION_GET_DEMOS_FAILURE = '@actions/DEMOS/GET_LIST_FAILURE'

export const ACTION_SELECT_DEMO_ITEM_REQUEST = '@actions/DEMOS/SELECT_ITEM_REQUEST'
export const ACTION_SELECT_DEMO_ITEM_SUCCESS = '@actions/DEMOS/SELECT_ITEM_SUCCESS'
export const ACTION_SELECT_DEMO_ITEM_FAILURE = '@actions/DEMOS/SELECT_ITEM_FAILURE'

export const SELECT_DEMO_SUCCESS = 'Enjoy the demo!'

// ERRORS
export const ERROR_GET_DEMOS_SERVER = 'Failed to load demos. Please try again later.'
export const ERROR_SELECT_DEMO_ITEM_SERVER = "This didn't work. Please be aware that you can only try each demo once."
