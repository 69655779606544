import { equals, intersection, path, pathOr, union } from 'ramda'
import { Fragment, PureComponent } from 'react'
import SelectAllNotification from './notification/all'
import SelectSomeNotification from './notification/some'

export const SELECT_HEADER_ICONS = {
  ALL: 'check_box',
  SOME: 'indeterminate_check_box',
  NONE: 'check_box_outline_blank',
}

const getHeaderIconNotAll = (list, currentView) => {
  const countView = currentView.reduce((acc, itemId) => (list.indexOf(itemId) > -1 ? acc + 1 : acc), 0)

  if (countView === 0) {
    return SELECT_HEADER_ICONS.NONE
  }
  if (countView === currentView.length) {
    return SELECT_HEADER_ICONS.ALL
  }
  return SELECT_HEADER_ICONS.SOME
}

const getHeaderIconAll = (list, total) => {
  if (list.length === 0) {
    return SELECT_HEADER_ICONS.ALL
  }
  if (list.length === total) {
    return SELECT_HEADER_ICONS.NONE
  }
  return SELECT_HEADER_ICONS.SOME
}

export const getHeaderIcon = (isAll, list, currentView, total, groups = [], search = '') => {
  if (isAll || groups.length > 0 || search !== '') {
    return getHeaderIconAll(list, total)
  }
  return getHeaderIconNotAll(list, currentView)
}

const add = (itemsToAdd = [], list = []) => union(list, itemsToAdd)

const remove = (itemsToRemove = [], list = []) => list.filter((itemId) => itemsToRemove.indexOf(itemId) === -1)

export default function createSelectableTable(Wrapped) {
  return class SelectableTable extends PureComponent {
    constructor(props) {
      super(props)

      const all = pathOr(false, ['selectionValues', 'all'], this.props)
      const list = pathOr([], ['selectionValues', 'list'], this.props)
      const groups = pathOr([], ['selectionValues', 'groups'], this.props)
      const search = pathOr([], ['selectionValues', 'search'], this.props)

      this.state = {
        all,
        list,
        count: all ? Math.max(0, props.total - list.length) : list.length,
        headerIcon: getHeaderIcon(all, list, props.ordered, props.total, groups, search),
        notificationAll: false,
        notificationSome: false,
        groups: [],
        search: '',
      }
    }

    componentDidUpdate(prevProps) {
      const { ordered, total, query } = this.props

      const all = path(['selectionValues', 'all'], this.props)
      const list = path(['selectionValues', 'list'], this.props)
      const groups = pathOr([], ['selectionValues', 'groups'], this.props)
      const search = pathOr([], ['selectionValues', 'search'], this.props)

      if (
        all !== path(['selectionValues', 'all'], prevProps) ||
        !equals(list, path(['selectionValues', 'list'], prevProps))
      ) {
        this.setState({
          all,
          list,
          count: all ? Math.max(0, this.props.total - list.length) : list.length,
          headerIcon: getHeaderIcon(all, list, this.props.ordered, this.props.total, groups, search),
        })
        return
      }

      if (JSON.stringify(query) !== JSON.stringify(prevProps.query) || !equals(ordered, prevProps.ordered)) {
        this.setState({
          headerIcon: getHeaderIcon(
            this.state.all,
            this.state.list,
            ordered,
            total,
            this.state.groups,
            this.state.search,
          ),
        })
      }
    }

    headerHandler = () => {
      const { ordered } = this.props

      if (this.state.all) {
        this.setAll(false)
        return
      }

      if (this.state.count > 0 && intersection(this.state.list, ordered).length === ordered.length) {
        this.removeSelection(ordered)
        return
      }

      this.addSelection(ordered)
      this.setState({ notificationSome: true })
    }

    setAll = (all) => {
      const { total } = this.props

      this.setState({
        all,
        search: '',
        list: [],
        count: all ? total : 0,
        notificationAll: all,
        notificationSome: false,
        groups: [],
        headerIcon: all ? SELECT_HEADER_ICONS.ALL : SELECT_HEADER_ICONS.NONE,
      })
    }

    addSelection = (items = []) => {
      const { ordered, total } = this.props
      const newList = add(items, this.state.list)
      this.setState({
        all: false,
        search: '',
        list: newList,
        count: this.state.all ? total - newList.length : newList.length,
        groups: [],
        headerIcon: getHeaderIcon(false, newList, ordered, total, [], this.state.search),
      })
    }

    addGroup = (groups, count) => {
      const { ordered, total } = this.props
      this.setState({
        all: false,
        list: [],
        count,
        notificationAll: false,
        notificationSome: false,
        groups,
        headerIcon: getHeaderIcon(false, [], ordered, total, groups, this.state.search),
      })
    }

    addSearch = (search, count) => {
      const { ordered, total } = this.props
      this.setState({
        all: false,
        search,
        list: [],
        count,
        notificationAll: false,
        notificationSome: false,
        headerIcon: getHeaderIcon(false, [], ordered, total, this.state.groups, search),
      })
    }

    removeSelection = (items = []) => {
      const { ordered, total } = this.props
      let newList =
        this.state.all || this.state.groups > 0 || this.state.search !== ''
          ? add(items, this.state.list)
          : remove(items, this.state.list)
      let newAll = this.state.all
      let newGroups = this.state.groups
      let newSearch = this.state.search
      if (newList.length === total) {
        newAll = false
        newGroups = []
        newSearch = ''
        newList = []
      }
      this.setState({
        all: newAll,
        list: newList,
        count: newAll || newGroups.length > 0 || newSearch !== '' ? total - newList.length : newList.length,
        groups: newGroups,
        search: newSearch,
        headerIcon: getHeaderIcon(newAll, newList, ordered, total, this.state.groups, this.state.search),
      })
    }

    clearSelection = () => {
      const { ordered, total } = this.props
      this.setState({
        all: false,
        list: [],
        count: 0,
        groups: [],
        search: '',
        headerIcon: getHeaderIcon(false, [], ordered, total, [], ''),
      })
    }

    isSelected = (itemId) => {
      const { all, list, groups, search } = this.state
      return all || groups.length > 0 || search !== '' ? list.indexOf(itemId) === -1 : list.indexOf(itemId) > -1
    }

    localSelection = {}

    get selection() {
      const newSelection = {
        isAll: this.state.all,
        list: this.state.list,
        groups: this.state.groups,
        search: this.state.search,
        count: this.state.count,
        headerIcon: this.state.headerIcon,
        headerHandler: this.headerHandler,
        setAll: this.setAll,
        add: this.addSelection,
        remove: this.removeSelection,
        isSelected: this.isSelected,
        clearSelection: this.clearSelection,
        addGroup: this.addGroup,
        addSearch: this.addSearch,
      }
      if (equals(newSelection, this.localSelection)) {
        return this.localSelection
      }
      this.localSelection = newSelection
      return newSelection
    }

    render() {
      const { ordered, total, orgTotal, activeFilter, pageSearchTerm } = this.props
      return (
        <Fragment>
          <SelectSomeNotification
            size={ordered.length}
            total={total}
            isOpen={this.state.notificationSome}
            onClose={() => this.setState({ notificationSome: false })}
            onConfirm={() => this.setAll(true)}
            activeFilter={activeFilter}
            pageSearchTerm={pageSearchTerm}
          />
          <SelectAllNotification
            total={orgTotal}
            isOpen={this.state.notificationAll}
            onClose={() => this.setState({ notificationAll: false })}
          />
          <Wrapped {...this.props} selection={this.selection} />
        </Fragment>
      )
    }
  }
}
