import { assocPath, compose, defaultTo, keys, reduce } from 'ramda'

export const createReducer =
  (defaultState, reducerMap = {}) =>
  (state = defaultState, action = {}) =>
    reducerMap[action.type] ? reducerMap[action.type](state, action) : state

export const createAsyncReducerMap =
  (defaultState = {}, transform = defaultTo) =>
  ({ request, success, failure }) => ({
    [request]: (state = {}) => ({
      ...state,
      loading: true,
      error: defaultState.error,
    }),
    [success]: (state = {}, { value } = {}) => ({
      ...state,
      loading: false,
      data: transform(state.data, value),
      error: defaultState.error,
    }),
    [failure]: (state, { value } = {}) => ({
      ...state,
      loading: false,
      data: defaultState.data,
      error: value,
    }),
  })

export const createAsyncActions = ({ request, success, failure }) => ({
  request: (data) => ({
    type: request,
    value: data,
  }),
  success: (data) => ({
    type: success,
    value: data,
  }),
  failure: (message) => ({
    type: failure,
    value: message,
  }),
})

export const createAsyncState = ({ data = {}, error = {}, ...rest } = {}) => ({
  data,
  error,
  ...rest,
})

export const createNested = (data = []) =>
  compose(
    reduce((acc, key) => assocPath(key.split('.'), data[key], acc), {}),
    keys,
  )(data)
