import { z } from 'zod'
import { createTranslations } from '@/app/module/campaigns/types/schemas/common'
import ZPlayfileContent from '@/app/module/campaigns/types/schemas/message-content/playfile'
import ZTextToSpeechContent from '@/app/module/campaigns/types/schemas/message-content/text-to-speech'
import ZBaseAction from './base'

const ZPlayfile = ZPlayfileContent.extend({
  translations: createTranslations(ZPlayfileContent).optional(),
})

const ZTextToSpeech = ZTextToSpeechContent.extend({
  translations: createTranslations(ZTextToSpeechContent).optional(),
})

const ZPlayAudioAction = ZBaseAction.extend({
  play: z.union([ZPlayfile, ZTextToSpeech]),
})

export default ZPlayAudioAction
