import { pathOr } from 'ramda'
import { createReducer } from '@/app/service/util'
import {
  ACTION_CREATE_API_KEY_FAILURE,
  ACTION_CREATE_API_KEY_REQUEST,
  ACTION_CREATE_API_KEY_SUCCESS,
  ACTION_DELETE_API_KEY_FAILURE,
  ACTION_DELETE_API_KEY_REQUEST,
  ACTION_DELETE_API_KEY_SUCCESS,
  ACTION_DELETE_INVITATION_FAILURE,
  ACTION_DELETE_INVITATION_REQUEST,
  ACTION_DELETE_INVITATION_SUCCESS,
  ACTION_DELETE_USER_FAILURE,
  ACTION_DELETE_USER_REQUEST,
  ACTION_DELETE_USER_SUCCESS,
  ACTION_DISABLE_TOTP_FAILURE,
  ACTION_DISABLE_TOTP_REQUEST,
  ACTION_DISABLE_TOTP_SUCCESS,
  ACTION_DOWNLOAD_AUDIT_LOG_FAILURE,
  ACTION_DOWNLOAD_AUDIT_LOG_REQUEST,
  ACTION_DOWNLOAD_AUDIT_LOG_SUCCESS,
  ACTION_ENABLE_TOTP_FAILURE,
  ACTION_ENABLE_TOTP_REQUEST,
  ACTION_ENABLE_TOTP_SUCCESS,
  ACTION_GET_API_KEYS_FAILURE,
  ACTION_GET_API_KEYS_REQUEST,
  ACTION_GET_API_KEYS_SUCCESS,
  ACTION_GET_INVITATIONS_FAILURE,
  ACTION_GET_INVITATIONS_REQUEST,
  ACTION_GET_INVITATIONS_SUCCESS,
  ACTION_GET_ORGANIZATION_FAILURE,
  ACTION_GET_ORGANIZATION_REQUEST,
  ACTION_GET_ORGANIZATION_SUCCESS,
  ACTION_GET_RECOVERY_CODES_FAILURE,
  ACTION_GET_RECOVERY_CODES_REQUEST,
  ACTION_GET_RECOVERY_CODES_SUCCESS,
  ACTION_GET_SNIPPETS_FAILURE,
  ACTION_GET_SNIPPETS_REQUEST,
  ACTION_GET_SNIPPETS_SUCCESS,
  ACTION_GET_USERS_FAILURE,
  ACTION_GET_USERS_REQUEST,
  ACTION_GET_USERS_SUCCESS,
  ACTION_GET_USER_FAILURE,
  ACTION_GET_USER_REQUEST,
  ACTION_GET_USER_SUCCESS,
  ACTION_INIT_TOTP_FAILURE,
  ACTION_INIT_TOTP_REQUEST,
  ACTION_INIT_TOTP_SUCCESS,
  ACTION_INVITE_USER_FAILURE,
  ACTION_INVITE_USER_REQUEST,
  ACTION_INVITE_USER_SUCCESS,
  ACTION_REMOVE_RECOVERY_CODES,
  ACTION_RESET_RECOVERY_CODES_FAILURE,
  ACTION_RESET_RECOVERY_CODES_REQUEST,
  ACTION_RESET_RECOVERY_CODES_SUCCESS,
  ACTION_SAVE_ORGANIZATION_FAILURE,
  ACTION_SAVE_ORGANIZATION_REQUEST,
  ACTION_SAVE_ORGANIZATION_SUCCESS,
  ACTION_SAVE_PASSWORD_FAILURE,
  ACTION_SAVE_PASSWORD_REQUEST,
  ACTION_SAVE_PASSWORD_SUCCESS,
  ACTION_SAVE_USER_FAILURE,
  ACTION_SAVE_USER_REQUEST,
  ACTION_SAVE_USER_SUCCESS,
  ACTION_SET_SELECTED_ORGANIZATION_ID,
  ACTION_SWITCH_ORGANIZATION_FAILURE,
  ACTION_SWITCH_ORGANIZATION_REQUEST,
  ACTION_SWITCH_ORGANIZATION_SUCCESS,
  ACTION_TOGGLE_CALL_DIALOG,
  ACTION_UPDATE_API_KEY_FAILURE,
  ACTION_UPDATE_API_KEY_REQUEST,
  ACTION_UPDATE_API_KEY_SUCCESS,
  ACTION_UPDATE_USER_FAILURE,
  ACTION_UPDATE_USER_REQUEST,
  ACTION_UPDATE_USER_SUCCESS,
} from '@/app/module/user/definitions'
import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_GET_USERS_REQUEST]: (state) => ({
    ...state,
    users: {
      ...state.users,
      loading: true,
    },
  }),
  [ACTION_GET_USERS_SUCCESS]: (state, { value }) => ({
    ...state,
    users: {
      ...state.users,
      loading: false,
      data: value,
      init: true,
    },
  }),
  [ACTION_GET_USERS_FAILURE]: (state, { value }) => ({
    ...state,
    users: {
      ...state.users,
      loading: false,
      error: value,
      init: true,
    },
  }),
  [ACTION_GET_USER_REQUEST]: (state) => ({
    ...state,
    details: {
      ...state.details,
      loading: true,
      error: defaultState.details.error,
    },
  }),
  [ACTION_GET_USER_SUCCESS]: (state, { value }) => ({
    ...state,
    details: {
      loading: false,
      init: true,
      error: defaultState.details.error,
      data: value,
    },
  }),
  [ACTION_GET_USER_FAILURE]: (state, { value }) => ({
    ...state,
    details: {
      ...state.details,
      loading: false,
      data: {},
      error: value,
    },
  }),
  [ACTION_SAVE_USER_REQUEST]: (state) => ({
    ...state,
    details: {
      ...state.details,
      loading: true,
      error: defaultState.details.error,
    },
  }),
  [ACTION_SAVE_USER_SUCCESS]: (state, { value }) => ({
    ...state,
    details: {
      ...state.details,
      loading: false,
      error: defaultState.details.error,
      data: {
        ...state.details.data,
        ...value,
      },
    },
  }),
  [ACTION_SAVE_USER_FAILURE]: (state, { value }) => ({
    ...state,
    details: {
      ...state.details,
      loading: false,
      error: value,
    },
  }),
  [ACTION_SAVE_PASSWORD_REQUEST]: (state) => ({
    ...state,
    password: {
      loading: true,
    },
  }),
  [ACTION_SAVE_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    password: {
      loading: false,
    },
  }),
  [ACTION_SAVE_PASSWORD_FAILURE]: (state, { value }) => ({
    ...state,
    password: {
      loading: false,
      error: value,
    },
  }),
  [ACTION_GET_ORGANIZATION_REQUEST]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      loading: true,
      error: defaultState.organization.error,
    },
  }),
  [ACTION_GET_ORGANIZATION_SUCCESS]: (state, { value }) => ({
    ...state,
    organization: {
      ...state.organization,
      loading: false,
      init: true,
      error: defaultState.organization.error,
      data: {
        ...state.organization.data,
        [value.id]: {
          ...pathOr({}, ['organization', 'data', value.id], state),
          ...value,
        },
      },
    },
  }),
  [ACTION_GET_ORGANIZATION_FAILURE]: (state, { value }) => ({
    ...state,
    organization: {
      ...state.organization,
      loading: false,
      error: value,
    },
  }),
  [ACTION_SAVE_ORGANIZATION_REQUEST]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      loading: true,
    },
  }),
  [ACTION_SAVE_ORGANIZATION_SUCCESS]: (state, { value = {} }) => ({
    ...state,
    details: {
      ...state.details,
      data: {
        ...state.details.data,
        orgs: pathOr([], ['details', 'data', 'orgs'], state).map(({ name, orgId, balance, balanceHuman }) =>
          orgId !== value.id
            ? { orgId, name, balance, balanceHuman }
            : { orgId, name: value.name, balance, balanceHuman },
        ),
      },
    },
    organization: {
      ...state.organization,
      loading: false,
      error: defaultState.organization.error,
      data: {
        ...state.organization.data,
        [value.id]: {
          ...pathOr({}, ['organization', 'data', value.id], state),
          ...value,
        },
      },
    },
  }),
  [ACTION_SAVE_ORGANIZATION_FAILURE]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      loading: false,
    },
  }),
  [ACTION_SWITCH_ORGANIZATION_REQUEST]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      loading: true,
    },
  }),
  [ACTION_SWITCH_ORGANIZATION_SUCCESS]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
    },
  }),
  [ACTION_SWITCH_ORGANIZATION_FAILURE]: (state) => ({
    ...state,
    organization: {
      ...state.organization,
      loading: false,
    },
  }),
  [ACTION_SET_SELECTED_ORGANIZATION_ID]: (state, { value }) => ({
    ...state,
    organization: {
      ...state.organization,
      selectedId: value.id,
    },
  }),
  [ACTION_DOWNLOAD_AUDIT_LOG_REQUEST]: (state) => ({
    ...state,
    auditLog: {
      loading: true,
    },
  }),
  [ACTION_DOWNLOAD_AUDIT_LOG_SUCCESS]: (state) => ({
    ...state,
    auditLog: {
      loading: false,
    },
  }),
  [ACTION_DOWNLOAD_AUDIT_LOG_FAILURE]: (state, { value }) => ({
    ...state,
    auditLog: {
      loading: false,
      error: value,
    },
  }),
  [ACTION_INVITE_USER_REQUEST]: (state) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: true,
    },
  }),
  [ACTION_INVITE_USER_SUCCESS]: (state) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: false,
    },
  }),
  [ACTION_INVITE_USER_FAILURE]: (state, { value }) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: false,
      error: value,
    },
  }),
  [ACTION_INIT_TOTP_REQUEST]: (state) => ({
    ...state,
    totp: {
      ...state.totp,
      loading: true,
    },
  }),
  [ACTION_INIT_TOTP_SUCCESS]: (state, { value }) => ({
    ...state,
    totp: {
      ...state.totp,
      loading: false,
      data: value,
    },
  }),
  [ACTION_INIT_TOTP_FAILURE]: (state, { value }) => ({
    ...state,
    totp: {
      ...state.totp,
      loading: false,
      error: value,
    },
  }),
  [ACTION_GET_INVITATIONS_REQUEST]: (state) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: true,
    },
  }),
  [ACTION_GET_INVITATIONS_SUCCESS]: (state, { value }) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: false,
      data: value,
      init: true,
    },
  }),
  [ACTION_GET_INVITATIONS_FAILURE]: (state, { value }) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: false,
      error: value,
      init: true,
    },
  }),
  [ACTION_DELETE_INVITATION_REQUEST]: (state) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: true,
    },
  }),
  [ACTION_DELETE_INVITATION_SUCCESS]: (state) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: false,
    },
  }),
  [ACTION_DELETE_INVITATION_FAILURE]: (state, { value }) => ({
    ...state,
    invitations: {
      ...state.invitations,
      loading: false,
      error: value,
    },
  }),
  [ACTION_ENABLE_TOTP_REQUEST]: (state) => ({
    ...state,
    totp: {
      ...state.totp,
      loading: true,
    },
  }),
  [ACTION_ENABLE_TOTP_SUCCESS]: (state, { value }) => ({
    ...state,
    totp: {
      ...state.totp,
      loading: false,
    },
    recoveryCodes: {
      ...state.recoveryCodes,
      data: value,
    },
  }),
  [ACTION_ENABLE_TOTP_FAILURE]: (state, { value }) => ({
    ...state,
    totp: {
      ...state.totp,
      loading: false,
      error: value,
    },
  }),
  [ACTION_DISABLE_TOTP_REQUEST]: (state) => ({
    ...state,
    totp: {
      ...state.totp,
      loading: true,
    },
  }),
  [ACTION_DISABLE_TOTP_SUCCESS]: (state) => ({
    ...state,
    totp: {
      ...state.totp,
      loading: false,
    },
  }),
  [ACTION_DISABLE_TOTP_FAILURE]: (state, { value }) => ({
    ...state,
    totp: {
      ...state.totp,
      loading: false,
      error: value,
    },
  }),
  [ACTION_GET_RECOVERY_CODES_REQUEST]: (state) => ({
    ...state,
    recoveryCodes: {
      ...state.recoveryCodes,
      loading: true,
    },
  }),
  [ACTION_GET_RECOVERY_CODES_SUCCESS]: (state, { value }) => ({
    ...state,
    recoveryCodes: {
      ...state.recoveryCodes,
      loading: false,
      data: value,
    },
  }),
  [ACTION_GET_RECOVERY_CODES_FAILURE]: (state, { value }) => ({
    ...state,
    recoveryCodes: {
      ...state.recoveryCodes,
      loading: false,
      error: value,
    },
  }),
  [ACTION_RESET_RECOVERY_CODES_REQUEST]: (state) => ({
    ...state,
    recoveryCodes: {
      ...state.recoveryCodes,
      loading: true,
    },
  }),
  [ACTION_RESET_RECOVERY_CODES_SUCCESS]: (state, { value }) => ({
    ...state,
    recoveryCodes: {
      ...state.recoveryCodes,
      loading: false,
      data: value,
    },
  }),
  [ACTION_RESET_RECOVERY_CODES_FAILURE]: (state, { value }) => ({
    ...state,
    recoveryCodes: {
      ...state.recoveryCodes,
      loading: false,
      error: value,
    },
  }),
  [ACTION_REMOVE_RECOVERY_CODES]: (state) => ({
    ...state,
    recoveryCodes: {
      data: defaultState.recoveryCodes.data,
    },
  }),
  [ACTION_GET_SNIPPETS_REQUEST]: (state) => ({
    ...state,
    snippets: {
      ...state.snippets,
      loading: true,
      error: defaultState.details.error,
    },
  }),
  [ACTION_GET_SNIPPETS_SUCCESS]: (state, { value }) => ({
    ...state,
    snippets: {
      loading: false,
      error: defaultState.details.error,
      data: value,
    },
  }),
  [ACTION_GET_SNIPPETS_FAILURE]: (state, { value }) => ({
    ...state,
    details: {
      ...state.snippets,
      loading: false,
      data: {},
      error: value,
    },
  }),
  [ACTION_TOGGLE_CALL_DIALOG]: (state, { value }) => ({
    ...state,
    callDialog: {
      ...state.callDialog,
      ...value,
    },
  }),
  [ACTION_DELETE_USER_REQUEST]: (state) => ({
    ...state,
    users: {
      ...state.users,
      loading: true,
      error: defaultState.users.error,
    },
  }),
  [ACTION_DELETE_USER_SUCCESS]: (state, { value }) => ({
    ...state,
    users: {
      ...state.users,
      loading: false,
      error: defaultState.users.error,
      data: state.users.data.filter((user) => user.id !== value),
    },
  }),
  [ACTION_DELETE_USER_FAILURE]: (state, { value }) => ({
    ...state,
    users: {
      ...state.users,
      loading: false,
      error: value,
    },
  }),
  [ACTION_UPDATE_USER_REQUEST]: (state) => ({
    ...state,
    users: {
      ...state.users,
      loading: true,
      error: defaultState.users.error,
    },
  }),
  [ACTION_UPDATE_USER_SUCCESS]: (state, { value }) => ({
    ...state,
    users: {
      ...state.users,
      loading: false,
      error: defaultState.users.error,
      data: state.users.data.map((user) => (user.id === value.id ? value : user)),
    },
  }),
  [ACTION_UPDATE_USER_FAILURE]: (state, { value }) => ({
    ...state,
    users: {
      ...state.users,
      loading: false,
      error: value,
    },
  }),
  [ACTION_GET_API_KEYS_REQUEST]: (state) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: true,
    },
  }),
  [ACTION_GET_API_KEYS_SUCCESS]: (state, { value }) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: false,
      data: value,
    },
  }),
  [ACTION_GET_API_KEYS_FAILURE]: (state, { value }) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: false,
      error: value,
    },
  }),
  [ACTION_CREATE_API_KEY_REQUEST]: (state) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: true,
    },
  }),
  [ACTION_CREATE_API_KEY_SUCCESS]: (state, { value }) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: false,
      data: {
        ...state.apiKeys.data,
        tokens: [...state.apiKeys.data.tokens, value],
        total: state.apiKeys.data.total + 1,
      },
    },
  }),
  [ACTION_CREATE_API_KEY_FAILURE]: (state, { value }) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: false,
      error: value,
    },
  }),
  [ACTION_UPDATE_API_KEY_REQUEST]: (state) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: true,
    },
  }),
  [ACTION_UPDATE_API_KEY_SUCCESS]: (state, { value }) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: false,
      data: {
        ...state.apiKeys.data,
        tokens: state.apiKeys.data.tokens.map((token) => (token.id === value.id ? value : token)),
      },
    },
  }),
  [ACTION_UPDATE_API_KEY_FAILURE]: (state, { value }) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: false,
      error: value,
    },
  }),
  [ACTION_DELETE_API_KEY_REQUEST]: (state) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: true,
    },
  }),
  [ACTION_DELETE_API_KEY_SUCCESS]: (state, { value }) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: false,
      data: {
        ...state.apiKeys.data,
        tokens: state.apiKeys.data.tokens.filter((token) => token.id !== value),
      },
    },
  }),
  [ACTION_DELETE_API_KEY_FAILURE]: (state, { value }) => ({
    ...state,
    apiKeys: {
      ...state.apiKeys,
      loading: false,
      error: value,
    },
  }),
})
