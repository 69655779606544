// PLAN ACTIONS
export const ACTION_GET_PLAN_REQUEST = '@actions/PLAN/GET_ITEM_REQUEST'
export const ACTION_GET_PLAN_SUCCESS = '@actions/PLAN/GET_ITEM_SUCCESS'
export const ACTION_GET_PLAN_FAILURE = '@actions/PLAN/GET_ITEM_FAILURE'

// PLANS ACTIONS
export const ACTION_GET_PLANS_REQUEST = '@actions/PLANS/GET_LIST_REQUEST'
export const ACTION_GET_PLANS_SUCCESS = '@actions/PLANS/GET_LIST_SUCCESS'
export const ACTION_GET_PLANS_FAILURE = '@actions/PLANS/GET_LIST_FAILURE'

// SUBSCRIBE PLAN ACTIONS
export const ACTION_SUBSCRIBE_PLAN_REQUEST = '@actions/PLAN/SUBSCRIBE_LIST_REQUEST'
export const ACTION_SUBSCRIBE_PLAN_SUCCESS = '@actions/PLAN/SUBSCRIBE_LIST_SUCCESS'
export const ACTION_SUBSCRIBE_PLAN_FAILURE = '@actions/PLAN/SUBSCRIBE_LIST_FAILURE'

// DELETE PLAN ACTIONS
export const ACTION_DELETE_PLAN_REQUEST = '@actions/PLAN/DELETE_LIST_REQUEST'
export const ACTION_DELETE_PLAN_SUCCESS = '@actions/PLAN/DELETE_LIST_SUCCESS'
export const ACTION_DELETE_PLAN_FAILURE = '@actions/PLAN/DELETE_LIST_FAILURE'

// ERRORS
export const ERROR_GET_PLAN_SERVER = 'Failed to load plan. Please try again later.'
export const ERROR_GET_PLANS_SERVER = 'Failed to load plans. Please try again later.'
export const ERROR_SUBSCRIBE_PLAN_SERVER = 'Failed to subscribe to plan. Please try again later.'
export const ERROR_DELETE_PLAN_SERVER = 'Failed to cancel plan. Please try again later.'
