import { append, equals, last } from 'ramda'
import {
  LocalAction,
  LocalActionType,
  LocalEmptyAction,
  LocalNonEmptyAction,
} from '@/app/module/campaigns/types/actions'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'
import { cannot } from '@/app/helpers'
import { isNonEmptyAction } from './helpers'

export const emptyAction: LocalEmptyAction = {
  type: '',
} as const

export const wrapAction = (action: LocalNonEmptyAction): Action => {
  switch (action.type) {
    case LocalActionType.API:
      return {
        id: action.id,
        makeAPIRequest: action.makeAPIRequest,
      }
    case LocalActionType.FinishFlow:
      return {
        id: action.id,
        finishFlow: action.finishFlow,
      }
    case LocalActionType.Hangup:
      return {
        id: action.id,
        hangup: action.hangup,
      }
    case LocalActionType.Jump:
      return {
        id: action.id,
        jump: action.jump,
      }
    case LocalActionType.PauseSubscription:
    case LocalActionType.ResumeSubscription:
      return {
        id: action.id,
        setPaused: action.setPaused,
      }
    case LocalActionType.PlayAudio:
      return {
        id: action.id,
        play: action.play,
      }
    case LocalActionType.Replay:
      return {
        id: action.id,
        replayQuestion: action.replayQuestion,
      }
    case LocalActionType.Resend:
      return {
        id: action.id,
        resendQuestion: action.resendQuestion,
      }
    case LocalActionType.SendEmail:
      return {
        id: action.id,
        sendEmail: action.sendEmail,
      }
    case LocalActionType.SendSMS:
      return {
        id: action.id,
        sendSMS: action.sendSMS,
      }
    case LocalActionType.SendSMSToPhoneNumbers:
      return {
        id: action.id,
        sendSMSToPhoneNumbers: action.sendSMSToPhoneNumbers,
      }
    case LocalActionType.SendWhatsApp:
      return {
        id: action.id,
        sendWhatsapp: action.sendWhatsapp,
      }
    case LocalActionType.SubscribeToCampaign:
      return {
        id: action.id,
        subscribeToCampaign: action.subscribeToCampaign,
      }
    case LocalActionType.Topup:
      return {
        id: action.id,
        topup: action.topup,
      }
    case LocalActionType.Transfer:
      return {
        id: action.id,
        transfer: action.transfer,
      }
    case LocalActionType.Unsubscribe:
      return {
        id: action.id,
        unsubscribe: action.unsubscribe,
      }
    case LocalActionType.UnsubscribeAll:
      return {
        id: action.id,
        unsubscribeAll: action.unsubscribeAll,
      }
    case LocalActionType.UpdateContact:
      return {
        id: action.id,
        updateContact: action.updateContact,
      }

    default:
      return cannot(action)
  }
}

/**
 * Convert an array of local action types to action DTOs
 */
export const wrapActions = (actions: LocalAction[]) => actions.filter(isNonEmptyAction).map(wrapAction)

export const unwrapAction = (action: Action): LocalNonEmptyAction => {
  if ('makeAPIRequest' in action) {
    return {
      type: LocalActionType.API,
      id: action.id,
      makeAPIRequest: action.makeAPIRequest,
    }
  }
  if ('finishFlow' in action) {
    return {
      type: LocalActionType.FinishFlow,
      id: action.id,
      finishFlow: action.finishFlow,
    }
  }
  if ('hangup' in action) {
    return {
      type: LocalActionType.Hangup,
      id: action.id,
      hangup: action.hangup,
    }
  }
  if ('jump' in action) {
    return {
      type: LocalActionType.Jump,
      id: action.id,
      jump: action.jump,
    }
  }
  if ('setPaused' in action) {
    return {
      type: action.setPaused.paused ? LocalActionType.PauseSubscription : LocalActionType.ResumeSubscription,
      id: action.id,
      setPaused: action.setPaused,
    }
  }
  if ('play' in action) {
    return {
      type: LocalActionType.PlayAudio,
      id: action.id,
      play: action.play,
    }
  }
  if ('replayQuestion' in action) {
    return {
      type: LocalActionType.Replay,
      id: action.id,
      replayQuestion: action.replayQuestion,
    }
  }
  if ('resendQuestion' in action) {
    return {
      type: LocalActionType.Resend,
      id: action.id,
      resendQuestion: action.resendQuestion,
    }
  }
  if ('sendEmail' in action) {
    return {
      type: LocalActionType.SendEmail,
      id: action.id,
      sendEmail: action.sendEmail,
    }
  }
  if ('sendSMS' in action) {
    return {
      type: LocalActionType.SendSMS,
      id: action.id,
      sendSMS: action.sendSMS,
    }
  }
  if ('sendSMSToPhoneNumbers' in action) {
    return {
      type: LocalActionType.SendSMSToPhoneNumbers,
      id: action.id,
      sendSMSToPhoneNumbers: action.sendSMSToPhoneNumbers,
    }
  }
  if ('sendWhatsapp' in action) {
    return {
      type: LocalActionType.SendWhatsApp,
      id: action.id,
      sendWhatsapp: action.sendWhatsapp,
    }
  }
  if ('subscribeToCampaign' in action) {
    return {
      type: LocalActionType.SubscribeToCampaign,
      id: action.id,
      subscribeToCampaign: action.subscribeToCampaign,
    }
  }
  if ('topup' in action) {
    return {
      type: LocalActionType.Topup,
      id: action.id,
      topup: action.topup,
    }
  }
  if ('transfer' in action) {
    return {
      type: LocalActionType.Transfer,
      id: action.id,
      transfer: action.transfer,
    }
  }
  if ('unsubscribe' in action) {
    return {
      type: LocalActionType.Unsubscribe,
      id: action.id,
      unsubscribe: action.unsubscribe,
    }
  }
  if ('unsubscribeAll' in action) {
    return {
      type: LocalActionType.UnsubscribeAll,
      id: action.id,
      unsubscribeAll: action.unsubscribeAll,
    }
  }
  if ('updateContact' in action) {
    return {
      type: LocalActionType.UpdateContact,
      id: action.id,
      updateContact: action.updateContact,
    }
  }

  return cannot(action)
}

/**
 * Convert an array of action DTOs to local action types
 */
export const unwrapActions = (actions: Action[]) => appendEmpty(actions.map(unwrapAction))

export const appendEmpty = (actions: LocalAction[]) =>
  equals(emptyAction, last(actions)) ? actions : append(emptyAction, actions)

export const removeEmpty = (actions: LocalAction[]) => {
  if (equals(emptyAction, last(actions))) {
    return actions.slice(0, -1)
  }
  return actions
}
