import CardLayout from '../component/layout/card'
import ErrorLayout from '../component/layout/error'
import Layout from '../component/layout/page'
import Header from '../component/section/header'

export default function ErrorPage({ isAuthorized = false }) {
  return (
    <Layout title="Error - engageSPARK">
      <Header isAuthorized={isAuthorized} />
      <CardLayout>
        <ErrorLayout status={500}>A server error occured</ErrorLayout>
      </CardLayout>
    </Layout>
  )
}
