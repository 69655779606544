export default {
  login: {
    loading: false,
    error: {},
    data: {},
  },
  signup: {
    loading: false,
    error: {},
    data: {},
  },
  signupEmail: {
    loading: false,
    error: {},
    data: {},
  },
  resetPassword: {
    loading: false,
    error: {},
    data: {},
  },
  requestResetPassword: {
    loading: false,
    error: {},
    data: {},
  },
  emailCheck: {
    loading: false,
    error: {},
  },
  trialMode: false,
  otp: {
    loading: false,
    error: {},
    data: {},
  },
  recoveryCode: {
    loading: false,
    error: {},
  },
  invitation: {
    loading: false,
  },
  call: {
    item: undefined,
    state: 'IDLE',
  },
}
