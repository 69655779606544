import { has, isEmpty, keys, pathOr, trim } from 'ramda'
import { v1 as uuid } from 'uuid'
import { cannot } from '@/app/helpers'
import { TYPES, getMessageLabel } from '@/app/module/campaigns/definitions'
import { emptyAction } from '@/app/module/campaigns/utils/actions/wrap'
import {
  LegacyCampaignItem,
  ConditionActions,
  ContentAudio,
  ContentSMS,
  LocalWhatsAppButtons,
  LocalWhatsAppList,
  Part,
  PartTypes,
  Translation,
} from '@/app/module/campaigns/types'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'
import { z } from 'zod'
import { getPartContentPreview } from '@/app/module/campaigns/utils/part-content'

export const defaultOnAnswerText = [
  {
    replies: ['yes'],
    reply: 'yes',
    ranges: [],
    actions: [emptyAction],
    label: '',
  },
  {
    replies: ['no'],
    reply: 'no',
    ranges: [],
    actions: [emptyAction],
    label: '',
  },
]

export const defaultOnAnswerTextNoActions = defaultOnAnswerText.map((item) => ({
  ...item,
  actions: [],
}))

export const defaultOnAnswerVoice = [
  {
    replies: ['1'],
    ranges: [],
    actions: [emptyAction],
    label: '',
  },
  {
    replies: ['2'],
    ranges: [],
    actions: [emptyAction],
    label: '',
  },
]

export const defaultOnAnswerVoiceNoActions = defaultOnAnswerVoice.map((item) => ({
  ...item,
  actions: [],
}))

export const generateDefaultOnButtons = (noAction?: boolean): LocalWhatsAppButtons => ({
  buttons: [
    {
      id: uuid(),
      actions: noAction ? [] : [emptyAction],
      label: '',
      title: 'yes',
    },
    {
      id: uuid(),
      actions: noAction ? [] : [emptyAction],
      label: '',
      title: 'no',
    },
  ],
})

export const generateDefaultOnList = (noAction?: boolean): LocalWhatsAppList => ({
  button: '',
  items: [
    {
      id: uuid(),
      actions: noAction ? [] : [emptyAction],
      description: '',
      label: '',
      title: 'yes',
    },
    {
      id: uuid(),
      actions: noAction ? [] : [emptyAction],
      description: '',
      label: '',
      title: 'no',
    },
  ],
})

export const selectCampaignTypeContent = (props: { item: LegacyCampaignItem }) =>
  pathOr({}, [pathOr('drip', ['item', 'type'], props).toUpperCase()], TYPES)

export const trimContent = (content?: string, fallback = '') => (content ? trim(content) : fallback)

export const concatAll = <T>(arr: Array<T[] | undefined>): T[] => arr.flatMap((a) => a || [])

export const findJumpsSource = (id: string, parts: Part[]): string[] =>
  parts
    .reduce((partAccum: PartWithIndex[], part, idx): PartWithIndex[] => {
      if (part.id === id) {
        // skip the current part because it cannot jump to itself
        // and because it might be a broken part
        return partAccum
      }

      let accum = partAccum
      if (getActions(part).find((a) => has('jump', a) && a.jump.nextPart === id)) {
        accum = [...partAccum, { part, idx }]
      }
      return accum
    }, [] as PartWithIndex[])
    .map(getJumpLabel)

type PartWithIndex = {
  part: Part
  idx: number
}

const getJumpLabel = (p: PartWithIndex): string =>
  `${p.idx + 1}. ${getPartLabel(p.part)} ${getPartContentPreview(p.part)}`

export const getPartType = (part: Part): PartTypes => {
  if (has(PartTypes.ActionsOnly, part)) {
    return PartTypes.ActionsOnly
  }
  if (has(PartTypes.ApiCall, part)) {
    return PartTypes.ApiCall
  }
  if (has(PartTypes.CATIMessage, part)) {
    return PartTypes.CATIMessage
  }
  if (has(PartTypes.CATIQuestion, part)) {
    return PartTypes.CATIQuestion
  }
  if (has(PartTypes.SMSMessage, part)) {
    return PartTypes.SMSMessage
  }
  if (has(PartTypes.SMSQuestion, part)) {
    return PartTypes.SMSQuestion
  }
  if (has(PartTypes.Topup, part)) {
    return PartTypes.Topup
  }
  if (has(PartTypes.VoiceMessage, part)) {
    return PartTypes.VoiceMessage
  }
  if (has(PartTypes.VoiceQuestion, part)) {
    return PartTypes.VoiceQuestion
  }
  if (has(PartTypes.WhatsAppMessage, part)) {
    return PartTypes.WhatsAppMessage
  }
  if (has(PartTypes.WhatsAppQuestion, part)) {
    return PartTypes.WhatsAppQuestion
  }
  return cannot(part)
}

const getPartLabel = (part: Part): string => getMessageLabel(getPartType(part))

export const getActionsFromConditionActions = (actions: ConditionActions[]): Action[] => {
  return actions.flatMap((a) => a.actions || [])
}

export const getActions = (part: Part): Action[] => {
  if (has(PartTypes.ActionsOnly, part)) {
    return part.actionsOnly.actions
  }
  if (has(PartTypes.ApiCall, part)) {
    return concatAll([part.apiCall.onSuccess, part.apiCall.onError])
  }
  if (has(PartTypes.CATIMessage, part)) {
    return []
  }
  if (has(PartTypes.CATIQuestion, part)) {
    const msg = part.catiQuestion

    return concatAll([nestedActions(msg.onAnswer), msg.openEnded?.actions])
  }
  if (has(PartTypes.SMSMessage, part)) {
    return []
  }
  if (has(PartTypes.SMSQuestion, part)) {
    const msg = part.smsQuestion

    return concatAll([
      nestedActions(msg.onAnswer),
      msg.onInvalidReply?.actions,
      msg.onRetriesExhausted?.actions,
      msg.onTimeout?.actions,
      msg.openEnded?.actions,
      nestedActions(msg.openEnded?.categories),
    ])
  }
  if (has(PartTypes.Topup, part)) {
    return concatAll([part.topup.onSuccess, part.topup.onError])
  }
  if (has(PartTypes.VoiceMessage, part)) {
    return nestedActions(part.callResultActions)
  }
  if (has(PartTypes.VoiceQuestion, part)) {
    const msg = part.voiceQuestion

    return concatAll([
      nestedActions(part.callResultActions),
      nestedActions(msg.onAnswer),
      msg.onInvalidReply?.actions,
      msg.onRetriesExhausted?.actions,
      msg.onTimeout?.actions,
      msg.spoken?.actions,
    ])
  }
  if (has(PartTypes.WhatsAppMessage, part)) {
    return []
  }
  if (has(PartTypes.WhatsAppQuestion, part)) {
    const msg = part.whatsappQuestion

    return concatAll([
      nestedActions(msg.onAnswer),
      nestedActions(msg.onButtons?.buttons),
      nestedActions(msg.onList?.items),
      msg.onInvalidReply?.actions,
      msg.onRetriesExhausted?.actions,
      msg.onTimeout?.actions,
      msg.openEnded?.actions,
      nestedActions(msg.openEnded?.categories),
    ])
  }
  return cannot(part)
}

type WithActionsMaybe = {
  actions?: Action[] | null
}

export const nestedActions = (l?: WithActionsMaybe[] | null): Action[] => {
  if (!l) {
    return []
  }
  return l.flatMap((a) => a.actions || [])
}

export const hasAudioContent = (content: unknown) =>
  has('playfile', content) || has('say', content) || has('voice', content)

export const hasTextContent = (content: unknown) => has('text', content)

const translationSchema = z.object({
  document: z.string().optional(),
  image: z.string().optional(),
  playfile: z.string().optional(),
  text: z.string().optional(),
  say: z.string().optional(),
  voice: z.string().optional(),
})

export const isTranslation = (t: unknown): t is Translation => {
  if (!t || isEmpty(t)) {
    return false
  }

  try {
    translationSchema.strict().parse(t)
    return true
  } catch {
    return false
  }
}

export const isTranslations = (t: unknown): t is Record<string, Translation> => {
  if (!t || isEmpty(t)) {
    return false
  }

  try {
    // check if t is an object
    z.object({}).parse(t)
    // if t is an object, check if it has translations
    return !keys(t).some((k) => !isTranslation(t[k]))
  } catch {
    return false
  }
}

export const hasTranslations = (content: unknown): content is { translations: Record<string, Translation> } => {
  if (!has('translations', content)) {
    return false
  }

  return isTranslations(content.translations) && Object.values(content.translations).some((t) => isTranslation(t))
}

export const isContentAudio = (content: unknown): content is ContentAudio => {
  const hasContent = hasAudioContent(content)
  if (!hasTranslations(content)) {
    return hasContent
  }

  return hasContent || Object.values(content.translations).some(hasAudioContent)
}

export const isContentText = (content: unknown): content is ContentSMS => {
  const hasContent = hasTextContent(content)
  if (!hasTranslations(content)) {
    return hasContent
  }

  return hasContent || Object.values(content.translations).some(hasTextContent)
}
