export const ACTION_GET_CATI_REQUEST = '@actions/CATI/GET_CATI_REQUEST'
export const ACTION_GET_CATI_SUCCESS = '@actions/CATI/GET_CATI_SUCCESS'
export const ACTION_GET_CATI_FAILURE = '@actions/CATI/GET_CATI_FAILURE'

export const ACTION_GET_CATI_STEP_REQUEST = '@actions/CATI/GET_CATI_STEP_REQUEST'
export const ACTION_GET_CATI_STEP_SUCCESS = '@actions/CATI/GET_CATI_STEP_SUCCESS'
export const ACTION_GET_CATI_STEP_FAILURE = '@actions/CATI/GET_CATI_STEP_FAILURE'

export const ACTION_GET_ORG_SUBS_REQUEST = '@actions/CATI/GET_ORG_SUBS_REQUEST'
export const ACTION_GET_ORG_SUBS_SUCCESS = '@actions/CATI/GET_ORG_SUBS_SUCCESS'
export const ACTION_GET_ORG_SUBS_FAILURE = '@actions/CATI/GET_ORG_SUBS_FAILURE'

export const ACTION_GET_SUB_DETAILS_REQUEST = '@actions/CATI/GET_SUB_DETAILS_REQUEST'
export const ACTION_GET_SUB_DETAILS_SUCCESS = '@actions/CATI/GET_SUB_DETAILS_SUCCESS'
export const ACTION_GET_SUB_DETAILS_FAILURE = '@actions/CATI/GET_SUB_DETAILS_FAILURE'

export const ACTION_CLOSE_CATI_SURVEY_REQUEST = '@actions/CATI/CLOSE_CATI_SURVEY_REQUEST'
export const ACTION_CLOSE_CATI_SURVEY_SUCCESS = '@actions/CATI/CLOSE_CATI_SURVEY_SUCCESS'
export const ACTION_CLOSE_CATI_SURVEY_FAILURE = '@actions/CATI/CLOSE_CATI_SURVEY_FAILURE'

export const ACTION_SET_CATI_STATUS_REQUEST = '@actions/CATI/SET_CATI_STATUS_REQUEST'
export const ACTION_SET_CATI_STATUS_SUCCESS = '@actions/CATI/SET_CATI_STATUS_SUCCESS'
export const ACTION_SET_CATI_STATUS_FAILURE = '@actions/CATI/SET_CATI_STATUS_FAILURE'

export const ACTION_ORG_SUBS_PAGE_SIZE = '@actions/CATI/ORG_SUBS_PAGE_SIZE'
export const ACTION_ORG_SUBS_NAVIGATE = '@actions/CATI/ORG_SUBS_NAVIGATE'

export const ERROR_GET_CATI = 'Failed to get CATI Survey'
export const ERROR_GET_ORG_SUBS = 'Failed to get CATI subscriptions'
export const ERROR_GET_SUB_DETAILS = 'Failed to get CATI subscription details'
export const ERROR_SET_CATI_STATUS = 'Failed to update CATI status'
export const ERROR_CLOSE_CATI_SURVEY = 'Failed to close CATI survey'

export const CATI_USER_ROLE = 'cati_agent'
