import { pathOr } from 'ramda'
import config from '@/config'
import { getPageSize } from '@/app/service/storage'

const pageSize = getPageSize({
  namespace: 'cati',
  defaultValue: pathOr(25, ['modules', 'contacts', 'pageSize', 'default'], config),
})

export default {
  item: {
    data: {},
    loading: false,
    error: {},
  },
  subs: {
    data: {
      values: [],
      searches: {
        active: '',
        data: {},
      },
      page: 1,
      size: pageSize,
      total: 0,
    },
    loading: false,
    error: {},
    initialized: false,
  },
}
