import { findIndex, propEq } from 'ramda'
import { capitalizeFirstLetter } from '@/app/module/campaigns/helpers'

type PartType = {
  id: string
}

type CampaignItemType = {
  variables: {
    parts: PartType[]
  }
}

type ErrorType = {
  response: {
    errorCode: string
    error: string
    errorContext?: {
      partId: string
    }
  }
}

export const errorCodes: { [key: string]: string } = {
  'totp.invalidpasscode': "You've entered an incorrect code. Please try again.",
  'totp.alreadyverified': 'Session is already verified. Please continue to the dashboard.',
  'totp.notenabled': 'Two-factor authentication is not enabled for this account. Please enable it to use this feature.',
  'totp.passcodereused': 'Two-factor authentication code has already been used. Please use a new one and try again.',
  'totp.alreadyenabled':
    'Two-factor authentication is already enabled. Please disable it first to enable it on a new device.',
  'invite.invalidtoken':
    'This invitation link has expired or has been revoked. Please ask the admins of this organization for a new invitation.',
  'invite.invalidorg': 'This organization is suspended. Please contact customer support.',
  'invite.invalidemail': 'This user cannot be invited. Please contact customer support.',
  'invite.nopermission':
    'You are not allowed to invite users. You must be the owner or a manager of the organization to do this.',
  'invite.wronguser':
    'You are currently logged in with a different account than the invited user. Please log in with the correct account and try again.',
  'invite.used': 'This invitation has already been accepted.',
  'invite.loggedin': 'You are already signed in. Please log out and try again.',
  'invite.member': 'You are already a member of this organization.',
  'invite.invalidaddress': 'Invalid email address. Please enter a correct email address and try again.',
  'campaign.subnotfound': 'This survey is not available',
  'campaign.notfound': 'This survey is not available',
  'user.needrolepermission': 'Your account does not have permission for this action in this organization.',
  'template.emptymessage': 'The campaign has empty messages. Please add text or remove them.',
  'fundstransfer.negativeamount': 'The transfer amount must be greater than 0.',
  'fundstransfer.fromorgnotenoughfunds': 'Source organization does not have enough funds to make this transfer.',
  'fundstransfer.fromorgnotexist': 'Source organization does not exist.',
  'fundstransfer.toorgnotexist': 'Destination organization does not exist.',
  'fundstransfer.orgnotactive': 'Both organizations must be active to transfer/receive funds.',
  'fundstransfer.fromorgrolepermission': 'You do not have permission to transfer funds from this organization.',
  'fundstransfer.toorgrolepermission': 'You do not have permission to receive funds to this organization.',
  'fundstransfer.orgusersuspended': 'You are suspended from the organization.',
  'fundstransfer.samefromtoorg': 'Source and destination organizations cannot be the same.',
  'user.owner': 'You cannot remove the owner of the organization.',
  'user.yourself': 'You cannot remove yourself from the organization.',
  'cc.already': 'Only one credit card can be active at the same time.',
  'cc.declined': 'The payment was declined. Please contact customer support.',
  'cc.expired': 'Your credit card has expired. Please update your credit card information.',
  'cc.invalidcvc': 'The CVC you entered is invalid.',
  'cc.invalidexpiry': 'The expiration date is invalid.',
  'cc.invalidnumber': 'The credit card number you entered is invalid. Maybe a typo?',
  'cc.nofunds': 'Your credit card has insufficient funds. Please add funds and try again.',
  'cc.notfound': 'No credit card found.',
  'cc.postalcode': 'The zip or postal code you entered is invalid.',
  'charge.openreview':
    'A previous charge is still under review and must be resolved before the next charge attempt. Please contact customer support.',
  'charge.requiresreview': 'This charge attempt requires a manual review from us. Please contact customer support.',
  'charge.declined': 'This charge was declined by your credit card provider. Please contact your provider.',
  'charge.failed': 'The transaction failed. Please contact customer support.',
  'charge.amount': 'The amount you entered is invalid. Please charge an amount between $10 and $2,000.',
  'contactbulk.xlsxencrypted': 'The Excel file is encrypted and cannot be read. Please use an unencrypted file.',
  'campaign.subdone': 'Subscription is already finished.',
}

export const processErrors = (error: ErrorType, fallback: string) =>
  errorCodes[error.response?.errorCode] || error.response?.error || fallback

export const processCampaignErrors = (error: ErrorType, fallback: string, item: CampaignItemType) => {
  if (error.response?.errorCode === 'template.part' && item?.variables?.parts) {
    const found = findIndex(propEq('id', error.response.errorContext?.partId), item.variables.parts)
    if (found !== -1) {
      return `${capitalizeFirstLetter(error.response.error)} for the ${found + 1}. message`
    }
    return capitalizeFirstLetter(error.response.error)
  }
  return fallback
}
