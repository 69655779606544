import { FileCopy, Visibility, VisibilityOff } from '@mui/icons-material'
import { Icon, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { CSSProperties } from 'react'

type Props = {
  title: string
  text: string | JSX.Element
  inputStyle?: CSSProperties
  style?: CSSProperties
  multiline?: boolean
  hideInput?: boolean
  inputVariant?: 'standard' | 'filled' | 'outlined'
  id?: string
  className?: string
}

export default function CopyBox(props: Props) {
  const [helperText, setHelperText] = React.useState('Click to copy')
  const copyText = React.useRef<HTMLInputElement>(null)
  const [hideInput, setHideInput] = React.useState(props.hideInput)
  return (
    <div
      id={props.id}
      className={props.className}
      style={props.style}
      onClick={() => {
        if (!navigator.clipboard) {
          if (copyText.current) {
            copyText.current.select()
          }
          document.execCommand('copy')
          setHelperText('Copied!')
          // use old commandExec() way
        } else {
          navigator.clipboard.writeText(props.text as string).then(() => {
            setHelperText('Copied!')
            setTimeout(() => {
              setHelperText('Click to copy')
            }, 2000)
          })
        }
      }}
    >
      <TextField
        id="outlined-helperText"
        label={props.title}
        value={props.text}
        helperText={helperText}
        type={hideInput ? 'password' : 'text'}
        margin="normal"
        variant={props.inputVariant || 'outlined'}
        inputRef={copyText}
        multiline={props.multiline}
        InputProps={{
          readOnly: true,
          style: props.inputStyle,
          endAdornment: (
            <>
              {props.hideInput && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setHideInput(!hideInput)
                    }}
                    edge="end"
                  >
                    <Icon>{hideInput ? <VisibilityOff /> : <Visibility />}</Icon>
                  </IconButton>
                </InputAdornment>
              )}
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    if (copyText.current) {
                      copyText.current.select()
                    }
                    document.execCommand('copy')
                    setHelperText('Copied!')
                    setTimeout(() => {
                      setHelperText('Click to copy')
                    }, 2000)
                  }}
                  edge="end"
                >
                  <FileCopy />
                </IconButton>
              </InputAdornment>
            </>
          ),
        }}
      />
    </div>
  )
}
