import { Step } from 'react-joyride'

import { TourStep } from './types'

export const commonStepConfig: Partial<Step> = {
  disableBeacon: true,
  disableOverlayClose: true,
  isFixed: true,
  spotlightClicks: false,
}

export const skippedSteps: TourStep[] = [
  {
    content: 'If you change your mind, find the tour in the help menu.',
    hideCloseButton: true,
    locale: {
      close: 'Got it!',
    },
    target: '#header-help-trigger',
  },
]

export const REPEAT_TOUR_KEY = 'repeatTour'
