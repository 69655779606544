import { createSelector } from 'reselect'
import latest from 'moment-timezone/data/meta/latest'
import { pathOr, prop, sortBy } from 'ramda'

const partSelectLanguages = pathOr('', ['utils', 'languageList', 'data'])

export const selectLanguages = createSelector(partSelectLanguages, (languages) =>
  languages.map((lang) => ({
    label: lang,
    value: lang.toLowerCase(),
  })),
)

const partSelectCountries = pathOr({}, ['utils', 'countriesList', 'data'])

export const selectCountries = createSelector(partSelectCountries, (countries = {}) =>
  Object.keys(countries).map((key) => ({
    label: pathOr('', [key, 'name'], countries),
    value: pathOr('', [key, 'code'], countries),
  })),
)

export const selectCountryNames = createSelector(partSelectCountries, (countries = {}) =>
  Object.keys(countries).map((key) => ({
    label: pathOr('', [key, 'name'], countries),
    value: pathOr('', [key, 'name'], countries),
  })),
)

export const selectCountriesMapped = createSelector(selectCountries, (countriesList = []) =>
  countriesList.reduce(
    (acc, { label, value }) => ({
      ...acc,
      [label]: value,
    }),
    {},
  ),
)

const partSelectCountriesTimezone = pathOr({}, ['utils', 'countriesTimezonesList', 'data'])

const selectCountriesTimezonesAsObject = createSelector(partSelectCountriesTimezone, (countriesTimezones = {}) =>
  Object.keys(countriesTimezones).map((key) => ({
    label: pathOr('', [key, 'country'], countriesTimezones),
    value: key,
  })),
)

export const selectCountriesTimezones = createSelector(selectCountriesTimezonesAsObject, (countriesTimezones = []) =>
  sortBy(prop('label'), countriesTimezones),
)

const partSelectSingleCountryTimezones = pathOr([], ['tsz'])

export const selectSingleCountryTimezones = createSelector(
  partSelectSingleCountryTimezones,
  (singleCountryTimezones = []) =>
    singleCountryTimezones.map((timezones) => ({
      label: `${pathOr('', ['city'], timezones)} - ${pathOr('', ['offset'], timezones)}`,
      value: pathOr('', ['timezone'], timezones),
    })),
)

export const selectDefaultTimezone = pathOr('', ['utils', 'timezone'])

export const selectTimezoneList = () => latest.zones

export const selectDefaultCountryCode = createSelector(selectDefaultTimezone, selectTimezoneList, (tz, list) =>
  pathOr('', [tz, 'countries', 0], list),
)

export const selectDefaultCountry = createSelector(selectDefaultCountryCode, partSelectCountries, (code, list) =>
  pathOr('', [code, 'name'], list),
)

export const selectVoices = createSelector(pathOr([], ['utils', 'voiceList', 'data']), (voices) =>
  voices
    .map((voice) => ({
      name: `${voice.name} (${voice.gender})`,
      value: voice.id,
    }))
    .sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true })),
)

export const selectSpeechLanguages = createSelector(
  pathOr([], ['utils', 'speechLanguagesList', 'data']),
  (speechLanguages) => {
    const speechLanguageList = speechLanguages
      .map((language) => ({
        label: language.name,
        value: language.value,
      }))
      .sort((a, b) => a.label.localeCompare(b.label, 'en', { numeric: true }))

    speechLanguageList.unshift({ label: 'Use default speech language', value: 'default' })

    return speechLanguageList
  },
)
