import { v1 as uuid } from 'uuid'
import { cannot } from '@/app/helpers'
import { Message, PartFamily, TWhatsAppButtons, TWhatsAppList } from '@/app/module/campaigns/types'
import { Action } from '@/app/module/campaigns/types/schemas/actions/all-actions'
import { ActionsData, WithActionsObject } from './types'

export const generateUniqueIdActions = (actions?: Action[] | null): Action[] =>
  (actions || []).map((action) => ({
    ...action,
    id: uuid(),
  }))

const generateUniqueIdActionsObject = <T extends WithActionsObject>(obj: T): T => {
  return {
    ...obj,
    actions: generateUniqueIdActions(obj.actions),
  }
}

const generateUniqueIdActionsArray = <T extends WithActionsObject>(array: T[]): T[] => {
  return array.map(generateUniqueIdActionsObject)
}

const generateUniqueIdWhatsappButtons = (buttons: TWhatsAppButtons): TWhatsAppButtons => {
  return {
    ...buttons,
    buttons: buttons.buttons.map((button) => ({
      ...button,
      id: uuid(),
      actions: generateUniqueIdActions(button.actions),
    })),
  }
}

const generateUniqueIdWhatsappList = (list: TWhatsAppList): TWhatsAppList => {
  return {
    ...list,
    items: list.items.map((item) => ({
      ...item,
      id: uuid(),
      actions: generateUniqueIdActions(item.actions),
    })),
  }
}

export const ensureUniqueIdForMessageActions = (message: Message): ActionsData => {
  let actionsData: ActionsData = {}

  switch (message.type) {
    case PartFamily.Action:
      actionsData = {
        actionsOnly: generateUniqueIdActionsObject(message.actionsOnly),
      }
      break
    case PartFamily.SMS:
      if (!message.hasQuestion) {
        break
      }
      actionsData = {
        ...actionsData,
        onAnswer: generateUniqueIdActionsArray(message.onAnswer || []),
        onInvalidReply: generateUniqueIdActionsObject(message.onInvalidReply),
        onRetriesExhausted: generateUniqueIdActionsObject(message.onRetriesExhausted),
        onTimeout: generateUniqueIdActionsObject(message.onTimeout),
      }
      if (message.openEnded) {
        actionsData.openEnded = generateUniqueIdActionsObject(message.openEnded)
        if (message.openEnded.categories) {
          actionsData.openEnded.categories = generateUniqueIdActionsArray(message.openEnded.categories)
        }
      }

      break

    case PartFamily.Voice:
      if (!message.hasQuestion) {
        break
      }
      actionsData = {
        ...actionsData,
        onAnswer: generateUniqueIdActionsArray(message.onAnswer || []),
        onInvalidReply: generateUniqueIdActionsObject(message.onInvalidReply),
        onRetriesExhausted: generateUniqueIdActionsObject(message.onRetriesExhausted),
        onTimeout: generateUniqueIdActionsObject(message.onTimeout),
      }
      if (message.spoken) {
        actionsData.spoken = generateUniqueIdActionsObject(message.spoken)
      }

      if (message.callResultActions) {
        actionsData.callResultActions = generateUniqueIdActionsArray(message.callResultActions)
      }

      break
    case PartFamily.WhatsApp: {
      if (!message.hasQuestion) {
        break
      }

      actionsData = {
        ...actionsData,
        onAnswer: generateUniqueIdActionsArray(message.onAnswer || []),
        onInvalidReply: generateUniqueIdActionsObject(message.onInvalidReply),
        onRetriesExhausted: generateUniqueIdActionsObject(message.onRetriesExhausted),
        onTimeout: generateUniqueIdActionsObject(message.onTimeout),
      }

      if (message.onButtons) {
        actionsData.onButtons = generateUniqueIdWhatsappButtons(message.onButtons)
      }

      if (message.onList) {
        actionsData.onList = generateUniqueIdWhatsappList(message.onList)
      }

      if (message.openEnded) {
        actionsData.openEnded = generateUniqueIdActionsObject(message.openEnded)
        if (message.openEnded.categories) {
          actionsData.openEnded.categories = generateUniqueIdActionsArray(message.openEnded.categories)
        }
      }

      break
    }

    case PartFamily.Topup:
      actionsData = {
        topup: {
          ...message.topup,
          onError: generateUniqueIdActions(message.topup?.onError),
          onSuccess: generateUniqueIdActions(message.topup?.onSuccess),
        },
      }
      break
    case PartFamily.API: {
      actionsData = {
        apiCall: {
          ...message.apiCall,
          onError: generateUniqueIdActions(message.apiCall?.onError),
          onSuccess: generateUniqueIdActions(message.apiCall?.onSuccess),
        },
      }
      break
    }
    case PartFamily.CATI: {
      if (!message.hasQuestion) {
        break
      }

      actionsData = {
        ...actionsData,
        onAnswer: generateUniqueIdActionsArray(message.onAnswer || []),
      }
      if (message.openEnded) {
        actionsData.openEnded = generateUniqueIdActionsObject(message.openEnded)
      }
      break
    }
    default:
      return cannot(message)
  }

  return actionsData
}
