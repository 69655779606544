import { formatDT } from '@/app/service/util/format'
import { TYPES } from '@/app/module/campaigns/definitions'
import { CampaignTemplate } from '@/app/module/campaigns/types'

export const generateCampaignName = (item: { name?: string; type?: string }, template?: CampaignTemplate) => {
  if (!item.type) return ''

  const time = formatDT('now')

  if (template === CampaignTemplate.AirtimeTopup && item.type === 'drip') {
    return item.name || `Airtime Topup Campaign (${time})`
  }
  if (template === CampaignTemplate.VoiceBlast && item.type === 'ivr') {
    return item.name || `Call Blast Campaign (${time})`
  }

  switch (item.type) {
    case 'smsblast':
      return item.name || `SMS Blast Campaign (${time})`

    default: {
      const key = item.type.toUpperCase() as keyof typeof TYPES
      return item.name || `${TYPES[key]?.title} (${time})`
    }
  }
}
