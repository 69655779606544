export const ACTION_ADD_NEW_LOG = '@actions/CONVERSATIONS/ADD_NEW_LOG'
export const ACTION_SET_ACTIVE_CONTACT_INFO = '@actions/CONVERSATIONS/SET_ACTIVE_CONTACT_INFO'
export const ACTION_READ_ACTIVE_CONTACT_MESSAGE = '@actions/CONVERSATIONS/READ_ACTIVE_CONTACT_MESSAGE'
export const ACTION_GET_CONTACT_LOGS = '@actions/CONVERSATIONS/GET_CONTACT_LOGS'
export const ACTION_GET_CONTACT_LOGS_COMPLETED = '@actions/CONVERSATIONS/GET_CONTACT_LOGS_COMPLETED'
export const ACTION_GET_CONTACTS = '@actions/CONVERSATIONS/GET_CONTACTS'
export const ACTION_GET_CONTACTS_COMPLETED = '@actions/CONVERSATIONS/GET_CONTACTS_COMPLETED'

export const ACTION_CLEAR_LOGS = '@actions/CONVERSATIONS/CLEAR_LOGS'

export const CONVERSATIONS_CONTACT_PAGE_SIZE = 20
export const CONVERSATIONS_MESSAGE_PAGE_SIZE = 50
