import React, { Suspense } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'

class Wrapper extends React.Component {
  state = {
    hasRenderError: false,
  }

  componentDidCatch(err, stack) {
    // eslint-disable-next-line
    console.error(`Error rendering: ${err.message}`)
    // eslint-disable-next-line
    console.error(stack)

    this.setState({
      hasRenderError: true,
    })
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props
    const navigated = location.pathname !== prevProps.location.pathname

    if (navigated) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { location, routes, Loader, ErrorPage } = this.props

    if (this.state.hasRenderError) {
      return <ErrorPage />
    }

    return (
      <Suspense fallback={<Loader />}>
        <Switch>
          {routes.map(({ name, path, exact, component: Page }) => (
            <Route
              key={name}
              path={path}
              exact={exact}
              location={location}
              render={function AppRouteRenderer(props) {
                return (
                  <Page
                    name={name}
                    history={props.history}
                    location={location}
                    match={{
                      ...props.match,
                      url: `${props.match.url}${location.search}`,
                    }}
                  />
                )
              }}
            />
          ))}
        </Switch>
      </Suspense>
    )
  }
}

export default withRouter(Wrapper)
