export const ALL = undefined

export const SEND_LIMIT = 5000

/* eslint-disable import/prefer-default-export */
export const operands = [
  {
    label: 'equals',
    value: '==',
    keywords: [],
  },
  {
    label: 'does not equal',
    value: '!=',
    keywords: [],
  },
]

// HOOKS

export const ACTION_GET_PHONENUMBERS_REQUEST = '@actions/PHONENUMBERS/GET_LIST_REQUEST'
export const ACTION_GET_PHONENUMBERS_SUCCESS = '@actions/PHONENUMBERS/GET_LIST_SUCCESS'
export const ACTION_GET_PHONENUMBERS_FAILURE = '@actions/PHONENUMBERS/GET_LIST_FAILURE'

export const ACTION_GET_PHONENUMBER_REQUEST = '@actions/PHONENUMBERS/GET_REQUEST'
export const ACTION_GET_PHONENUMBER_SUCCESS = '@actions/PHONENUMBERS/GET_SUCCESS'
export const ACTION_GET_PHONENUMBER_FAILURE = '@actions/PHONENUMBERS/GET_FAILURE'

export const ACTION_ADD_PHONENUMBER_HOOK_REQUEST = '@actions/PHONENUMBERS/ADD_PHONENUMBER_HOOK_REQUEST'
export const ACTION_ADD_PHONENUMBER_HOOK_SUCCESS = '@actions/PHONENUMBERS/ADD_PHONENUMBER_HOOK_SUCCESS'
export const ACTION_ADD_PHONENUMBER_HOOK_FAILURE = '@actions/PHONENUMBERS/ADD_PHONENUMBER_HOOK_FAILURE'

export const ACTION_UPDATE_PHONENUMBER_HOOK_REQUEST = '@actions/PHONENUMBERS/UPDATE_PHONENUMBER_HOOK_REQUEST'
export const ACTION_UPDATE_PHONENUMBER_HOOK_SUCCESS = '@actions/PHONENUMBERS/UPDATE_PHONENUMBER_HOOK_SUCCESS'
export const ACTION_UPDATE_PHONENUMBER_HOOK_FAILURE = '@actions/PHONENUMBERS/UPDATE_PHONENUMBER_HOOK_FAILURE'

export const ACTION_DELETE_PHONENUMBER_HOOK_REQUEST = '@actions/PHONENUMBERS/DELETE_PHONENUMBER_HOOK_REQUEST'
export const ACTION_DELETE_PHONENUMBER_HOOK_SUCCESS = '@actions/PHONENUMBERS/DELETE_PHONENUMBER_HOOK_SUCCESS'
export const ACTION_DELETE_PHONENUMBER_HOOK_FAILURE = '@actions/PHONENUMBERS/DELETE_PHONENUMBER_HOOK_FAILURE'

export const ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_REQUEST =
  '@actions/PHONENUMBERS/ADD_PHONENUMBER_WHATSAPP_HOOK_REQUEST'
export const ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_SUCCESS =
  '@actions/PHONENUMBERS/ADD_PHONENUMBER_WHATSAPP_HOOK_SUCCESS'
export const ACTION_ADD_PHONENUMBER_WHATSAPP_HOOK_FAILURE =
  '@actions/PHONENUMBERS/ADD_PHONENUMBER_WHATSAPP_HOOK_FAILURE'

export const ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_REQUEST =
  '@actions/PHONENUMBERS/UPDATE_PHONENUMBER_WHATSAPP_HOOK_REQUEST'
export const ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS =
  '@actions/PHONENUMBERS/UPDATE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS'
export const ACTION_UPDATE_PHONENUMBER_WHATSAPP_HOOK_FAILURE =
  '@actions/PHONENUMBERS/UPDATE_PHONENUMBER_WHATSAPP_HOOK_FAILURE'

export const ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_REQUEST =
  '@actions/PHONENUMBERS/DELETE_PHONENUMBER_WHATSAPP_HOOK_REQUEST'
export const ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS =
  '@actions/PHONENUMBERS/DELETE_PHONENUMBER_WHATSAPP_HOOK_SUCCESS'
export const ACTION_DELETE_PHONENUMBER_WHATSAPP_HOOK_FAILURE =
  '@actions/PHONENUMBERS/DELETE_PHONENUMBER_WHATSAPP_HOOK_FAILURE'

export const ACTION_POST_PHONENUMBER_REQUEST = '@actions/PHONENUMBERS/POST_PHONENUMBER_REQUEST'
export const ACTION_POST_PHONENUMBER_SUCCESS = '@actions/PHONENUMBERS/POST_PHONENUMBER_SUCCESS'
export const ACTION_POST_PHONENUMBER_FAILURE = '@actions/PHONENUMBERS/POST_PHONENUMBER_FAILURE'

export const ACTION_CLEAR_PHONENUMBERS = '@actions/PHONENUMBERS/CLEAR_PHONENUMBERS_REQUEST'

export const ACTION_ADD_PHONENUMBER_CALLHOOK_REQUEST = '@actions/PHONENUMBERS/ADD_PHONENUMBER_CALLHOOK_REQUEST'
export const ACTION_ADD_PHONENUMBER_CALLHOOK_SUCCESS = '@actions/PHONENUMBERS/ADD_PHONENUMBER_CALLHOOK_SUCCESS'
export const ACTION_ADD_PHONENUMBER_CALLHOOK_FAILURE = '@actions/PHONENUMBERS/ADD_PHONENUMBER_CALLHOOK_FAILURE'

export const ACTION_UPDATE_PHONENUMBER_CALLHOOK_REQUEST = '@actions/PHONENUMBERS/UPDATE_PHONENUMBER_CALLHOOK_REQUEST'
export const ACTION_UPDATE_PHONENUMBER_CALLHOOK_SUCCESS = '@actions/PHONENUMBERS/UPDATE_PHONENUMBER_CALLHOOK_SUCCESS'
export const ACTION_UPDATE_PHONENUMBER_CALLHOOK_FAILURE = '@actions/PHONENUMBERS/UPDATE_PHONENUMBER_CALLHOOK_FAILURE'

export const ACTION_DELETE_PHONENUMBER_CALLHOOK_REQUEST = '@actions/PHONENUMBERS/DELETE_PHONENUMBER_CALLHOOK_REQUEST'
export const ACTION_DELETE_PHONENUMBER_CALLHOOK_SUCCESS = '@actions/PHONENUMBERS/DELETE_PHONENUMBER_CALLHOOK_SUCCESS'
export const ACTION_DELETE_PHONENUMBER_CALLHOOK_FAILURE = '@actions/PHONENUMBERS/DELETE_PHONENUMBER_CALLHOOK_FAILURE'

export const ACTION_GET_FILE_REQUEST = '@actions/PHONENUMBERS/GET_FILE_REQUEST'
export const ACTION_GET_FILE_SUCCESS = '@actions/PHONENUMBERS/GET_FILE_SUCCESS'
export const ACTION_GET_FILE_FAILURE = '@actions/PHONENUMBERS/GET_FILE_FAILURE'

export const ACTION_SAVE_FILE_REQUEST = '@actions/PHONENUMBERS/SAVE_FILE_REQUEST'
export const ACTION_SAVE_FILE_SUCCESS = '@actions/PHONENUMBERS/SAVE_FILE_SUCCESS'
export const ACTION_SAVE_FILE_FAILURE = '@actions/PHONENUMBERS/SAVE_FILE_FAILURE'

export const ACTION_FILE_RESET = '@actions/PHONENUMBERS/RESET_FILE'

// ERRORS
export const ERROR_GET_PHONENUMBERS_SERVER = 'Failed to get phone numbers. Please try again later.'
export const ERROR_GET_PHONENUMBER_SERVER = 'Failed to get phone number rules. Please try again later.'
export const ERROR_UPDATE_PHONENUMBER_HOOK_SERVER = 'Failed to update phone number rule. Please try again later.'
export const ERROR_ADD_PHONENUMBER_HOOK_SERVER = 'Failed to add phone number rule. Please try again later.'
export const ERROR_DELETE_PHONENUMBER_HOOK_SERVER = 'Failed to delete phone number rule. Please try again later.'
export const ERROR_UPDATE_PHONENUMBER_CALLHOOK_SERVER = 'Failed to update phone number rule. Please try again later.'
export const ERROR_ADD_PHONENUMBER_CALLHOOK_SERVER = 'Failed to add phone number rule. Please try again later.'
export const ERROR_DELETE_PHONENUMBER_CALLHOOK_SERVER = 'Failed to delete phone number rule. Please try again later.'
