import React from 'react'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Icon from '@mui/material/Icon'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import CodeSnippet from '@/app/component/atom/code-snippet'
import CopyBox from '@/app/component/atom/copy-box'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  maxWidth: '305px',
})

const StyledIcon = styled(Icon)(({ theme }) => ({
  fontSize: '4rem !important',
  color: `${theme.palette.primary.main} !important`,
}))

type ErrorLayoutProps = {
  status?: number
  iconName?: string
  children: React.ReactNode
  style?: React.CSSProperties
}

const getStatus = (status: number) => {
  if (status === 404) {
    return 'Not Found'
  }
  return 'Oops'
}

export default function ErrorLayout({ status, iconName = 'error', children, style }: ErrorLayoutProps) {
  return (
    <Container style={style}>
      {status && status !== 500 ? (
        <>
          <StyledIcon>{iconName}</StyledIcon>
          <Typography variant="h3" gutterBottom={true}>
            {getStatus(status)}
          </Typography>
          {children}
        </>
      ) : (
        <>
          <Typography variant="h6" gutterBottom={true}>
            Something went wrong.
          </Typography>
          <Button
            onClick={() => window.location.reload()}
            style={{
              marginBottom: '15px',
              marginTop: '15px',
            }}
            color="primary"
            variant="contained"
          >
            Automatically fix this issue
          </Button>
          <Divider
            style={{
              width: '100%',
              margin: '15px',
            }}
          />
          <Typography gutterBottom={true} style={{ maxWidth: '100%' }}>
            If this error message appears again after you click the above button, please{' '}
            <a href="mailto:HelpTeam@engageSPARK.com">email us</a> or hit that blue chat box in the lower right corner
            of your screen to start chatting with our customer support team! Let them know you got a 500 error <br />
            <CodeSnippet>{children}</CodeSnippet>
          </Typography>
          <CopyBox
            inputStyle={{
              width: '270px',
            }}
            title="url"
            text={window.location.href}
          />
        </>
      )}
    </Container>
  )
}
