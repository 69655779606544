import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { styled } from '@mui/material/styles'

const Container = styled(Card)({
  display: 'flex',
  maxWidth: '350px',
  margin: '70px auto 70px',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
})

type CardLayoutProps = {
  children: React.ReactNode
}

export default function CardLayout({ children }: CardLayoutProps) {
  return (
    <Container>
      <CardContent>{children}</CardContent>
    </Container>
  )
}
