// ACTIONS
export const ACTION_GET_FILES_REQUEST = '@actions/FILES/GET_LIST_REQUEST'
export const ACTION_GET_FILES_SUCCESS = '@actions/FILES/GET_LIST_SUCCESS'
export const ACTION_GET_FILES_FAILURE = '@actions/FILES/GET_LIST_FAILURE'

export const ACTION_GET_FILES_ITEM_REQUEST = '@actions/FILES/GET_REQUEST'
export const ACTION_GET_FILES_ITEM_SUCCESS = '@actions/FILES/GET_SUCCESS'
export const ACTION_GET_FILES_ITEM_FAILURE = '@actions/FILES/GET_FAILURE'

export const ACTION_UPLOAD_FILES_ITEM_REQUEST = '@actions/FILES/UPLOAD_REQUEST'
export const ACTION_UPLOAD_FILES_ITEM_SUCCESS = '@actions/FILES/UPLOAD_SUCCESS'
export const ACTION_UPLOAD_FILES_ITEM_FAILURE = '@actions/FILES/UPLOAD_FAILURE'

export const ACTION_DELETE_FILES_ITEM_REQUEST = '@actions/FILES/DELETE_REQUEST'
export const ACTION_DELETE_FILES_ITEM_SUCCESS = '@actions/FILES/DELETE_SUCCESS'
export const ACTION_DELETE_FILES_ITEM_FAILURE = '@actions/FILES/DELETE_FAILURE'

// ERRORS
export const ERROR_FILES_UPLOAD_SERVER = 'Failed to upload file. Please try again later.'
export const ERROR_FILES_DELETE_SERVER = 'Failed to delete file. Please try again later.'
