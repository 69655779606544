import config from '.'

const downloadConfig = {
  exports: {
    getPath: ({
      orgId = 0,
      exportId = 0,
      filename = 'filename.ext',
    }: {
      orgId: number
      exportId: number
      filename: string
    }) => `${config.svc.url}/v1/organizations/${orgId}/exports/${exportId}/${filename}`,
  },
  receipts: {
    getFileName: (transactionId: number) => `receipt-${transactionId}.pdf`,
    getPath: ({ orgId, transactionId = 0 }: { orgId: number; transactionId: number }) =>
      `${config.svc.url}/v1/organizations/${orgId}/receipts/${transactionId}/`,
  },
}

export default downloadConfig
