import { z } from 'zod'
import {
  ZCallSchedule,
  ZCallWindow,
  ZCampContacts,
  ZDateString,
  ZFilesType,
  ZLaunch,
  ZUIStore,
  ZUserStatuses,
} from '@/app/module/campaigns/types/schemas/common'

export const ZBaseCampaignItem = z.object({
  aborted: z.boolean(),
  abortedAt: ZDateString.optional(),
  amd: z.boolean(),
  archived: z.boolean(),
  autoLaunchAt: ZDateString.optional(),
  callAgainCustom: z.array(ZCallSchedule).optional(),
  callAgainMax: z.number(),
  callWindow: ZCallWindow,
  contacts: ZCampContacts,
  createdAt: ZDateString.optional(),
  files: ZFilesType,
  id: z.number().optional(),
  launch: ZLaunch,
  launchOpen: z.boolean(),
  launched: z.boolean(),
  launchedAt: ZDateString.optional(),
  name: z.string(),
  open: z.boolean(),
  published: z.boolean(),
  reconnectMax: z.number(),
  reconnectOnProgress: z.boolean(),
  runs: z.number(),
  runsDone: z.number(),
  runsError: z.number(),
  runsInProgress: z.number(),
  runsPaused: z.number(),
  runsWithFailures: z.number(),
  uiStore: ZUIStore,
  userStatuses: ZUserStatuses.nullable(),
  version: z.number(),
})
