// ACTIONS
export const ACTION_GET_CUSTOM_FIELDS_REQUEST = '@actions/CUSTOM_FIELDS/GET_LIST_REQUEST'
export const ACTION_GET_CUSTOM_FIELDS_SUCCESS = '@actions/CUSTOM_FIELDS/GET_LIST_SUCCESS'
export const ACTION_GET_CUSTOM_FIELDS_FAILURE = '@actions/CUSTOM_FIELDS/GET_LIST_FAILURE'

export const ACTION_ADD_CUSTOM_FIELDS_REQUEST = '@actions/CUSTOM_FIELDS/ADD_REQUEST'
export const ACTION_ADD_CUSTOM_FIELDS_SUCCESS = '@actions/CUSTOM_FIELDS/ADD_SUCCESS'
export const ACTION_ADD_CUSTOM_FIELDS_FAILURE = '@actions/CUSTOM_FIELDS/ADD_FAILURE'

export const ACTION_MOD_CUSTOM_FIELDS_REQUEST = '@actions/CUSTOM_FIELDS/MOD_REQUEST'
export const ACTION_MOD_CUSTOM_FIELDS_SUCCESS = '@actions/CUSTOM_FIELDS/MOD_SUCCESS'
export const ACTION_MOD_CUSTOM_FIELDS_FAILURE = '@actions/CUSTOM_FIELDS/MOD_FAILURE'

export const ACTION_DELETE_CUSTOM_FIELDS_REQUEST = '@actions/CUSTOM_FIELDS/DELETE_REQUEST'
export const ACTION_DELETE_CUSTOM_FIELDS_SUCCESS = '@actions/CUSTOM_FIELDS/DELETE_SUCCESS'
export const ACTION_DELETE_CUSTOM_FIELDS_FAILURE = '@actions/CUSTOM_FIELDS/DELETE_FAILURE'

// ERRORS

export const ERROR_GET_CUSTOM_FIELDS_SERVER = 'Failed to get custom field details. Please try again later.'
export const ERROR_SAVE_CUSTOM_FIELDS_SERVER = 'Failed to save custom field, '
export const ERROR_DELETE_CUSTOM_FIELDS_SERVER = 'Failed to delete custom field'
