// ACTIONS
export const ACTION_GET_USER_REQUEST = '@actions/USER/GET_DETAILS_REQUEST'
export const ACTION_GET_USER_SUCCESS = '@actions/USER/GET_DETAILS_SUCCESS'
export const ACTION_GET_USER_FAILURE = '@actions/USER/GET_DETAILS_FAILURE'

export const ACTION_GET_USERS_REQUEST = '@actions/USER/GET_USERS_REQUEST'
export const ACTION_GET_USERS_SUCCESS = '@actions/USER/GET_USERS_SUCCESS'
export const ACTION_GET_USERS_FAILURE = '@actions/USER/GET_USERS_FAILURE'

export const ACTION_SAVE_USER_REQUEST = '@actions/USER/SAVE_USER_REQUEST'
export const ACTION_SAVE_USER_SUCCESS = '@actions/USER/SAVE_USER_SUCCESS'
export const ACTION_SAVE_USER_FAILURE = '@actions/USER/SAVE_USER_FAILURE'

export const ACTION_DELETE_USER_REQUEST = '@actions/USER/DELETE_USER_REQUEST'
export const ACTION_DELETE_USER_SUCCESS = '@actions/USER/DELETE_USER_SUCCESS'
export const ACTION_DELETE_USER_FAILURE = '@actions/USER/DELETE_USER_FAILURE'

export const ACTION_UPDATE_USER_REQUEST = '@actions/USER/UPDATE_USER_REQUEST'
export const ACTION_UPDATE_USER_SUCCESS = '@actions/USER/UPDATE_USER_SUCCESS'
export const ACTION_UPDATE_USER_FAILURE = '@actions/USER/UPDATE_USER_FAILURE'

export const ACTION_GET_ORGANIZATION_REQUEST = '@actions/USER/GET_ORGANIZATION_REQUEST'
export const ACTION_GET_ORGANIZATION_SUCCESS = '@actions/USER/GET_ORGANIZATION_SUCCESS'
export const ACTION_GET_ORGANIZATION_FAILURE = '@actions/USER/GET_ORGANIZATION_FAILURE'

export const ACTION_SAVE_ORGANIZATION_REQUEST = '@actions/USER/SAVE_ORGANIZATION_REQUEST'
export const ACTION_SAVE_ORGANIZATION_SUCCESS = '@actions/USER/SAVE_ORGANIZATION_SUCCESS'
export const ACTION_SAVE_ORGANIZATION_FAILURE = '@actions/USER/SAVE_ORGANIZATION_FAILURE'

export const ACTION_SWITCH_ORGANIZATION_REQUEST = '@actions/USER/SWITCH_ORGANIZATION_REQUEST'
export const ACTION_SWITCH_ORGANIZATION_SUCCESS = '@actions/USER/SWITCH_ORGANIZATION_SUCCESS'
export const ACTION_SWITCH_ORGANIZATION_FAILURE = '@actions/USER/SWITCH_ORGANIZATION_FAILURE'

export const ACTION_SAVE_PASSWORD_REQUEST = '@actions/USER/SAVE_PASSWORD_REQUEST'
export const ACTION_SAVE_PASSWORD_SUCCESS = '@actions/USER/SAVE_PASSWORD_SUCCESS'
export const ACTION_SAVE_PASSWORD_FAILURE = '@actions/USER/SAVE_PASSWORD_FAILURE'

export const ACTION_DOWNLOAD_AUDIT_LOG_REQUEST = '@actions/USER/DOWNLOAD_AUDIT_LOG_REQUEST'
export const ACTION_DOWNLOAD_AUDIT_LOG_SUCCESS = '@actions/USER/DOWNLOAD_AUDIT_LOG_SUCCESS'
export const ACTION_DOWNLOAD_AUDIT_LOG_FAILURE = '@actions/USER/DOWNLOAD_AUDIT_LOG_FAILURE'

export const ACTION_INVITE_USER_REQUEST = '@actions/USER/INVITE_USER_REQUEST'
export const ACTION_INVITE_USER_SUCCESS = '@actions/USER/INVITE_USER_SUCCESS'
export const ACTION_INVITE_USER_FAILURE = '@actions/USER/INVITE_USER_FAILURE'

export const ACTION_GET_INVITATIONS_REQUEST = '@actions/USER/GET_INVITATIONS_REQUEST'
export const ACTION_GET_INVITATIONS_SUCCESS = '@actions/USER/GET_INVITATIONS_SUCCESS'
export const ACTION_GET_INVITATIONS_FAILURE = '@actions/USER/GET_INVITATIONS_FAILURE'

export const ACTION_DELETE_INVITATION_REQUEST = '@actions/USER/DELETE_INVITATION_REQUEST'
export const ACTION_DELETE_INVITATION_SUCCESS = '@actions/USER/DELETE_INVITATION_SUCCESS'
export const ACTION_DELETE_INVITATION_FAILURE = '@actions/USER/DELETE_INVITATION_FAILURE'

export const ACTION_INIT_TOTP_REQUEST = '@actions/USER/INIT_TOTP_REQUEST'
export const ACTION_INIT_TOTP_SUCCESS = '@actions/USER/INIT_TOTP_SUCCESS'
export const ACTION_INIT_TOTP_FAILURE = '@actions/USER/INIT_TOTP_FAILURE'

export const ACTION_ENABLE_TOTP_REQUEST = '@actions/USER/ENABLE_TOTP_REQUEST'
export const ACTION_ENABLE_TOTP_SUCCESS = '@actions/USER/ENABLE_TOTP_SUCCESS'
export const ACTION_ENABLE_TOTP_FAILURE = '@actions/USER/ENABLE_TOTP_FAILURE'

export const ACTION_DISABLE_TOTP_REQUEST = '@actions/USER/DISABLE_TOTP_REQUEST'
export const ACTION_DISABLE_TOTP_SUCCESS = '@actions/USER/DISABLE_TOTP_SUCCESS'
export const ACTION_DISABLE_TOTP_FAILURE = '@actions/USER/DISABLE_TOTP_FAILURE'

export const ACTION_GET_RECOVERY_CODES_REQUEST = '@actions/USER/GET_RECOVERY_CODES_REQUEST'
export const ACTION_GET_RECOVERY_CODES_SUCCESS = '@actions/USER/GET_RECOVERY_CODES_SUCCESS'
export const ACTION_GET_RECOVERY_CODES_FAILURE = '@actions/USER/GET_RECOVERY_CODES_FAILURE'

export const ACTION_RESET_RECOVERY_CODES_REQUEST = '@actions/USER/RESET_RECOVERY_CODES_REQUEST'
export const ACTION_RESET_RECOVERY_CODES_SUCCESS = '@actions/USER/RESET_RECOVERY_CODES_SUCCESS'
export const ACTION_RESET_RECOVERY_CODES_FAILURE = '@actions/USER/RESET_RECOVERY_CODES_FAILURE'

export const ACTION_GET_SNIPPETS_REQUEST = '@actions/USER/GET_SNIPPETS_REQUEST'
export const ACTION_GET_SNIPPETS_SUCCESS = '@actions/USER/GET_SNIPPETS_SUCCESS'
export const ACTION_GET_SNIPPETS_FAILURE = '@actions/USER/GET_SNIPPETS_FAILURE'

export const ACTION_SAVE_SNIPPET_REQUEST = '@actions/USER/SAVE_SNIPPET_REQUEST'
export const ACTION_SAVE_SNIPPET_SUCCESS = '@actions/USER/SAVE_SNIPPET_SUCCESS'
export const ACTION_SAVE_SNIPPET_FAILURE = '@actions/USER/SAVE_SNIPPET_FAILURE'

export const ACTION_REMOVE_RECOVERY_CODES = '@actions/USER/REMOVE_RECOVERY_CODES'

export const ACTION_TOGGLE_CALL_DIALOG = '@actions/USER/TOGGLE_CALL_DIALOG'

export const ACTION_SET_SELECTED_ORGANIZATION_ID = '@actions/USER/SET_SELECTED_ORGANIZATION_ID'

export const ACTION_GET_API_KEYS_REQUEST = '@actions/USER/GET_API_KEYS_REQUEST'
export const ACTION_GET_API_KEYS_SUCCESS = '@actions/USER/GET_API_KEYS_SUCCESS'
export const ACTION_GET_API_KEYS_FAILURE = '@actions/USER/GET_API_KEYS_FAILURE'

export const ACTION_CREATE_API_KEY_REQUEST = '@actions/USER/CREATE_API_KEY_REQUEST'
export const ACTION_CREATE_API_KEY_SUCCESS = '@actions/USER/CREATE_API_KEY_SUCCESS'
export const ACTION_CREATE_API_KEY_FAILURE = '@actions/USER/CREATE_API_KEY_FAILURE'

export const ACTION_UPDATE_API_KEY_REQUEST = '@actions/USER/UPDATE_API_KEY_REQUEST'
export const ACTION_UPDATE_API_KEY_SUCCESS = '@actions/USER/UPDATE_API_KEY_SUCCESS'
export const ACTION_UPDATE_API_KEY_FAILURE = '@actions/USER/UPDATE_API_KEY_FAILURE'

export const ACTION_DELETE_API_KEY_REQUEST = '@actions/USER/DELETE_API_KEY_REQUEST'
export const ACTION_DELETE_API_KEY_SUCCESS = '@actions/USER/DELETE_API_KEY_SUCCESS'
export const ACTION_DELETE_API_KEY_FAILURE = '@actions/USER/DELETE_API_KEY_FAILURE'

// ERRORS
export const ERROR_GET_USER_SERVER = 'Failed to get user details. Please try again later.'
export const ERROR_SAVE_USER_SERVER = 'Failed to save user details. Please try again later.'
export const ERROR_GET_USERS_SERVER = 'Failed to get users details. Please try again later.'
export const ERROR_DELETE_USER_SERVER = 'Failed to remove user from the organization. Please try again later.'
export const ERROR_UPDATE_USER_SERVER = 'Failed to update user. Please try again later.'
export const ERROR_GET_ORGANIZATION_SERVER = 'Failed to get organization details. Please try again later.'
export const ERROR_SAVE_ORGANIZATION_SERVER = 'Failed to save organization details. Please try again later.'
export const ERROR_SWITCH_ORGANIZATION_SERVER = 'Failed switching to different organization. Please try again later.'
export const ERROR_SAVE_PASSWORD_SERVER =
  'Failed to save new password. Please make sure the details you entered are correct.'
export const ERROR_DOWNLOAD_AUDIT_LOG = 'Failed to download audit log. Please try again later.'
export const ERROR_INVITE_USER = 'Failed to invite User. Please try again later.'
export const ERROR_GET_INVITATIONS = 'Failed to get invitations. Please try again later.'
export const ERROR_DELETE_INVITATIONS = 'Failed to delete invitation. Please try again later.'
export const ERROR_INIT_TOTP = 'Failed to enable Two-Factor Authentication. Please try again later.'
export const ERROR_ENABLE_TOTP = 'Failed to enable Two-Factor Authentication. Please try again later.'
export const ERROR_DISABLE_TOTP = 'Failed to disable Two-Factor Authentication. Please try again later.'
export const ERROR_GET_RECOVERY_CODES = 'Failed to get recovery codes. Please try again later.'
export const ERROR_RESET_RECOVERY_CODES = 'Failed to reset recovery codes. Please try again later.'
export const ERROR_GET_SNIPPETS_SERVER = 'Failed to get user snippets. Please try again later.'
export const ERROR_SAVE_SNIPPET_SERVER = 'Failed to save user snippets. Please try again later.'
export const ERROR_GET_API_KEYS_SERVER = 'Failed to get API keys. Please try again later.'
export const ERROR_CREATE_API_KEY_SERVER = 'Failed to create API key. Please try again later.'
export const ERROR_UPDATE_API_KEY_SERVER = 'Failed to update API key. Please try again later.'
export const ERROR_DELETE_API_KEY_SERVER = 'Failed to delete API key. Please try again later.'
export const ERROR_EXPORT_SMS_REPORT = 'Failed to export SMS report. Please try again later.'

// MESSAGE
export const EXPORT_SMS_REPORT_PENDING =
  'Your SMS report is being generated. You will receive a notification when done. Or you can find the completed report under Exports.'
