import { clone, defaultTo, flatten, isEmpty, map, mapObjIndexed, omit, path, pathOr, pick, prop } from 'ramda'
import { createSelector } from 'reselect'
import { getHeaderIcon } from '@/app/component/wrapper/table/selectable'
import { formatDateTime } from '@/app/service/util'
import { selectOrdered as selectContactsOrdered } from '@/app/module/contacts/store/selectors'
import { selectApiPersonalizationList, selectPersonalizationList } from '@/app/module/logs/store/selectors'
import { selectUserTimezone } from '@/app/module/user/store/selectors'
import {
  processActionsInWhatsappButtons,
  processActionsInWhatsappList,
} from '@/app/module/campaigns/utils/actions/helpers'
import { SENDER_ID_TYPES, TEMPLATES_TO_TYPE } from '@/app/module/campaigns/definitions'
import { removeEmpty } from '@/app/module/campaigns/utils/actions/wrap'
import { processAudio, processSpeechSettingsLanguageChange, processText } from '@/app/module/campaigns/language-helpers'
import { createReminderInstance } from '@/app/module/campaigns/models/classes/reminder-campaign.class'
import { processOpenEndedActions } from '@/app/module/campaigns/utils/process-actions'

const partSelectPage = pathOr('1', ['campaigns', 'list', 'data', 'page'])

const partSelectPageIds = pathOr({}, ['campaigns', 'list', 'data', 'pages'])

const partSelectCampaigns = pathOr({}, ['campaigns', 'list', 'data', 'values'])

export const selectOrdered = createSelector(partSelectPage, partSelectPageIds, (page, pages) =>
  pathOr([], [page], pages),
)

export const selectHeaders = () => [
  {
    title: 'Name',
    fields: ['name'],
  },
  {
    title: 'Type',
    fields: ['type'],
  },
  {
    title: 'Created',
    fields: ['createdAt'],
  },
  {
    title: 'Launch Time',
    fields: ['launched'],
  },
  {
    title: 'Status',
    fields: ['state'],
  },
  {
    title: 'Subscriptions',
    fields: ['runs'],
  },
]

const partSelectCampaignsPage = createSelector(selectOrdered, partSelectCampaigns, pick)

const EMPTY_DATE = '0001-01-01T00:00:00Z'

export const selectCampaignItemConfig = createSelector(selectUserTimezone, (tz) => ({
  id: {
    type: 'hidden',
    display: 'hidden',
  },
  name: {
    type: 'text',
    display: 'text',
  },
  type: {
    type: 'type',
    display: 'type',
  },
  createdAt: {
    type: 'datetime',
    display: 'datetime',
    tz,
  },
  launched: {
    type: 'text',
    display: 'text',
  },
  state: {
    type: 'state',
    display: 'state',
  },
  runs: {
    type: 'runs',
    display: 'runs',
  },
}))

export const selectTimezones = (timezones) =>
  timezones.map((timezone) => ({
    label: `${pathOr('', ['city'], timezone)} (${pathOr('', ['offset'], timezone)})`,
    value: pathOr('', ['timezone'], timezone),
  }))

export const processActionForSaving = (action, senderIds) => {
  const newAnswer = {
    ...action,
    actions: pathOr([], ['actions'], action)
      .filter((value) => !isEmpty(value))
      .map((value) => {
        if (path(['sendSMS'], value) && value.sendSMS.useCampaignSenderId !== 'action') {
          const { sendSMS } = value
          return {
            ...value,
            sendSMS: {
              ...sendSMS,
              senderId: senderIds[sendSMS.useCampaignSenderId],
            },
          }
        }
        if (path(['sendSMSToPhoneNumbers'], value) && value.sendSMSToPhoneNumbers.useCampaignSenderId !== 'action') {
          const { sendSMSToPhoneNumbers } = value
          return {
            ...value,
            sendSMSToPhoneNumbers: {
              ...sendSMSToPhoneNumbers,
              senderId: senderIds[sendSMSToPhoneNumbers.useCampaignSenderId],
            },
          }
        }
        if (path(['sendWhatsapp'], value) && value.sendWhatsapp.useCampaignSenderId !== 'action') {
          const { sendWhatsapp } = value
          return {
            ...value,
            sendWhatsapp: {
              ...sendWhatsapp,
              senderId: senderIds[sendWhatsapp.useCampaignSenderId],
            },
          }
        }
        return value
      }),
  }
  return newAnswer
}

export const processActionsInLists = (answers, senderIds, processFn) => {
  if (!answers) {
    return answers
  }
  return answers.map((answer) => processFn(answer, senderIds))
}

export const processActionsInObjects = (answers, senderIds, processFn) => {
  if (answers) {
    return processFn(answers, senderIds)
  }
  return answers
}

export const decodeText = (text) => {
  let updatedText = text
  updatedText = updatedText.replace(/[\u2018\u2019\u201A]/g, "'")
  // smart double quotes
  updatedText = updatedText.replace(/[\u201C\u201D\u201E]/g, '"')
  // ellipsis
  updatedText = updatedText.replace(/\u2026/g, '...')
  // dashes
  updatedText = updatedText.replace(/[\u2013\u2014]/g, '-')
  // circumflex
  updatedText = updatedText.replace(/\u02C6/g, '^')
  // open angle bracket
  updatedText = updatedText.replace(/\u2039/g, '<')
  // close angle bracket
  updatedText = updatedText.replace(/\u203A/g, '>')
  // spaces
  updatedText = updatedText.replace(/[\u02DC\u00A0]/g, ' ')
  // Guillemet or french quote character
  // https://en.wikipedia.org/wiki/Guillemet
  updatedText = updatedText.replace(/[\u00AB]/g, '<')
  updatedText = updatedText.replace(/[\u00BB]/g, '>')

  return updatedText
}

const partSelectData = path(['logs', 'data'])

const partSelectSenderIds = createSelector(partSelectData, pathOr([], ['senderIds']))

export const selectSMSSenderIdValues = createSelector(partSelectSenderIds, (senderIds) => [
  ...map(
    ({ phonenumber, sender, name }) => ({
      label: name ? `${name} (${sender || phonenumber})` : sender || phonenumber,
      value: sender || phonenumber,
      type: sender ? 'sender' : 'phonenumber',
    }),
    pathOr([], ['sms'], senderIds),
  ),
])

export const selectWhatsappSenderIds = createSelector(partSelectSenderIds, (senderIds) => [
  ...map(
    ({ phonenumber, sender, name }) => ({
      label: name ? `${name} (${sender || phonenumber})` : sender || phonenumber,
      value: sender || phonenumber,
      type: sender ? 'sender' : 'phonenumber',
    }),
    pathOr([], ['whatsapp'], senderIds),
  ),
])

export const selectSenderIdValuesForDrip = createSelector(partSelectSenderIds, (senderIds) => senderIds)

export const selectSenderIdsByType = ({ messageType, newMessages, type }) =>
  flatten(
    map(
      (src) =>
        src.listName !== 'defaultSenderId'
          ? map(
              (types) =>
                pathOr([], ['senderIdsForDrip', messageType.name], newMessages)
                  .filter(prop(types.key))
                  .map(({ [types.key]: senderIdVal, name }) => ({
                    label: name ? `${name} (${senderIdVal})` : senderIdVal,
                    value: senderIdVal,
                  })),
              src.types,
            )
          : [...pathOr([], ['defaultSenderId'], newMessages)],
      pathOr([], [type, 'dataSource'], SENDER_ID_TYPES),
    ),
  )

const campaignsTransform = (
  {
    id,
    name,
    template,
    autoLaunchAt,
    createdAt,
    launched,
    launchedAt,
    published,
    aborted,
    open,
    archived,
    launchOpen,
    runs = 0,
    runsDone = 0,
    runsError = 0,
    runsInProgress = 0,
    runsPaused = 0,
    runsWithFailures = 0,
  },
  tz,
) => {
  let launchedData = 'Draft'
  if (launched) {
    launchedData = formatDateTime(launchedAt, tz)
  } else if (autoLaunchAt && autoLaunchAt !== EMPTY_DATE) {
    launchedData = formatDateTime(autoLaunchAt, tz)
  }
  return {
    id,
    name,
    type: TEMPLATES_TO_TYPE[template] || template,
    createdAt,
    launched: launchedData,
    state: {
      launched,
      open,
      published,
      aborted,
      archived,
    },
    open: launched ? open : launchOpen,
    runs: {
      started: runs,

      inProgress: runs - runsDone,
      live: runsInProgress - runsPaused,
      scheduled: runs - runsDone - runsInProgress,
      paused: runsPaused,

      completed: runsDone,
      endpointReached: runsDone - runsError,
      unsubscribed: runsError,

      runsWithFailures,
    },
  }
}

export const selectCampaigns = createSelector(partSelectCampaignsPage, selectUserTimezone, (campaigns, tz) =>
  map((item) => campaignsTransform(item, tz), campaigns),
)

export const selectCampaignIds = createSelector(partSelectCampaigns, selectOrdered, (campaigns, ordered) =>
  map(
    (campaignId) => ({
      label: `${pathOr('', [campaignId, 'name'], campaigns)} - ${pathOr('', [campaignId, 'id'], campaigns)}`,
      value: pathOr('', [campaignId, 'id'], campaigns),
    }),
    ordered,
  ),
)

export const selectCampaignItem = createSelector(pathOr({}, ['campaigns', 'item', 'data']), (data) => {
  switch (data.template) {
    case 'reminder':
      return createReminderInstance(data) || {}
    default:
      return {
        ...omit(['template'], data),
        type: TEMPLATES_TO_TYPE[data.template] || data.template,
      }
  }
})

export const selectCampaignSummary = pathOr({}, ['campaigns', 'itemSummary', 'data'])

export const selectCampaignContactConfig = () => ({
  name: {
    type: 'text',
    display: 'text',
  },
  fullPhoneNumber: {
    type: 'phone',
    display: 'phone',
  },
})

export const selectManageCampaignContactConfig = () => ({
  name: {
    type: 'text',
    display: 'text',
  },
  fullPhoneNumber: {
    type: 'phone',
    display: 'phone',
  },
  subscription: {
    type: 'runs',
    display: 'runs',
  },
})

const selectCampaignItemContactsIsAll = createSelector(
  selectCampaignItem,
  pathOr(false, ['contacts', 'allContactsAreSelected']),
)

const selectCampaignItemContactsList = createSelector(
  selectCampaignItem,
  selectCampaignItemContactsIsAll,
  ({ contacts = {} }, isAll) =>
    isAll ? defaultTo([], contacts.excludedContactIds) : defaultTo([], contacts.includedContactIds),
)

export const selectContactsHeaderIcon = createSelector(
  selectCampaignItemContactsIsAll,
  selectCampaignItemContactsList,
  selectContactsOrdered,
  pathOr(0, ['contacts', 'data', 'total']),
  getHeaderIcon,
)

export const selectGroupsHeaders = () => [
  {
    title: 'Name',
    fields: ['name'],
  },
  {
    title: 'Number of contacts',
    fields: ['numberOfContacts'],
  },
]

export const selectSegmentsHeaders = selectGroupsHeaders

export const checkIfCallerIsValid = (item) => {
  if (
    pathOr([], ['variables', 'callerIds'], item).length > 0 ||
    typeof path(['variables', 'callerId'], item) === 'string'
  ) {
    return true
  }
  return false
}

// To handle old operands. Maybe one day we will remove it.
export const processConditions = (conditions) => {
  let stringified = JSON.stringify(conditions)
  stringified = stringified.replace(/\["notEmpty",/g, '["not_empty",')
  stringified = stringified.replace(/\["contains",/g, '["contain",')
  stringified = stringified.replace(/\["notContains",/g, '["not_contain",')
  return JSON.parse(stringified)
}

export const processActionsMap = {
  multidrip: {
    key: 'parts',
    questions: ['smsMessage', 'smsQuestion', 'voiceMessage', 'voiceQuestion', 'action', 'whatsappQuestion'],
    smsMessage: {
      message: processText,
    },
    smsQuestion: {
      message: processText,
      onAnswer: processActionsInLists,
      onInvalidReply: processActionsInObjects,
      onTimeout: processActionsInObjects,
      onRetriesExhausted: processActionsInObjects,
      openEnded: processOpenEndedActions,
    },
    voiceMessage: {
      audio: processAudio,
    },
    voiceQuestion: {
      audio: processAudio,
      onAnswer: processActionsInLists,
      onInvalidReply: processActionsInObjects,
      onTimeout: processActionsInObjects,
      onRetriesExhausted: processActionsInObjects,
      spoken: processActionsInObjects,
      callResultActions: processActionsInLists,
      reconnectActions: processActionsInLists,
    },
    whatsappQuestion: {
      onAnswer: processActionsInLists,
      onButtons: processActionsInWhatsappButtons,
      onInvalidReply: processActionsInObjects,
      onList: processActionsInWhatsappList,
      onRetriesExhausted: processActionsInObjects,
      onTimeout: processActionsInObjects,
      openEnded: processOpenEndedActions,
    },
    action: {
      actionsOnly: processActionsInObjects,
    },
    topup: {
      topup: processActionsInObjects,
    },
  },
  ivr: {
    key: 'parts',
    questions: ['voiceMessage', 'voiceQuestion'],
    voiceMessage: {
      audio: processAudio,
    },
    voiceQuestion: {
      audio: processAudio,
      onAnswer: processActionsInLists,
      onInvalidReply: processActionsInObjects,
      onTimeout: processActionsInObjects,
      onRetriesExhausted: processActionsInObjects,
      spoken: processActionsInObjects,
      callResultActions: processActionsInLists,
      reconnectActions: processActionsInLists,
    },
  },
  smssurvey: {
    key: 'parts',
    questions: ['smsMessage', 'smsQuestion'],
    smsMessage: {
      message: processText,
    },
    smsQuestion: {
      message: processText,
      onAnswer: processActionsInLists,
      onInvalidReply: processActionsInObjects,
      onTimeout: processActionsInObjects,
      onRetriesExhausted: processActionsInObjects,
      openEnded: processOpenEndedActions,
    },
  },
  whatsappsurvey: {
    key: 'parts',
    questions: ['whatsappQuestion'],
    whatsappQuestion: {
      onAnswer: processActionsInLists,
      onButtons: processActionsInWhatsappButtons,
      onInvalidReply: processActionsInObjects,
      onList: processActionsInWhatsappList,
      onRetriesExhausted: processActionsInObjects,
      onTimeout: processActionsInObjects,
      openEnded: processOpenEndedActions,
    },
  },
  cati: {
    key: 'parts',
    questions: ['catiMessage', 'catiQuestion'],
    catiMessage: {
      message: processText,
    },
    catiQuestion: {
      message: processText,
      onAnswer: processActionsInLists,
      openEnded: processOpenEndedActions,
    },
  },
}

export const processVariables = (item, processFn) => {
  const { template } = item
  const { senderId, senderIdQuestions, senderIdWhatsapp } = pathOr({}, ['variables'], item)
  const newVariables = pathOr({}, ['variables'], item)
  if (template === 'ivr') {
    const { reconnectActions = [] } = newVariables
    newVariables.reconnectActions = processActionsInLists(
      reconnectActions,
      { senderId, senderIdQuestions, senderIdWhatsapp },
      processFn,
    )
  }
  const parts = pathOr([], ['variables', 'parts'], item).map((part) => {
    const newPart = clone(part)
    mapObjIndexed((val, question) => {
      if (processActionsMap[template].questions.includes(question)) {
        mapObjIndexed((actionVal, key) => {
          const processActions = path([template, question, key], processActionsMap)
          if (processActions && key !== 'message' && key !== 'audio') {
            const cleansed = processActions(actionVal, { senderId, senderIdQuestions, senderIdWhatsapp }, processFn)
            newPart[question][key] = cleansed
          }
        }, val)
      } else if (question === 'actionsOnly') {
        const cleansed = processActionsInObjects(val, { senderId, senderIdQuestions, senderIdWhatsapp }, processFn)
        newPart[question] = cleansed
      } else if (val && question === 'topup' && val.onSuccess) {
        const onSuccess = removeEmpty(val.onSuccess)
        const onError = removeEmpty(val.onError)
        newPart[question] = {
          ...newPart[question],
          onSuccess,
          onError,
        }
      } else if (question === 'callResultActions') {
        const cleansed = processActionsInLists(val, { senderId, senderIdQuestions, senderIdWhatsapp }, processFn)
        newPart[question] = cleansed
      }
    }, newPart)
    return newPart
  })
  return {
    ...newVariables,
    parts,
  }
}

export const processPartsForLanguageChange = (item, newLanguages, newDefaultLanguage, processFn) => {
  const { template } = item
  const { languages, defaultLanguage } = pathOr({}, ['uiStore'], item)
  const languageConfig = { languages, newLanguages, defaultLanguage, newDefaultLanguage }
  const parts = pathOr([], ['variables', 'parts'], item).map((part) => {
    const newPart = processSpeechSettingsLanguageChange(part, languageConfig)
    mapObjIndexed((val, question) => {
      if (processActionsMap[template].questions.includes(question)) {
        mapObjIndexed((actionVal, key) => {
          const processActions = path([template, question, key], processActionsMap)
          if (processActions) {
            const cleansed = processActions(actionVal, languageConfig, processFn)
            newPart[question][key] = cleansed
          }
        }, val)
      } else if (question === 'actionsOnly') {
        const cleansed = processActionsInObjects(val, languageConfig, processFn)
        newPart[question] = cleansed
      }
    }, newPart)

    return newPart
  })
  return parts
}

export const processWelcomeForLanguageChange = (item, newLanguages, newDefaultLanguage) => {
  const { type } = item
  const welcome = path(['variables', 'welcome'], item)
  const { languages, defaultLanguage } = pathOr({}, ['uiStore'], item)
  if (!welcome) {
    return undefined
  }

  const processFn = type === 'ivr' ? processAudio : processText
  return processFn(welcome, { languages, newLanguages, defaultLanguage, newDefaultLanguage })
}

export const selectVoicePreview = (voicePreviewState) => {
  const { loading, query } = voicePreviewState

  const language = pathOr('', ['query', 'activeLanguage'])(voicePreviewState)
  const data = language
    ? pathOr('', ['data', 'translations', language])(voicePreviewState)
    : pathOr('', ['data', 'preview'])(voicePreviewState)

  return {
    data,
    loading,
    query: {
      activeLanguage: query.activeLanguage,
    },
  }
}

const selectCustomFields = path(['customFields'])
const selectLogs = path(['logs'])

export const selectMessagesState = createSelector(selectCustomFields, selectLogs, (customFields, logs) => ({
  loading: pathOr(false, ['loading'], logs),
  error: pathOr({}, ['error'], logs),
  senderIds: selectSMSSenderIdValues({ logs }),
  senderIdsForDrip: selectSenderIdValuesForDrip({ logs }),
  whatsappSenderIds: selectWhatsappSenderIds({ logs }),
  personalizationList: selectPersonalizationList({ customFields }),
  apiPersonalizationList: selectApiPersonalizationList({ customFields }),
}))

export const selectFilesState = ({ campaigns }) => ({
  loadingItems: pathOr([], ['files', 'loadingItems'], campaigns),
  data: pathOr({}, ['files', 'data'], campaigns),
})

export const selectCampaignListLoading = pathOr(false, ['campaigns', 'list', 'loading'])
