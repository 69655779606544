import { createReducer } from '@/app/service/util'
import {
  ACTION_AUTH_ACCEPT_INVITATION_FAILURE,
  ACTION_AUTH_ACCEPT_INVITATION_REQUEST,
  ACTION_AUTH_ACCEPT_INVITATION_SUCCESS,
  ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_FAILURE,
  ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_REQUEST,
  ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_SUCCESS,
  ACTION_AUTH_CHECK_INVITATION_FAILURE,
  ACTION_AUTH_CHECK_INVITATION_REQUEST,
  ACTION_AUTH_CHECK_INVITATION_SUCCESS,
  ACTION_AUTH_LOGIN_FAILURE,
  ACTION_AUTH_LOGIN_REQUEST,
  ACTION_AUTH_LOGIN_SUCCESS,
  ACTION_AUTH_REQUEST_RESET_PASSWORD_FAILURE,
  ACTION_AUTH_REQUEST_RESET_PASSWORD_REQUEST,
  ACTION_AUTH_REQUEST_RESET_PASSWORD_SUCCESS,
  ACTION_AUTH_RESET_PASSWORD_FAILURE,
  ACTION_AUTH_RESET_PASSWORD_REQUEST,
  ACTION_AUTH_RESET_PASSWORD_SUCCESS,
  ACTION_AUTH_SIGNUP_DATA_MOD,
  ACTION_AUTH_SIGNUP_EMAIL_CHECK_FAILURE,
  ACTION_AUTH_SIGNUP_EMAIL_CHECK_REQUEST,
  ACTION_AUTH_SIGNUP_EMAIL_CHECK_SUCCESS,
  ACTION_AUTH_SIGNUP_EMAIL_FAILURE,
  ACTION_AUTH_SIGNUP_EMAIL_REQUEST,
  ACTION_AUTH_SIGNUP_EMAIL_SUCCESS,
  ACTION_AUTH_SIGNUP_FAILURE,
  ACTION_AUTH_SIGNUP_REQUEST,
  ACTION_AUTH_SIGNUP_SUCCESS,
  ACTION_AUTH_VERIFY_OTP_FAILURE,
  ACTION_AUTH_VERIFY_OTP_REQUEST,
  ACTION_AUTH_VERIFY_OTP_SUCCESS,
  ACTION_AUTH_VERIFY_RECOVERY_CODE_FAILURE,
  ACTION_AUTH_VERIFY_RECOVERY_CODE_REQUEST,
  ACTION_AUTH_VERIFY_RECOVERY_CODE_SUCCESS,
  ACTION_SET_CALL,
  ACTION_SET_CALL_STATE,
  ACTION_SET_OTP,
  ACTION_SET_TRIAL_MODE,
} from '@/app/module/auth/definitions'
import defaultState from './state'

export default createReducer(defaultState, {
  [ACTION_AUTH_LOGIN_REQUEST]: (state) => ({
    ...state,
    login: {
      ...state.login,
      loading: true,
    },
  }),
  [ACTION_AUTH_LOGIN_SUCCESS]: (state, { value }) => ({
    ...state,
    login: {
      ...state.login,
      loading: false,
      error: defaultState.login.error,
      data: value || state.login.value,
    },
  }),
  [ACTION_AUTH_LOGIN_FAILURE]: (state, { value }) => ({
    ...state,
    login: {
      ...state.login,
      loading: false,
      error: value,
      data: defaultState.login.data,
    },
  }),
  [ACTION_AUTH_SIGNUP_REQUEST]: (state) => ({
    ...state,
    signup: {
      ...state.signup,
      loading: true,
    },
  }),
  [ACTION_AUTH_SIGNUP_SUCCESS]: (state) => ({
    ...state,
    signup: {
      ...state.signup,
      loading: false,
      error: defaultState.signup.error,
      data: defaultState.signup.data,
    },
  }),
  [ACTION_AUTH_SIGNUP_FAILURE]: (state, { value }) => ({
    ...state,
    signup: {
      ...state.signup,
      loading: false,
      error: value,
    },
  }),
  [ACTION_AUTH_SIGNUP_EMAIL_REQUEST]: (state) => ({
    ...state,
    signupEmail: {
      ...state.signupEmail,
      loading: true,
    },
  }),
  [ACTION_AUTH_SIGNUP_EMAIL_SUCCESS]: (state) => ({
    ...state,
    signupEmail: {
      ...state.signupEmail,
      loading: false,
      error: defaultState.signupEmail.error,
      data: defaultState.signupEmail.data,
    },
  }),
  [ACTION_AUTH_SIGNUP_EMAIL_FAILURE]: (state, { value }) => ({
    ...state,
    signupEmail: {
      ...state.signupEmail,
      loading: false,
      error: value,
    },
  }),
  [ACTION_AUTH_REQUEST_RESET_PASSWORD_REQUEST]: (state) => ({
    ...state,
    requestResetPassword: {
      ...state.requestResetPassword,
      loading: true,
    },
  }),
  [ACTION_AUTH_REQUEST_RESET_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    requestResetPassword: {
      ...state.requestResetPassword,
      loading: false,
      error: defaultState.requestResetPassword.error,
    },
  }),
  [ACTION_AUTH_REQUEST_RESET_PASSWORD_FAILURE]: (state) => ({
    ...state,
    requestResetPassword: {
      ...state.requestResetPassword,
      loading: false,
    },
  }),
  [ACTION_AUTH_RESET_PASSWORD_REQUEST]: (state) => ({
    ...state,
    resetPassword: {
      ...state.resetPassword,
      loading: true,
    },
  }),
  [ACTION_AUTH_RESET_PASSWORD_SUCCESS]: (state) => ({
    ...state,
    resetPassword: {
      ...state.resetPassword,
      loading: false,
      error: defaultState.resetPassword.error,
    },
  }),
  [ACTION_AUTH_RESET_PASSWORD_FAILURE]: (state) => ({
    ...state,
    resetPassword: {
      ...state.resetPassword,
      loading: false,
    },
  }),
  [ACTION_AUTH_SIGNUP_EMAIL_CHECK_REQUEST]: (state) => ({
    ...state,
    emailCheck: {
      ...state.emailCheck,
      loading: true,
    },
  }),
  [ACTION_AUTH_SIGNUP_EMAIL_CHECK_SUCCESS]: (state) => ({
    ...state,
    emailCheck: {
      ...state.emailCheck,
      loading: false,
      error: defaultState.signup.error,
    },
  }),
  [ACTION_AUTH_SIGNUP_EMAIL_CHECK_FAILURE]: (state, { value }) => ({
    ...state,
    emailCheck: {
      ...state.emailCheck,
      loading: false,
      error: value,
    },
  }),
  [ACTION_AUTH_SIGNUP_DATA_MOD]: (state, { value }) => ({
    ...state,
    signup: {
      ...state.signup,
      data: value,
    },
  }),
  [ACTION_AUTH_VERIFY_OTP_REQUEST]: (state) => ({
    ...state,
    otp: {
      ...state.otp,
      loading: true,
    },
  }),
  [ACTION_AUTH_VERIFY_OTP_SUCCESS]: (state, { value }) => ({
    ...state,
    otp: {
      loading: false,
    },
    login: {
      data: value || state.login.value,
    },
  }),
  [ACTION_AUTH_VERIFY_OTP_FAILURE]: (state) => ({
    ...state,
    otp: {
      ...state.otp,
      loading: false,
    },
  }),
  [ACTION_AUTH_VERIFY_RECOVERY_CODE_REQUEST]: (state) => ({
    ...state,
    recoveryCode: {
      ...state.recoveryCode,
      loading: true,
    },
  }),
  [ACTION_AUTH_VERIFY_RECOVERY_CODE_SUCCESS]: (state, { value }) => ({
    ...state,
    recoveryCode: {
      loading: false,
    },
    login: {
      data: value || state.login.value,
    },
  }),
  [ACTION_AUTH_VERIFY_RECOVERY_CODE_FAILURE]: (state) => ({
    ...state,
    recoveryCode: {
      ...state.recoveryCode,
      loading: false,
    },
  }),
  [ACTION_AUTH_CHECK_INVITATION_REQUEST]: (state) => ({
    ...state,
    invitation: {
      loading: true,
    },
  }),
  [ACTION_AUTH_CHECK_INVITATION_SUCCESS]: (state) => ({
    ...state,
    invitation: {
      loading: false,
    },
  }),
  [ACTION_AUTH_CHECK_INVITATION_FAILURE]: (state) => ({
    ...state,
    invitation: {
      loading: false,
    },
  }),
  [ACTION_AUTH_ACCEPT_INVITATION_REQUEST]: (state) => ({
    ...state,
    invitation: {
      loading: true,
    },
  }),
  [ACTION_AUTH_ACCEPT_INVITATION_SUCCESS]: (state) => ({
    ...state,
    invitation: {
      loading: false,
    },
  }),
  [ACTION_AUTH_ACCEPT_INVITATION_FAILURE]: (state) => ({
    ...state,
    invitation: {
      loading: false,
    },
  }),
  [ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_REQUEST]: (state) => ({
    ...state,
    invitation: {
      loading: true,
    },
  }),
  [ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_SUCCESS]: (state) => ({
    ...state,
    invitation: {
      loading: false,
    },
  }),
  [ACTION_AUTH_ACCEPT_INVITATION_WITH_SIGNUP_FAILURE]: (state) => ({
    ...state,
    invitation: {
      loading: false,
    },
  }),
  [ACTION_SET_TRIAL_MODE]: (state, { value }) => ({
    ...state,
    trialMode: value,
  }),
  [ACTION_SET_OTP]: (state, { value }) => ({
    ...state,
    login: {
      ...state.login,
      loading: false,
    },
    otp: {
      data: {
        token: value,
      },
    },
  }),
  [ACTION_SET_CALL_STATE]: (state, { value }) => ({
    ...state,
    call: {
      ...state.call,
      state: value,
    },
  }),
  [ACTION_SET_CALL]: (state, { value }) => ({
    ...state,
    call: {
      ...state.call,
      item: value,
    },
  }),
})
