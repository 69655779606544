import React, { Dispatch, SetStateAction } from 'react'
import ReactJoyride from 'react-joyride'

import { Tour } from './types'

type TourContextState = {
  tour?: Tour
  setTour: Dispatch<SetStateAction<Tour | undefined>>
}

export const TourContext = React.createContext({} as TourContextState)

export const TourProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [tour, setTour] = React.useState<Tour | undefined>(undefined)

  const value = React.useMemo(() => ({ tour, setTour }), [tour])

  return (
    <TourContext.Provider value={value}>
      {children}
      {tour && (
        <ReactJoyride
          {...tour}
          run
          styles={{
            options: {
              zIndex: 1400,
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
            },
          }}
        />
      )}
    </TourContext.Provider>
  )
}

export const useTourContext = () => React.useContext(TourContext)
