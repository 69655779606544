import { z } from 'zod'
import ZBaseAction from './base'

const ZTopupAction = ZBaseAction.extend({
  topup: z.object({
    maxAmount: z.string(),
    maxAmountTemplated: z.string(),
    desiredAmountTemplated: z.string(),
  }),
})

export default ZTopupAction
