import { applySpec, compose, indexBy, merge, omit, pathOr, prop, without } from 'ramda'
import { createReducer } from '@/app/service/util'
import {
  ACTION_DELETE_SEGMENTS_ITEM_FAILURE,
  ACTION_DELETE_SEGMENTS_ITEM_REQUEST,
  ACTION_DELETE_SEGMENTS_ITEM_SUCCESS,
  ACTION_GET_SEGMENTS_FAILURE,
  ACTION_GET_SEGMENTS_ITEM_FAILURE,
  ACTION_GET_SEGMENTS_ITEM_REQUEST,
  ACTION_GET_SEGMENTS_ITEM_SUCCESS,
  ACTION_GET_SEGMENTS_REQUEST,
  ACTION_GET_SEGMENTS_SUCCESS,
  ACTION_RESET_SEGMENTS_QUERY,
  ACTION_SAVE_SEGMENTS_ITEM_FAILURE,
  ACTION_SAVE_SEGMENTS_ITEM_REQUEST,
  ACTION_SAVE_SEGMENTS_ITEM_SUCCESS,
  ACTION_SEGMENTS_NAVIGATE,
} from '@/app/module/segments/definitions'
import defaultState from './state'

const transformSegments = (state, value = { values: [] }) => {
  const indexedPage = indexBy(prop('id'), value.values || [])

  return compose(
    merge(state),
    applySpec({
      page: ({ page }) => parseInt(page, 10),
      size: ({ size }) => parseInt(size, 10),
      total: ({ total }) => parseInt(total, 10),
      values: () => ({
        ...state.values,
        ...indexedPage,
      }),
      pages: ({ page }) => ({
        ...state.pages,
        [page]: value.values.map(({ id }) => id),
      }),
    }),
  )(value)
}

export default createReducer(defaultState, {
  [ACTION_GET_SEGMENTS_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: true,
    },
  }),
  [ACTION_GET_SEGMENTS_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      data: transformSegments(state.list.data, value),
    },
  }),
  [ACTION_GET_SEGMENTS_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      error: value,
    },
  }),
  [ACTION_RESET_SEGMENTS_QUERY]: (state) => {
    const values = pathOr({}, ['list', 'data', 'values'], state)
    const keys = Object.keys(values)
    const pages = {
      1: keys,
    }
    return {
      ...state,
      list: {
        loading: false,
        error: {},
        data: {
          values,
          page: 1,
          pages,
          size: 1000,
          total: keys.length,
        },
      },
    }
  },

  [ACTION_GET_SEGMENTS_ITEM_REQUEST]: (state, { value }) => ({
    ...state,
    item: {
      data: {
        id: value,
      },
      loading: false,
    },
  }),
  [ACTION_GET_SEGMENTS_ITEM_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      data: value,
      loading: false,
    },
  }),
  [ACTION_GET_SEGMENTS_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      error: value,
      loading: false,
    },
  }),

  // SAVE
  [ACTION_SAVE_SEGMENTS_ITEM_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
  }),
  [ACTION_SAVE_SEGMENTS_ITEM_SUCCESS]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      data: defaultState.item.data,
    },
  }),
  [ACTION_SAVE_SEGMENTS_ITEM_FAILURE]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
    },
  }),

  // DELETE
  [ACTION_DELETE_SEGMENTS_ITEM_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
  }),
  [ACTION_DELETE_SEGMENTS_ITEM_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      data: defaultState.item.data,
    },
    list: {
      ...state.list,
      data: {
        ...state.list.data,
        values: omit([value.id], state.list.data.values),
        size: state.list.data.size - 1,
        total: state.list.data.total - 1,
        pages: {
          [state.list.data.page]: without([Number(value.id)], state.list.data.pages[state.list.data.page]),
        },
      },
    },
  }),
  [ACTION_DELETE_SEGMENTS_ITEM_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),

  // NAVIGATE
  [ACTION_SEGMENTS_NAVIGATE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      data: {
        ...state.list.data,
        page: value.page,
        search: value.searchTerm,
      },
    },
  }),
})
