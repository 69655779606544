/**
 * The type of the part.
 */
export enum PartFamily {
  Action = 'action',
  API = 'api',
  CATI = 'cati',
  SMS = 'sms',
  Topup = 'topup',
  Voice = 'voice',
  WhatsApp = 'whatsapp',
}

/**
 * The name of the property in the part object that contains the content of the part.
 */
export enum PartTypes {
  ActionsOnly = 'actionsOnly',
  ApiCall = 'apiCall',
  CATIMessage = 'catiMessage',
  CATIQuestion = 'catiQuestion',
  SMSMessage = 'smsMessage',
  SMSQuestion = 'smsQuestion',
  Topup = 'topup',
  VoiceMessage = 'voiceMessage',
  VoiceQuestion = 'voiceQuestion',
  WhatsAppMessage = 'whatsappMessage',
  WhatsAppQuestion = 'whatsappQuestion',
}
