import { z } from 'zod'

export const ZWAAudioContent = z.object({
  audio: z.string().optional(),
})

export const ZWADocumentContent = z.object({
  document: z.string().optional(),
})

export const ZWAImageContent = z.object({
  image: z.string().optional(),
})

export const ZWAVideoContent = z.object({
  video: z.string().optional(),
})
