import { z } from 'zod'
import ZBaseAction from './base'

const ZSendEmailAction = ZBaseAction.extend({
  sendEmail: z.object({
    from: z.string(),
    to: z.string().optional(),
    toMultiple: z.array(z.string()),
    subject: z.string(),
    message: z.string(),
  }),
})

export default ZSendEmailAction
