import { ReactNode } from 'react'

type CodeSnippetProps = {
  children: ReactNode
}

export default function CodeSnippet({ children }: CodeSnippetProps) {
  return (
    <pre
      style={{
        background: '#f4f4f4',
        border: '1px solid #ddd',
        color: '#666',
        pageBreakInside: 'avoid',
        fontSize: '15px',
        lineHeight: '1.6',
        marginBottom: '1.6em',
        maxWidth: '100%',
        overflow: 'auto',
        display: 'block',
        wordWrap: 'break-word',
        padding: '.5em',
        whiteSpace: 'pre-wrap',
      }}
    >
      {children}
    </pre>
  )
}
