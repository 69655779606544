import { createReducer } from '@/app/service/util'
import {
  ACTION_DELETE_PLAN_FAILURE,
  ACTION_DELETE_PLAN_REQUEST,
  ACTION_DELETE_PLAN_SUCCESS,
  ACTION_GET_PLANS_FAILURE,
  ACTION_GET_PLANS_REQUEST,
  ACTION_GET_PLANS_SUCCESS,
  ACTION_GET_PLAN_FAILURE,
  ACTION_GET_PLAN_REQUEST,
  ACTION_GET_PLAN_SUCCESS,
  ACTION_SUBSCRIBE_PLAN_FAILURE,
  ACTION_SUBSCRIBE_PLAN_REQUEST,
  ACTION_SUBSCRIBE_PLAN_SUCCESS,
} from '@/app/module/plans/definitions'
import defaultState from './state'

export default createReducer(defaultState, {
  // PLAN
  [ACTION_GET_PLAN_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
  }),
  [ACTION_GET_PLAN_SUCCESS]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      data: value,
    },
  }),
  [ACTION_GET_PLAN_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
  // PLANS
  [ACTION_GET_PLANS_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: true,
    },
  }),
  [ACTION_GET_PLANS_SUCCESS]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      data: value,
    },
  }),
  [ACTION_GET_PLANS_FAILURE]: (state, { value }) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      error: value,
    },
  }),
  // SUBSCRIBE PLAN
  [ACTION_SUBSCRIBE_PLAN_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
  }),
  [ACTION_SUBSCRIBE_PLAN_SUCCESS]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
    },
  }),
  [ACTION_SUBSCRIBE_PLAN_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
  // DELETE PLAN
  [ACTION_DELETE_PLAN_REQUEST]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: true,
    },
  }),
  [ACTION_DELETE_PLAN_SUCCESS]: (state) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
    },
  }),
  [ACTION_DELETE_PLAN_FAILURE]: (state, { value }) => ({
    ...state,
    item: {
      ...state.item,
      loading: false,
      error: value,
    },
  }),
})
