export default {
  funds: {
    loading: false,
  },
  cards: {
    loading: false,
    initialized: false,
    error: {},
    data: [],
  },
  autoRecharge: {
    loading: false,
    error: {},
    data: {
      autoRecharge: false,
      autoRechargeThreshold: '',
      autoRechargeAmount: '',
    },
  },
  receipts: {
    loading: false,
    error: {},
    data: [],
  },
  invoices: {
    loading: false,
    error: {},
    data: [],
  },
  proformaInvoice: {
    loading: false,
  },
  transferFunds: {
    loading: false,
  },
}
