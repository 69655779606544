import { countries, esCountries, languages, voices } from '@/config/resources'
import speechLanguages from '@/config/speech-languages'

export default {
  languageList: {
    loading: false,
    data: languages,
    error: {},
  },
  countriesList: {
    loading: false,
    data: esCountries,
    error: {},
  },
  countriesTimezonesList: {
    loading: false,
    data: countries,
    error: {},
  },
  voiceList: {
    loading: false,
    data: voices,
    error: {},
  },
  speechLanguagesList: {
    loading: false,
    data: speechLanguages,
    error: {},
  },
}
