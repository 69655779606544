export default {
  dashboard: {
    loading: false,
    error: {},
    data: {},
  },
  billing: {
    loading: false,
    error: {},
    data: {},
  },
}
