export default {
  list: {
    loading: false,
    loadingItems: [],
    data: {},
    error: {},
  },
  item: {
    loading: false,
    data: {},
    error: {},
  },
}
