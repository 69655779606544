import { z } from 'zod'
import ZBaseAction from './base'

const ZTransferAction = ZBaseAction.extend({
  transfer: z.object({
    to: z.string(),
  }),
})

export default ZTransferAction
