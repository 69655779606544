import {
  equals,
  filter,
  find,
  findIndex,
  has,
  isEmpty,
  keys,
  omit,
  pathEq,
  pathOr,
  propEq,
  reduce,
  without,
} from 'ramda'
import { LanguageType } from './helpers'
import {
  LegacyCampaignItem,
  CampaignTemplateType,
  ContentAudio,
  ContentProperty,
  ContentSMS,
  Part,
  SpeechRepliesType,
  SpeechSettings,
} from './types'
import { Action } from './types/schemas/actions/all-actions'

export type LanguageConfig = {
  languages: LanguageType[]
  newLanguages: LanguageType[]
  defaultLanguage: string
  newDefaultLanguage: string
}

type MessageContent = ContentAudio | ContentSMS

export const processMessage = (
  message: ContentAudio | ContentSMS,
  { newLanguages, defaultLanguage, newDefaultLanguage }: LanguageConfig,
  contentProperties: ContentProperty[],
) => {
  let updatedMessage = {
    ...message,
  }
  if (newLanguages.length < 1) {
    return {
      ...updatedMessage,
      translations: {},
    }
  }
  if (!equals(defaultLanguage, newDefaultLanguage) && defaultLanguage) {
    contentProperties.forEach((property) => {
      updatedMessage = {
        ...updatedMessage,
        [property]: pathOr('', ['translations', newDefaultLanguage, property], message),
        translations: {
          ...updatedMessage.translations,
          [defaultLanguage]: {
            ...pathOr({}, ['translations', defaultLanguage], updatedMessage),
            [property]: pathOr('', [property], message),
          },
        },
      }
    })
  }
  const isTranslation = (n: LanguageType) => n.value !== newDefaultLanguage
  const languagesWithoutDefault = filter(isTranslation, newLanguages)
  const translations = pathOr({}, ['translations'], updatedMessage)
  let newTranslations = {}
  keys(translations).forEach((translation) => {
    const foundIndex = findIndex(propEq('value', translation), languagesWithoutDefault)
    if (foundIndex !== -1) {
      newTranslations = {
        ...newTranslations,
        [translation]: translations[translation],
      }
    }
  })
  return {
    ...updatedMessage,
    translations: newTranslations,
  }
}

export const processText = (message: MessageContent, languages: LanguageConfig) =>
  processMessage(message, languages, ['text'])

export const processAudio = (message: MessageContent, languages: LanguageConfig) =>
  processMessage(message, languages, ['playfile', 'say', 'voice'])

const languageActionsContentProcessors: Record<string, typeof processAudio | typeof processText> = {
  play: processAudio,
  sendSMS: processText,
  sendSMSToPhoneNumbers: processText,
  sendWhatsapp: processText,
}

export const processSpeechSettingsLanguageChange = (part: Part, languages: LanguageConfig) => {
  if (!('voiceQuestion' in part) || !part.voiceQuestion.speechSettings) {
    return part
  }

  return {
    ...part,
    voiceQuestion: {
      ...part.voiceQuestion,
      speechSettings: generateSpeechSettings(languages.newLanguages, part.voiceQuestion.speechSettings),
    },
  }
}

export const processSpeechRepliesLanguageChange = (
  speechReplies: SpeechRepliesType,
  { languages, newLanguages }: LanguageConfig,
) => {
  const speechRepliesByLanguage: SpeechRepliesType = reduce(
    (acc, l) => {
      if (!l.speechLanguage || l.speechLanguage.value === 'default') {
        return acc
      }
      return {
        ...acc,
        [l.value]: pathOr([], [l.speechLanguage.value], speechReplies),
      }
    },
    {},
    languages,
  )
  return reduce<LanguageType, SpeechRepliesType>(
    (acc, l) => {
      if (!l.speechLanguage || l.speechLanguage.value === 'default') {
        return acc
      }

      return {
        ...acc,
        [l.speechLanguage.value]: speechRepliesByLanguage[l.value] || [],
      }
    },
    {},
    newLanguages,
  )
}

export const processActionsLanguageChange = (
  action: { actions?: Action[]; ranges?: string[]; replies?: string[]; speechReplies?: SpeechRepliesType },
  languages: LanguageConfig,
) => {
  let newAction = {
    ...action,
    actions: (action.actions || [])
      .filter((value) => !isEmpty(value))
      .map((value: Action) => {
        const actionKey = pathOr('', [0], without(['id', 'type'], keys(value)))
        if (!actionKey) {
          return value
        }

        const processor = languageActionsContentProcessors[actionKey]

        if (!processor) {
          return value
        }

        const actionContent = value[actionKey]
        const newContent = processor(actionContent, languages)

        return {
          ...value,
          [actionKey]: newContent,
        }
      }),
  }

  const defaultLanguageSpeech = getDefaultLanguageSpeech(languages.newLanguages)
  if (action.speechReplies) {
    if (defaultLanguageSpeech) {
      newAction.speechReplies = processSpeechRepliesLanguageChange(action.speechReplies, languages)
    } else {
      newAction = omit(['speechReplies'], newAction)
      newAction.replies = []
      newAction.ranges = []
    }
  }
  return newAction
}

export const processReconnectLanguageChange = (item: LegacyCampaignItem, languages: LanguageConfig) => {
  if (item.type !== CampaignTemplateType.VoiceSurvey || !item.variables) {
    return item
  }

  return {
    ...item,
    variables: {
      ...item.variables,
      reconnectActions: (item.variables.reconnectActions || []).map((recAction) => {
        const processedActions = processActionsLanguageChange({ actions: recAction.actions || [] }, languages)

        return {
          ...recAction,
          ...processedActions,
        }
      }),
    },
  }
}

const defaultSpeechConfig: Omit<SpeechSettings, 'languages'> = {
  speechAfterAudio: true,
}

export const generateSpeechSettings = (
  languages: LanguageType[],
  config: Omit<SpeechSettings, 'languages'> = defaultSpeechConfig,
): SpeechSettings | undefined => {
  const defaultLanguageSpeech = getDefaultLanguageSpeech(languages)
  if (!defaultLanguageSpeech?.speechLanguage) {
    return undefined
  }

  return {
    ...config,
    languages: reduce(
      (acc, l) => {
        if (!l.speechLanguage || !defaultLanguageSpeech?.speechLanguage) {
          return acc
        }
        return {
          ...acc,
          [l.value]:
            l.speechLanguage.value !== 'default' ? l.speechLanguage.value : defaultLanguageSpeech.speechLanguage.value,
        }
      },
      {
        '': defaultLanguageSpeech.speechLanguage.value,
      },
      languages,
    ),
  }
}

export const hasSpeechLanguage = (languages: LanguageType[]) => !!find(has('speechLanguage'), languages)

export const getDefaultLanguageSpeech = (languages: LanguageType[]) =>
  find(pathEq(['speechLanguage', 'default'], true), languages)

export const generateDefaultSpeechReplies = (speechLanguages: LanguageType[]): SpeechRepliesType =>
  reduce(
    (acc, l) => ({
      ...acc,
      [l.value]: [],
    }),
    {},
    speechLanguages,
  )
