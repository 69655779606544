import { GlobalStyles } from '@mui/material'
import React, { PropsWithChildren } from 'react'
import RecaptchaLayout from '@/app/component/layout/recaptcha-page'

type Props = PropsWithChildren<{
  title?: string
}>

/**
 * This layout will hide the google recaptcha badge.
 */
const Layout: React.FC<Props> = ({ title, children }) => (
  <RecaptchaLayout title={title}>
    <GlobalStyles styles={globalStyles} />
    {children}
  </RecaptchaLayout>
)

const globalStyles = {
  '.grecaptcha-badge': {
    display: 'none !important',
  },
}

export default Layout
