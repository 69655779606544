/* eslint-disable */
// This is a copy of the Intercom setup script from here: https://developers.intercom.com/installing-intercom/docs/basic-javascript
export const setupIntercom = (appId?: string) => {
  if (!appId) {
    return
  }

  ;(window as any).intercomSettings = {
    app_id: appId,
  }
  ;(function () {
    var w: any = window
    var ic = w.Intercom
    if (typeof ic === 'function') {
      ic('reattach_activator')
      ic('update', w.intercomSettings)
    } else {
      const d = document
      const i: any = function () {
        i.c(arguments)
      }
      i.q = []
      i.c = function (args: any) {
        i.q.push(args)
      }
      w.Intercom = i
      const l = function () {
        const s = d.createElement('script')
        s.type = 'text/javascript'
        s.async = true
        s.src = 'https://widget.intercom.io/widget/' + appId
        const x = d.getElementsByTagName('script')[0]
        x.parentNode?.insertBefore(s, x)
      }
      if (document.readyState === 'complete') {
        l()
      } else if (w.attachEvent) {
        w.attachEvent('onload', l)
      } else {
        w.addEventListener('load', l, false)
      }
    }
  })()
}
