import { TimezoneWithCountryCodeType } from '@/app/module/campaigns/types'
import { getTimeZones } from '@vvo/tzdb'

export const tzDb = getTimeZones()

/**
 * To prevent using obsolete timezone, which was returned by moment.tz.guess(), we need to check it against tzdb.
 */
export const findTz = (tz: string) => tzDb.find((t) => t.name === tz || t.group.includes(tz))

export const getCountryCodeFromTimezone = (
  timezone: string,
  timezones: TimezoneWithCountryCodeType,
): string | undefined => {
  const countriesByTimezone = Object.keys(timezones).reduce<Record<string, string>>((acc, code) => {
    const country = timezones[code]
    country.tzs.forEach((tz) => {
      acc[tz.timezone] = code
    })

    return acc
  }, {})

  return countriesByTimezone[timezone]
}

export const toCountry = (timezone: string, countryCode: string, timezones: TimezoneWithCountryCodeType) => {
  const current = timezones[countryCode]
  // if current country has the selected timezone, return current country
  if (current && current.tzs.some((tz) => tz.timezone === timezone)) {
    return countryCode
  }

  // else find in timezones list to get matched country or return current country as the fall back
  return getCountryCodeFromTimezone(timezone, timezones) || countryCode
}
