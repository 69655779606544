import { green, red, yellow } from '@mui/material/colors'

// DASHBOARD ACTIONS
export const ACTION_GET_DASHBOARD_REQUEST = '@actions/DASHBOARD/GET_LIST_REQUEST'
export const ACTION_GET_DASHBOARD_SUCCESS = '@actions/DASHBOARD/GET_LIST_SUCCESS'
export const ACTION_GET_DASHBOARD_FAILURE = '@actions/DASHBOARD/GET_LIST_FAILURE'

// BILLING ACTIONS
export const ACTION_GET_BILLING_REQUEST = '@actions/BILLING/GET_LIST_REQUEST'
export const ACTION_GET_BILLING_SUCCESS = '@actions/BILLING/GET_LIST_SUCCESS'
export const ACTION_GET_BILLING_FAILURE = '@actions/BILLING/GET_LIST_FAILURE'

// ERRORS
export const ERROR_GET_DASHBOARD_SERVER = 'Failed to load DASHBOARD. Please try again later.'
export const ERROR_GET_BILLING_SERVER = 'Failed to load BILLING. Please try again later.'

export const smsLegend = [
  {
    name: 'Delivered',
    color: green.A700,
  },
  {
    name: 'Failed',
    color: red.A100,
  },
  {
    name: 'Not Delivered',
    color: yellow.A100,
  },
]

export const voiceLegend = [
  {
    name: 'Picked Up',
    color: green.A700,
  },
  {
    name: 'Not Picked Up',
    color: red.A100,
  },
  {
    name: 'Failed',
    color: yellow.A100,
  },
]

export const whatsappLegend = [
  {
    name: 'Delivered',
    color: green.A700,
  },
  {
    name: 'Failed',
    color: red.A100,
  },
  {
    name: 'In Progress',
    color: yellow.A100,
  },
]
