import Tooltip from '@mui/material/Tooltip'
import { FC, ReactElement, ReactNode } from 'react'
import { withStyles } from 'tss-react/mui'

type Props = {
  background?: string
  color?: string
  open?: boolean
  title: ReactNode
  children: ReactElement<any, any>
  interactive?: boolean
}

const CustomTooltip: FC<Props> = ({ background, color, open, title, children, interactive = false }) => {
  if (!title) {
    return children
  }

  return (
    <StyledTooltip
      arrow
      color={color}
      disableInteractive={!interactive}
      open={open}
      sx={{
        ...(background && {
          '& .MuiTooltip-arrow': {
            color: background,
          },
          '& .MuiTooltip-tooltip': {
            backgroundColor: background,
          },
        }),
      }}
      title={title}
    >
      {children}
    </StyledTooltip>
  )
}

const StyledTooltip = withStyles(Tooltip, {
  tooltip: {
    color: '#ffffff',
    maxWidth: 320,
    fontSize: 14,
    textAlign: 'center',
  },
})

export default CustomTooltip
