import { z } from 'zod'
import { createTranslations } from '@/app/module/campaigns/types/schemas/common'
import ZTextContent from '@/app/module/campaigns/types/schemas/message-content/text'
import ZBaseAction from './base'

const ZSendSMSAction = ZBaseAction.extend({
  sendSMS: ZTextContent.extend({
    senderId: z.string(),
    translations: createTranslations(ZTextContent).optional(),
    useCampaignSenderId: z.string(),
  }),
})

export default ZSendSMSAction
